import React, { FC } from "react";
import { FieldProps } from "./types";
import FormSpanError from "./SpanError";

type Props = FieldProps & {
  type?: string;
};

const Email: FC<Props> = ({
  type = "email",
  name,
  childRef,
  label,
  error,
  onChange,
  className,
  placeholder
}) => (
  <>
    {label && <label className="form__label">{label}</label>}
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      ref={childRef}
      className={`form__control ${
        error ? "form__control--error" : ""
      } ${className || ""}`}
      onChange={onChange}
    />
    <FormSpanError error={error} />
  </>
);

export default Email;
