import React, { FC, useCallback, useEffect } from 'react';
import Helmet from "react-helmet";
import Instagram from '../Component/Instagram';
import Services from '../Component/Services';
import Offer from '../Component/Offer';
import Zones from '../../Core/Component/Zones';
import { useDispatch } from 'react-redux';
import { conciergeRequestUnFilFill } from '../../Concierge/Store/actions';
import { saleBraintreeFulFilled } from '../../Cart/Store/actions';

const Home: FC = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(conciergeRequestUnFilFill());
    stableDispatch(saleBraintreeFulFilled(false));
  }, [stableDispatch]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <div className="tagline">
        <div className="container">
          <p>
            We are Myko Minions, premium delivery service company founded to meet your desires and to make stay at
            Mykonos as pleasant as possible.
          </p>
        </div>
      </div>

      <Services />
      <Zones />
      <Offer />
      <Instagram />
    </>
  )
};

export default Home;
