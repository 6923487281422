let coordinates = [
  [37.42715, 25.33796],
  [37.42613, 25.33744],
  [37.42562, 25.33718],
  [37.42536, 25.33705],
  [37.42523, 25.33698],
  [37.42512, 25.33693],
  [37.42501, 25.33666],
  [37.42489, 25.33638],
  [37.4248, 25.33619],
  [37.42472, 25.33601],
  [37.42467, 25.33588],
  [37.42456, 25.33533],
  [37.42445, 25.33477],
  [37.42433, 25.33418],
  [37.42429, 25.33393],
  [37.42426, 25.33358],
  [37.42422, 25.33307],
  [37.42377, 25.33242],
  [37.42374, 25.3318],
  [37.42371, 25.33114],
  [37.4237, 25.33076],
  [37.42368, 25.33034],
  [37.42367, 25.32999],
  [37.42364, 25.3293],
  [37.4233, 25.32905],
  [37.42313, 25.32892],
  [37.42296, 25.3288],
  [37.42273, 25.32862],
  [37.4226, 25.32852],
  [37.42255, 25.32849],
  [37.42247, 25.32843],
  [37.42202, 25.3276],
  [37.42179, 25.32717],
  [37.42169, 25.32694],
  [37.42163, 25.32682],
  [37.42158, 25.32671],
  [37.42133, 25.32589],
  [37.42121, 25.32548],
  [37.4211, 25.32511],
  [37.42165, 25.32497],
  [37.42193, 25.32491],
  [37.4221, 25.32486],
  [37.42222, 25.32484],
  [37.42227, 25.32436],
  [37.42233, 25.32388],
  [37.42238, 25.32341],
  [37.42241, 25.32317],
  [37.42244, 25.32293],
  [37.42235, 25.32244],
  [37.42231, 25.3222],
  [37.42227, 25.32197],
  [37.42112, 25.32141],
  [37.42111, 25.3211],
  [37.4211, 25.32095],
  [37.4211, 25.3208],
  [37.4211, 25.32074],
  [37.4211, 25.32068],
  [37.42106, 25.32068],
  [37.42096, 25.3207],
  [37.42078, 25.32073],
  [37.42076, 25.32058],
  [37.42075, 25.32038],
  [37.42074, 25.32018],
  [37.42073, 25.32011],
  [37.42073, 25.32006],
  [37.42086, 25.31996],
  [37.42092, 25.31992],
  [37.42095, 25.31979],
  [37.42098, 25.31969],
  [37.42101, 25.31961],
  [37.42101, 25.31948],
  [37.421, 25.31936],
  [37.42099, 25.31855],
  [37.42098, 25.31815],
  [37.42098, 25.31795],
  [37.42098, 25.31777],
  [37.42126, 25.3174],
  [37.4214, 25.31721],
  [37.42147, 25.31712],
  [37.42155, 25.31702],
  [37.42177, 25.31673],
  [37.42188, 25.31659],
  [37.42194, 25.31651],
  [37.42199, 25.31644],
  [37.42218, 25.31621],
  [37.42224, 25.31612],
  [37.42228, 25.31608],
  [37.42231, 25.31604],
  [37.42239, 25.31601],
  [37.42246, 25.31599],
  [37.42295, 25.31585],
  [37.4232, 25.31578],
  [37.42348, 25.31573],
  [37.42383, 25.31568],
  [37.42401, 25.31565],
  [37.42427, 25.31562],
  [37.42452, 25.31558],
  [37.42511, 25.31549],
  [37.42547, 25.31544],
  [37.42583, 25.31538],
  [37.42633, 25.3153],
  [37.42663, 25.31524],
  [37.42689, 25.3152],
  [37.42823, 25.31496],
  [37.42891, 25.31484],
  [37.42925, 25.31479],
  [37.42942, 25.31475],
  [37.42958, 25.31473],
  [37.43023, 25.3146],
  [37.43088, 25.31449],
  [37.43109, 25.31445],
  [37.43125, 25.31443],
  [37.43138, 25.3144],
  [37.43159, 25.31466],
  [37.4317, 25.3148],
  [37.43181, 25.31494],
  [37.43198, 25.31497],
  [37.43215, 25.31499],
  [37.43235, 25.31502],
  [37.43239, 25.31495],
  [37.43247, 25.3148],
  [37.4325, 25.31477],
  [37.43251, 25.31469],
  [37.43253, 25.31457],
  [37.43276, 25.31436],
  [37.43287, 25.31425],
  [37.43297, 25.31416],
  [37.43367, 25.3141],
  [37.43397, 25.31408],
  [37.43416, 25.31427],
  [37.43442, 25.31453],
  [37.43478, 25.3146],
  [37.43491, 25.31464],
  [37.43519, 25.3148],
  [37.43554, 25.31502],
  [37.43575, 25.31538],
  [37.43579, 25.31546],
  [37.43585, 25.31556],
  [37.43589, 25.31564],
  [37.44009, 25.3107],
  [37.44033, 25.30634],
  [37.43906, 25.3037],
  [37.43163, 25.29927],
  [37.42635, 25.3006],
  [37.42188, 25.30503],
  [37.42214, 25.30847],
  [37.42037, 25.31105],
  [37.41712, 25.31178],
  [37.41408, 25.31019],
  [37.41182, 25.3085],
  [37.41058, 25.30587],
  [37.40779, 25.30643],
  [37.40527, 25.30528],
  [37.40321, 25.304],
  [37.40231, 25.30525],
  [37.40208, 25.3073],
  [37.40318, 25.30922],
  [37.40579, 25.31002],
  [37.40574, 25.31135],
  [37.4044, 25.31482],
  [37.40579, 25.31781],
  [37.40749, 25.31552],
  [37.4083, 25.31513],
  [37.40998, 25.31597],
  [37.41085, 25.31981],
  [37.41383, 25.31842],
  [37.41629, 25.31889],
  [37.41725, 25.32214],
  [37.42019, 25.32126],
  [37.42168, 25.32279],
  [37.42123, 25.3242],
  [37.41998, 25.32577],
  [37.41901, 25.32462],
  [37.41791, 25.32433],
  [37.41683, 25.32509],
  [37.41636, 25.32715],
  [37.41458, 25.32782],
  [37.41054, 25.33085],
  [37.41067, 25.33317],
  [37.40937, 25.3352],
  [37.40889, 25.33669],
  [37.40969, 25.33732],
  [37.41163, 25.33701],
  [37.41338, 25.3372],
  [37.4151, 25.33726],
  [37.41511, 25.33908],
  [37.41247, 25.34227],
  [37.41348, 25.3434],
  [37.41318, 25.34465],
  [37.41207, 25.34547],
  [37.41092, 25.34638],
  [37.41005, 25.34741],
  [37.40856, 25.34621],
  [37.40686, 25.34656],
  [37.40518, 25.34721],
  [37.40446, 25.34792],
  [37.40312, 25.34851],
  [37.40272, 25.34898],
  [37.40293, 25.34979],
  [37.40533, 25.34946],
  [37.40679, 25.3487],
  [37.40741, 25.35],
  [37.40461, 25.35352],
  [37.40753, 25.35376],
  [37.40886, 25.35422],
  [37.40971, 25.35632],
  [37.40947, 25.35841],
  [37.40746, 25.35981],
  [37.40596, 25.36345],
  [37.40685, 25.36803],
  [37.4085, 25.36989],
  [37.41029, 25.37106],
  [37.41195, 25.37025],
  [37.41354, 25.36841],
  [37.41428, 25.36985],
  [37.41375, 25.3724],
  [37.41282, 25.37384],
  [37.41193, 25.37615],
  [37.413, 25.37861],
  [37.4153, 25.37902],
  [37.41744, 25.38017],
  [37.41795, 25.382],
  [37.42196, 25.3843],
  [37.4227, 25.38376],
  [37.4233, 25.38307],
  [37.42372, 25.3822],
  [37.42418, 25.38122],
  [37.42553, 25.37973],
  [37.42693, 25.37977],
  [37.42817, 25.3795],
  [37.42867, 25.38031],
  [37.42929, 25.38361],
  [37.42964, 25.38632],
  [37.43254, 25.38415],
  [37.43684, 25.38333],
  [37.43591, 25.38616],
  [37.43678, 25.38682],
  [37.43651, 25.38783],
  [37.43682, 25.38829],
  [37.43699, 25.38845],
  [37.43703, 25.38866],
  [37.43793, 25.389],
  [37.43941, 25.38985],
  [37.44043, 25.39234],
  [37.44067, 25.39531],
  [37.44087, 25.39649],
  [37.44032, 25.39772],
  [37.44118, 25.39857],
  [37.44151, 25.39939],
  [37.441, 25.40064],
  [37.4419, 25.40116],
  [37.44226, 25.40164],
  [37.44264, 25.40193],
  [37.44325, 25.40184],
  [37.4436, 25.40154],
  [37.44382, 25.40139],
  [37.44445, 25.40132],
  [37.44556, 25.40123],
  [37.44756, 25.40045],
  [37.44886, 25.39999],
  [37.45004, 25.39926],
  [37.45239, 25.39835],
  [37.45417, 25.39756],
  [37.45503, 25.39676],
  [37.45531, 25.39626],
  [37.45582, 25.39649],
  [37.45619, 25.39687],
  [37.45796, 25.3969],
  [37.45881, 25.39638],
  [37.45958, 25.39637],
  [37.45912, 25.39554],
  [37.45842, 25.39486],
  [37.45804, 25.39368],
  [37.45869, 25.39101],
  [37.45982, 25.38967],
  [37.46074, 25.38865],
  [37.46389, 25.38837],
  [37.468, 25.38927],
  [37.46954, 25.38946],
  [37.47009, 25.38655],
  [37.46768, 25.38484],
  [37.4655, 25.38412],
  [37.46492, 25.38088],
  [37.46486, 25.37968],
  [37.46465, 25.37957],
  [37.46457, 25.37921],
  [37.46406, 25.37885],
  [37.46344, 25.37879],
  [37.46174, 25.37799],
  [37.46147, 25.37661],
  [37.46179, 25.37551],
  [37.46186, 25.37403],
  [37.46243, 25.37321],
  [37.46404, 25.37381],
  [37.46559, 25.3735],
  [37.46616, 25.37314],
  [37.46652, 25.3726],
  [37.4673, 25.37137],
  [37.46838, 25.37109],
  [37.46932, 25.36894],
  [37.47171, 25.36731],
  [37.47358, 25.36565],
  [37.47505, 25.36131],
  [37.47691, 25.36085],
  [37.47803, 25.36311],
  [37.47974, 25.36267],
  [37.48075, 25.36133],
  [37.48222, 25.36214],
  [37.48355, 25.36016],
  [37.48522, 25.36132],
  [37.48586, 25.36398],
  [37.48762, 25.36354],
  [37.48846, 25.36181],
  [37.4912, 25.36427],
  [37.49165, 25.36212],
  [37.49242, 25.36106],
  [37.49404, 25.36049],
  [37.49615, 25.36054],
  [37.49795, 25.35927],
  [37.49879, 25.35587],
  [37.50041, 25.35082],
  [37.49988, 25.34819],
  [37.50145, 25.34561],
  [37.49971, 25.33963],
  [37.50045, 25.33789],
  [37.4984, 25.33795],
  [37.49889, 25.33718],
  [37.49849, 25.33519],
  [37.49864, 25.33268],
  [37.49816, 25.32951],
  [37.49727, 25.32904],
  [37.49628, 25.32747],
  [37.49446, 25.32552],
  [37.49355, 25.32295],
  [37.49436, 25.32017],
  [37.49362, 25.31631],
  [37.49255, 25.31312],
  [37.49261, 25.31162],
  [37.49203, 25.31036],
  [37.4897, 25.31113],
  [37.48726, 25.30966],
  [37.48379, 25.3069],
  [37.47868, 25.30841],
  [37.47768, 25.31173],
  [37.47423, 25.31231],
  [37.47306, 25.30882],
  [37.47084, 25.30915],
  [37.46936, 25.3115],
  [37.46828, 25.31308],
  [37.46722, 25.31897],
  [37.46684, 25.32041],
  [37.46696, 25.32087],
  [37.46696, 25.32216],
  [37.46709, 25.32297],
  [37.46721, 25.3235],
  [37.46732, 25.32398],
  [37.46747, 25.32464],
  [37.46752, 25.32471],
  [37.46756, 25.32479],
  [37.46757, 25.3251],
  [37.46749, 25.32534],
  [37.46739, 25.3256],
  [37.46731, 25.32589],
  [37.46721, 25.32619],
  [37.46735, 25.32631],
  [37.46755, 25.32647],
  [37.46758, 25.32665],
  [37.46759, 25.32673],
  [37.46758, 25.32684],
  [37.46756, 25.32698],
  [37.46752, 25.32717],
  [37.46746, 25.32739],
  [37.46737, 25.32755],
  [37.46735, 25.32758],
  [37.46733, 25.32763],
  [37.46731, 25.32771],
  [37.46724, 25.32787],
  [37.46718, 25.32804],
  [37.46711, 25.32819],
  [37.46706, 25.32835],
  [37.46694, 25.32866],
  [37.46672, 25.32923],
  [37.46671, 25.32927],
  [37.46671, 25.32932],
  [37.46671, 25.32942],
  [37.4665, 25.32974],
  [37.46641, 25.32987],
  [37.46632, 25.32998],
  [37.46614, 25.33001],
  [37.46594, 25.33003],
  [37.46571, 25.32982],
  [37.46558, 25.32989],
  [37.46544, 25.32996],
  [37.46537, 25.33003],
  [37.46531, 25.3301],
  [37.46526, 25.33031],
  [37.46521, 25.33043],
  [37.46506, 25.33078],
  [37.465, 25.33094],
  [37.46493, 25.3311],
  [37.46483, 25.33134],
  [37.46473, 25.33158],
  [37.46471, 25.33197],
  [37.46455, 25.33228],
  [37.46429, 25.33251],
  [37.46418, 25.3326],
  [37.46413, 25.33266],
  [37.46397, 25.33281],
  [37.46383, 25.33305],
  [37.46392, 25.33327],
  [37.46397, 25.33338],
  [37.46402, 25.33349],
  [37.46411, 25.33408],
  [37.46413, 25.33465],
  [37.46388, 25.33501],
  [37.46376, 25.33544],
  [37.46372, 25.3358],
  [37.4637, 25.33598],
  [37.46369, 25.33615],
  [37.4633, 25.33656],
  [37.46307, 25.33682],
  [37.46306, 25.3371],
  [37.46306, 25.33731],
  [37.46307, 25.33751],
  [37.46281, 25.33756],
  [37.46267, 25.33759],
  [37.46251, 25.33761],
  [37.46227, 25.33814],
  [37.4622, 25.33848],
  [37.46201, 25.33905],
  [37.46182, 25.33927],
  [37.46177, 25.33943],
  [37.46139, 25.34047],
  [37.46094, 25.3409],
  [37.46048, 25.34133],
  [37.46037, 25.34176],
  [37.45992, 25.34179],
  [37.45947, 25.34181],
  [37.45923, 25.34182],
  [37.45899, 25.34184],
  [37.45889, 25.34182],
  [37.45878, 25.34181],
  [37.45869, 25.34175],
  [37.45864, 25.34165],
  [37.4586, 25.34155],
  [37.45857, 25.34149],
  [37.45854, 25.34144],
  [37.45847, 25.34138],
  [37.45837, 25.34136],
  [37.45827, 25.34127],
  [37.45822, 25.34117],
  [37.45818, 25.34107],
  [37.45794, 25.34107],
  [37.4577, 25.34107],
  [37.45758, 25.34116],
  [37.45746, 25.34126],
  [37.45725, 25.34143],
  [37.45713, 25.34153],
  [37.457, 25.34163],
  [37.45698, 25.34201],
  [37.45682, 25.34214],
  [37.45668, 25.34224],
  [37.45654, 25.34234],
  [37.4564, 25.34245],
  [37.45625, 25.34267],
  [37.45588, 25.34289],
  [37.45545, 25.34291],
  [37.455, 25.34293],
  [37.45487, 25.34299],
  [37.45473, 25.34304],
  [37.45459, 25.34309],
  [37.45445, 25.34315],
  [37.45418, 25.34325],
  [37.45389, 25.34336],
  [37.45362, 25.34343],
  [37.45332, 25.34349],
  [37.45289, 25.34367],
  [37.45273, 25.34374],
  [37.45251, 25.34381],
  [37.45168, 25.34408],
  [37.45105, 25.34427],
  [37.45042, 25.34447],
  [37.45019, 25.34468],
  [37.44996, 25.34489],
  [37.44964, 25.34519],
  [37.44954, 25.3454],
  [37.44945, 25.34561],
  [37.44937, 25.34578],
  [37.44929, 25.34583],
  [37.44921, 25.34589],
  [37.44893, 25.34608],
  [37.4488, 25.34617],
  [37.44866, 25.34616],
  [37.4484, 25.34616],
  [37.44833, 25.34615],
  [37.44827, 25.34616],
  [37.44813, 25.34617],
  [37.44801, 25.34618],
  [37.44789, 25.3462],
  [37.44783, 25.3462],
  [37.44777, 25.34621],
  [37.4476, 25.34598],
  [37.44744, 25.34576],
  [37.44727, 25.34551],
  [37.44718, 25.34538],
  [37.4471, 25.34526],
  [37.44704, 25.34518],
  [37.447, 25.34511],
  [37.44679, 25.34514],
  [37.44658, 25.34516],
  [37.44639, 25.34519],
  [37.4463, 25.3452],
  [37.44621, 25.34521],
  [37.44608, 25.34507],
  [37.44595, 25.34493],
  [37.44583, 25.34479],
  [37.44571, 25.34464],
  [37.44545, 25.34452],
  [37.4452, 25.34441],
  [37.44509, 25.34436],
  [37.445, 25.34431],
  [37.44493, 25.34419],
  [37.44487, 25.34406],
  [37.4448, 25.34393],
  [37.44473, 25.3438],
  [37.44464, 25.34378],
  [37.44456, 25.34375],
  [37.44435, 25.34371],
  [37.44416, 25.34367],
  [37.4441, 25.34365],
  [37.44404, 25.34364],
  [37.44394, 25.34362],
  [37.44385, 25.3436],
  [37.44373, 25.34357],
  [37.44366, 25.34356],
  [37.4436, 25.34354],
  [37.44345, 25.34351],
  [37.4433, 25.34348],
  [37.44319, 25.34354],
  [37.44307, 25.34361],
  [37.44283, 25.34376],
  [37.44259, 25.3439],
  [37.44235, 25.34405],
  [37.44224, 25.34412],
  [37.44218, 25.34415],
  [37.44212, 25.34418],
  [37.44167, 25.34394],
  [37.44127, 25.34372],
  [37.44107, 25.34361],
  [37.44097, 25.34356],
  [37.44087, 25.34351],
  [37.44036, 25.34323],
  [37.44011, 25.34309],
  [37.43985, 25.34295],
  [37.43935, 25.34268],
  [37.43909, 25.34254],
  [37.43884, 25.3424],
  [37.43872, 25.34233],
  [37.43859, 25.34226],
  [37.43849, 25.34221],
  [37.43834, 25.34219],
  [37.43779, 25.34209],
  [37.43747, 25.34203],
  [37.43731, 25.34201],
  [37.43723, 25.34199],
  [37.43715, 25.34198],
  [37.43686, 25.34193],
  [37.43671, 25.3419],
  [37.43657, 25.34188],
  [37.43631, 25.34161],
  [37.43618, 25.34148],
  [37.43605, 25.34135],
  [37.4359, 25.3412],
  [37.43575, 25.34104],
  [37.43566, 25.34095],
  [37.43558, 25.34087],
  [37.43534, 25.34101],
  [37.43505, 25.34121],
  [37.43491, 25.34095],
  [37.43478, 25.34088],
  [37.43465, 25.34083],
  [37.43453, 25.34089],
  [37.43447, 25.34094],
  [37.43439, 25.341],
  [37.43425, 25.34106],
  [37.4341, 25.34113],
  [37.43401, 25.34089],
  [37.43394, 25.34071],
  [37.43396, 25.34066],
  [37.43399, 25.3406],
  [37.43399, 25.34051],
  [37.43399, 25.34043],
  [37.43398, 25.34035],
  [37.43398, 25.34018],
  [37.43388, 25.34022],
  [37.43377, 25.34026],
  [37.43366, 25.3403],
  [37.43356, 25.34034],
  [37.43355, 25.34029],
  [37.43359, 25.34027],
  [37.43359, 25.34016],
  [37.43362, 25.34013],
  [37.43366, 25.34009],
  [37.43365, 25.34002],
  [37.43362, 25.33997],
  [37.43384, 25.33978],
  [37.43369, 25.33976],
  [37.43354, 25.33974],
  [37.4334, 25.33972],
  [37.43326, 25.3397],
  [37.43297, 25.33965],
  [37.43268, 25.33961],
  [37.43235, 25.33956],
  [37.43222, 25.33953],
  [37.43202, 25.33944],
  [37.4316, 25.33926],
  [37.43135, 25.33914],
  [37.43112, 25.33903],
  [37.43078, 25.33888],
  [37.4304, 25.33871],
  [37.42974, 25.33841],
  [37.42953, 25.33832],
  [37.42934, 25.3383],
  [37.42913, 25.33828],
  [37.42889, 25.33826],
  [37.42855, 25.33823],
  [37.42835, 25.33822],
  [37.42813, 25.3382],
  [37.4279, 25.33818],
  [37.42766, 25.33816],
  [37.42753, 25.33815],
  [37.42741, 25.33808],
  [37.42732, 25.33804],
  [37.42724, 25.338]
]

export default coordinates.map(item => {
	return { lat: item[0], lng: item[1] }
})
