import React, { FC, useEffect, useState } from 'react';
import { FieldProps } from "./types";
import DP from "react-datepicker";
import FormSpanError from "./SpanError";
import { useForm } from "react-hook-form";

type Props = FieldProps & {};

const DatePicker: FC<Props> = ({
  name,
  className,
  childRef,
  onChange,
  error,
}: any) => {
  const [selected, setSelected] = useState(null);
  const { register, watch } = useForm();

  // eslint-disable-next-line
  useEffect(() => { register({ name }) }, [register]);

  watch(name);

  return (
    <>
      <DP
        name={name}
        selected={selected}
        onChange={(val: any) => {
          setSelected(val);
          onChange(val);
        }}
        wrapperClassName={className}
        ref={childRef}
        autoComplete={"off"}
      />

      <FormSpanError error={error}/>
    </>
  );
};

export default DatePicker;
