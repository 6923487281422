let coordinates = [
  [
    37.44194,
    25.32619
  ],
  [
    37.44209,
    25.32662
  ],
  [
    37.44211,
    25.32668
  ],
  [
    37.44218,
    25.32669
  ],
  [
    37.44235,
    25.32674
  ],
  [
    37.44235,
    25.32738
  ],
  [
    37.44237,
    25.32782
  ],
  [
    37.44236,
    25.32794
  ],
  [
    37.44234,
    25.32807
  ],
  [
    37.44235,
    25.3282
  ],
  [
    37.44236,
    25.32832
  ],
  [
    37.44241,
    25.3284
  ],
  [
    37.44244,
    25.32848
  ],
  [
    37.4424,
    25.32856
  ],
  [
    37.44265,
    25.32884
  ],
  [
    37.44278,
    25.32872
  ],
  [
    37.44285,
    25.32913
  ],
  [
    37.44289,
    25.32933
  ],
  [
    37.44293,
    25.32953
  ],
  [
    37.44299,
    25.3296
  ],
  [
    37.44304,
    25.32968
  ],
  [
    37.44305,
    25.3298
  ],
  [
    37.443,
    25.32987
  ],
  [
    37.44295,
    25.32995
  ],
  [
    37.44325,
    25.32981
  ],
  [
    37.44331,
    25.32981
  ],
  [
    37.44335,
    25.3299
  ],
  [
    37.44339,
    25.32999
  ],
  [
    37.44373,
    25.32985
  ],
  [
    37.44375,
    25.3302
  ],
  [
    37.44391,
    25.33024
  ],
  [
    37.44397,
    25.33026
  ],
  [
    37.44402,
    25.33031
  ],
  [
    37.44408,
    25.33067
  ],
  [
    37.44402,
    25.33076
  ],
  [
    37.44418,
    25.33101
  ],
  [
    37.44428,
    25.33106
  ],
  [
    37.44441,
    25.33097
  ],
  [
    37.44452,
    25.33108
  ],
  [
    37.44464,
    25.33119
  ],
  [
    37.44477,
    25.33094
  ],
  [
    37.4449,
    25.3307
  ],
  [
    37.44536,
    25.33057
  ],
  [
    37.44581,
    25.33044
  ],
  [
    37.44598,
    25.33051
  ],
  [
    37.44601,
    25.33053
  ],
  [
    37.44606,
    25.33053
  ],
  [
    37.44615,
    25.33055
  ],
  [
    37.44623,
    25.33062
  ],
  [
    37.44645,
    25.33057
  ],
  [
    37.44647,
    25.33072
  ],
  [
    37.44655,
    25.3307
  ],
  [
    37.44662,
    25.33068
  ],
  [
    37.44675,
    25.33072
  ],
  [
    37.44687,
    25.33068
  ],
  [
    37.44695,
    25.33079
  ],
  [
    37.44698,
    25.33085
  ],
  [
    37.447,
    25.3309
  ],
  [
    37.4472,
    25.33082
  ],
  [
    37.44739,
    25.33074
  ],
  [
    37.44744,
    25.33087
  ],
  [
    37.44749,
    25.33101
  ],
  [
    37.44754,
    25.33106
  ],
  [
    37.44755,
    25.33119
  ],
  [
    37.44757,
    25.33135
  ],
  [
    37.44763,
    25.33123
  ],
  [
    37.44778,
    25.33123
  ],
  [
    37.44784,
    25.33145
  ],
  [
    37.44797,
    25.33157
  ],
  [
    37.44815,
    25.33163
  ],
  [
    37.44816,
    25.33165
  ],
  [
    37.4483,
    25.33166
  ],
  [
    37.44831,
    25.33152
  ],
  [
    37.44837,
    25.3315
  ],
  [
    37.44841,
    25.33134
  ],
  [
    37.44844,
    25.33125
  ],
  [
    37.44847,
    25.33117
  ],
  [
    37.44855,
    25.33102
  ],
  [
    37.44871,
    25.33091
  ],
  [
    37.44877,
    25.33104
  ],
  [
    37.44882,
    25.33117
  ],
  [
    37.44897,
    25.33104
  ],
  [
    37.44911,
    25.33093
  ],
  [
    37.4494,
    25.33077
  ],
  [
    37.44958,
    25.33063
  ],
  [
    37.44948,
    25.33023
  ],
  [
    37.44946,
    25.33015
  ],
  [
    37.44944,
    25.33007
  ],
  [
    37.44937,
    25.32986
  ],
  [
    37.44943,
    25.32824
  ],
  [
    37.44947,
    25.32656
  ],
  [
    37.44745,
    25.32501
  ],
  [
    37.44611,
    25.32455
  ],
  [
    37.44505,
    25.32401
  ],
  [
    37.44344,
    25.32484
  ]
]

export default coordinates.map(item => {
  return {lat: item[0], lng: item[1]}
})
