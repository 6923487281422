/* eslint-disable */
import React, { FC, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  decreaseDrinkItemQuantity,
  increaseDrinkItemQuantity,
  removeDrinkItem,
} from '../Store/actions';
import { getDrinkItemQuantity, hasDrinkItem } from '../../Order/Page/OrderingDrinks';

const SummaryDrink: FC = () => {
  const {categories, items}: any = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  if (Object.values(categories).length <= 0) {
    return <></>;
  }

  return (
    <>
      <div>
        {
          Object.values(categories).map((category: any) => {
            return (
              <Fragment key={`${category.id}-${Math.random()}`}>
                <div className={'cart__order'}>
                  <div className="cart__order-title">
                    {category.title}
                  </div>

                  <div className={'cart__order-items'}>
                    {
                      Object.entries(items.drinks).map(([categoryId, products]: any) => {
                        if (categoryId !== category.id) {
                          return <Fragment key={`${category.id}-${Math.random()}`} />;
                        }

                        return Object.values(products).map((item: any) => {
                          return (
                            <Fragment key={`${item.id}-${Math.random()}`}>
                              <div className={'cart__order-item'}>
                                <span className={'itemRemove'} onClick={() => removeDrinkItem(category, item)} />
                                <div className="menu__item-name">
                                  <span>{item.title}</span>
                                  {item.description}
                                </div>
                                <div className={'menu__item-inner'}>
                                  <div className={'menu__item-inner'}>
                                    <div className={'menu__item-price'}>
                                      {item.price}€
                                    </div>
                                  </div>

                                  <div className={'menu__item-inner'}>
                                    <div className={'menu__item-step'}>
                                      <a
                                        href="#"
                                        className="menu__item-arrow menu__item-arrow--down"
                                        onClick={() => stableDispatch(decreaseDrinkItemQuantity(category, item))}
                                      />

                                      <span className="menu__item-number">
                                      {
                                        hasDrinkItem(items, category, item.id) ? getDrinkItemQuantity(items, category, item.id) : 0
                                      }
                                    </span>

                                      <a
                                        href="#"
                                        className="menu__item-arrow menu__item-arrow--up"
                                        onClick={() => stableDispatch(increaseDrinkItemQuantity(category, item))}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })
                      })
                    }
                  </div>
                </div>
              </Fragment>
            );
          })
        }
      </div>
    </>
  );
};

export default SummaryDrink;
