/* eslint-disable */
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGifts } from '../Store/actions';
import Total from '../../Cart/Page/Total';
import { addGiftItem, decreaseGiftItemQuantity, increaseGiftItemQuantity, removeGiftItem } from '../../Cart/Store/actions';

export const getGiftItemQuantity = (items: any, id: string): boolean => {
  return items.gifts[id].quantity;
};

export const hasGiftItem = (items: any, id: string): boolean => {
  return items.gifts.hasOwnProperty(id);
};

const OrderingGift: FC<any> = () => {
  const {gifts}: any = useSelector((state: any) => state.order);
  const {items}: any = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchGifts());
  }, [stableDispatch]);

  if (gifts.length <= 0) {
    return (<></>);
  }

  return (
    <>
      <Helmet>
        <title>Ordering Gifts</title>
      </Helmet>

      <div>
        <div className={'tagline tagline--small'}>
          <div className={'container'}>
            <p>
              Maybe you want to surprise someone who is currently, or will be, in Mykonos?<br/>
              Either you want to stay mystified or you are just not on the island at the moment to make a surprise, in both situations your Minions
              are there for you!<br/>
              Your gift will be in front of the right person, at the right time with your personalized message attached.<br/>
              All you have to do is to check the gift(s) below, fill up the form, and your Minions will take care of everything!<br/> <br/>
              <i>* Please note that if we receive the order after 3 pm it might not be delivered on the same day. Be sure to order on time! </i>
            </p>
          </div>
        </div>


        <div className="menu">
          <div className="container container--menu">
            <div className="menu__title">
              Packages
            </div>

            <Fragment key={`${Math.random()}-fragment`}>
              {
                gifts.map((product: any) => {
                  return (
                    <Fragment key={`${product.id}-fragment-2`}>
                      <div className="menu__item" key={`${product.id}-${Math.random() * Math.random()}`}>
                        <div className="menu__item-wrapper">
                          <div className="menu__item-name">
                          <span>
                            {product.title}
                          </span>
                            {product.description}
                          </div>

                          <div className="menu__item-inner">
                            <div className="menu__item-inner">
                              <div className="menu__item-checkbox">
                                <input
                                  onChange={() => undefined}
                                  type="checkbox" checked={hasGiftItem(items, product.id)}/>
                                <label onClick={
                                  () => stableDispatch(
                                    hasGiftItem(items, product.id)
                                      ? removeGiftItem(product) : addGiftItem(product),
                                  )
                                }>
                                  <span/>
                                </label>
                              </div>
                              <div className="menu__item-price">{product.price}€</div>
                            </div>

                            <div className="menu__item-step">
                              <a
                                href="#"
                                className="menu__item-arrow menu__item-arrow--down"
                                onClick={() => stableDispatch(decreaseGiftItemQuantity(product))}
                              />
                              <span className="menu__item-number">
                              {
                                hasGiftItem(items, product.id) ? getGiftItemQuantity(items, product.id) : 0
                              }
                            </span>

                              <a
                                href="#"
                                className="menu__item-arrow menu__item-arrow--up"
                                onClick={() => stableDispatch(increaseGiftItemQuantity(product))}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              }
            </Fragment>

          </div>
        </div>
      </div>
      <Total/>
    </>
  );
};

export default OrderingGift;
