export const ActionType = {
  REQUEST_ACCOMMODATION: 'REQUEST_ACCOMMODATION',
  REQUEST_RENTING: 'REQUEST_RENTING',
  REQUEST_RESERVATION: 'REQUEST_RESERVATION',
  REQUEST_TRIP_PLANNING: 'REQUEST_TRIP_PLANNING',
  REQUEST_PERSONAL_SERVICE: 'REQUEST_PERSONAL_SERVICE',
  REQUEST_CONTACT_FORM: 'REQUEST_CONTACT_FORM',
  REQUEST_FUL_FILLED: 'REQUEST_FUL_FILLED',
  REQUEST_UN_FUL_FILL: 'REQUEST_UN_FUL_FILL',

  FETCH_RENTING_TYPES: 'FETCH_RENTING_TYPES',
  FETCH_RENTING_TYPES_FUL_FILLED: 'FETCH_RENTING_TYPES_FUL_FILLED',

  FETCH_PERSONAL_SERVICE_TYPES: 'FETCH_PERSONAL_SERVICE_TYPES',
  FETCH_PERSONAL_SERVICE_TYPES_FUL_FILLED: 'FETCH_PERSONAL_SERVICE_TYPES_FUL_FILLED',

  FETCH_RESERVATION_TREE: 'FETCH_RESERVATION_TREE',
  FETCH_RESERVATION_TREE_FUL_FILLED: 'FETCH_RESERVATION_TREE_FUL_FILLED',

  FETCH_TRIP_PLANNING_TREE: 'FETCH_TRIP_PLANNING_TREE',
  FETCH_TRIP_PLANNING_TREE_FUL_FILLED: 'FETCH_TRIP_PLANNING_TREE_FUL_FILLED',
};
