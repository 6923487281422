import React, { FC } from 'react';
import { groupedLinks } from '../../Core/Footer/config';
import { Link } from 'react-router-dom';

const Services: FC = () => {
  return (
    <>
      <div className="services">
        <div className="container">
          <div className="service">
          <div className="service__image"
               style={{ backgroundImage: 'url(\'/images/home/services/1.jpg\')' }}>
            <div className="service__title">Ordering</div>
          </div>

          <div className="service__content">
            <ul className="service__content-links">
              {
                groupedLinks[0].map(({ label, to }: any) => {
                  if (label.toLocaleLowerCase().includes(['order', 'concierge'])) {
                    return '';
                  }

                  return (
                    <li className={"service__content-links-item"} key={`li-${label}-${to}$`}>
                      <Link to={to} className={"service__content-links-link"}>
                        <span className="service__content-links-icon" />
                        { ( label ) }
                      </Link>
                    </li>
                  );
                })
              }
            </ul>

            <Link to={"/ordering"} className={"button button--center"}>
              Explore
            </Link>
          </div>
        </div>

          <div className="service">
            <div className="service__image"
                 style={{ backgroundImage: 'url(\'/images/home/services/2.jpg\')' }}>
              <div className="service__title">Concierge</div>
            </div>

            <div className="service__content">
              <ul className="service__content-links">
                {
                  groupedLinks[1].map(({ label, to }: any) => {
                    if (label.toLocaleLowerCase().includes(['order', 'concierge'])) {
                      return '';
                    }

                    return (
                      <li className={"service__content-links-item"} key={`li-${label}-${to}$`}>
                        <Link to={to} className={"service__content-links-link"}>
                          <span className="service__content-links-icon" />
                          { ( label ) }
                        </Link>
                      </li>
                    );
                  })
                }
              </ul>

              <Link to={"/concierge"} className={"button button--center"}>
                Explore
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Services;
