import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Store/actions';

const zonesText: any = {
  1: 'Located up to 4 km from town? You are in the first zone! Check the map above and locate yourself.',
  2: 'Located 4 - 8 km from town? You are in the second zone! Check the map above and locate yourself.',
  3: 'Located 8 km or more from town? You are in the third zone! Check the map above and locate yourself.'
};

const Zones: FC = () => {
  const settings = useSelector((state: any) => state.core.settings);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchSettings())
  }, [stableDispatch]);

  if (!settings.hasOwnProperty('zones')) {
    return (<></>);
  }

  const {
    zones,
    siteCurrency,
    surchargePercent,
    amountPerExtraStop,
    surchargeAfter,
    zonesDiscount,
  } = settings;

  return (
    <>
      <div>
        <div className="zones">
          <div className="container">
            <div className="zones__title">
              Myko zones
            </div>
            <p className="zones__tagline">
              We are the original and best delivery service on Mykonos.
              Let us take the hassle out of your holiday by bringing you whatever you need whenever you need it.
              We are here for you anytime.
            </p>
          </div>
        </div>

        <div className="zones zones--map">
          <div className="container">
            <div className="zones__map"/>

            <div className="zones__prices">
              {
                Object.entries(zones).map((item: any, idx: any) => {
                  idx = idx + 1;
                  const [, { chargeAmount, discountAmount } ] = item;
                  return (
                    <div className="zones__price" key={`zone-${idx}`}>
                      <div className="zones__price-amount">
                        {
                          zonesDiscount[idx] ? (
                            <span className="zones__price-discount">
                              { Number(chargeAmount) }{ siteCurrency }
                            </span>
                          ) : ('')
                        } { zonesDiscount[idx] ? Number(discountAmount) : Number(chargeAmount) }{ siteCurrency }
                      </div>

                      <div className="zones__price-text">
                        { zonesText[idx] }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>

        <div className="zones">
          <div className="zones__pricing">
            <p className="zones__pricing-text">
            For the orders over { surchargeAfter }{ siteCurrency } we are charging regular zone fee
              plus { surchargePercent }% of the order.
          </p>
          <p className="zones__pricing-text">
            Myko Minions keeps the rights to charge an additional { amountPerExtraStop }{ siteCurrency } per
            every extra stop that they need
            to do to complete the order
          </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Zones;
