/* eslint-disable */
import React, { FC, useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from 'context/Cart/Store/actions';
import { calculateAmount, orderRequestMarket, setSetting } from 'context/Order/Store/actions';
import MarketForm from './MarketForm';
import { ActionType } from '../Store/types';

const MarketCheckout: FC = () => {
  const { settings }: any = useSelector((state: any) => state.cart);
  const { market: { products, total, amount }, orderDeliveryFee }: any = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchSettings());
  }, [stableDispatch]);

  useEffect(() => {
    stableDispatch(setSetting(settings));
  }, [settings]);

  useEffect(() => {
    stableDispatch(calculateAmount());
  }, [settings]);

  let formData: any = null;
  const onFormSubmit = (data: any) => {
    formData = data;
  };

  const onChange = (data: any) => {
    formData = data;
  };

  const submit = () => {
    const data = { contact: formData, products, type: 'market', orderAmount: amount, orderDeliveryFee, orderTotal: total };
    stableDispatch(orderRequestMarket(ActionType.REQUEST_MARKET, data));
  };

  return (
    <>
      <Helmet>
        <title>Cart Market Shop</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              Wooohoo! Final step!<br/>
              Please have a look at your final receipt.<br/>
              If everything looks good, please fill up the form below so your minions can be at your service as soon as possible!
            </p>
          </div>
        </div>

        <div className="single-header single-header--flat">
          <h1 className="single-header__title">Cart Market Shop</h1>
        </div>

        <div className="cart">
          <div className="container">
            <div className="cart__form form">
              <div className="cart__form-title" id="contactDetails">
                Contact details
              </div>
              <MarketForm
                onFormSubmit={onFormSubmit}
                onChange={onChange}
              />
            </div>

            <div className="cart__total">
              <div className="cart__total-title">Final reciept</div>

              <div className="cart__total-items">
                <div className="cart__total-item">
                  Order <span> {total}€ </span>
                </div>

                <div className="cart__total-item cart__total-item--special">
                  Delivery fee <span> {orderDeliveryFee}€ </span>
                </div>
              </div>

              <div className="cart__total-price">
                <div> Total</div>
                {amount}€
              </div>

              <a href="#" className="button button--full" onClick={() => submit()}>
                Order
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default MarketCheckout;
