import { ActionType } from './types';

export function purgeState() {
  return {
    type: ActionType.PURGE_STATE,
  }
}

export function addFoodItem(restaurant: any, product: any) {
  return {
    type: ActionType.ADD_FOOD_ITEM,
    restaurant,
    product,
  };
}

export function addFoodItemExtra(restaurant: any, product: any, extra: any) {
  return {
    type: ActionType.ADD_FOOD_ITEM_EXTRA,
    restaurant,
    product,
    extra,
  };
}

export function addFoodItemExtraCopies(copies: any) {
  return {
    type: ActionType.ADD_FOOD_ITEM_EXTRA_COPIES,
    copies
  };
}

export function removeFoodItemExtra(restaurant: any, product: any, extra: any) {
  return {
    type: ActionType.REMOVE_FOOD_ITEM_EXTRA,
    restaurant,
    product,
    extra,
  };
}

export function removeFoodItem(restaurant: any, product: any) {
  return {
    type: ActionType.REMOVE_FOOD_ITEM,
    restaurant,
    product,
  };
}

export function fetchSettings() {
  return {
    type: ActionType.FETCH_STORE_SETTINGS,
  };
}

export function fetchBraintreeClientToken() {
  return {
    type: ActionType.FETCH_BRAINTREE_CLIENT_TOKEN,
  };
}

export function fetchBraintreeClientTokenDone(token: any) {
  return {
    type: ActionType.FETCH_BRAINTREE_CLIENT_TOKEN_DONE,
    token,
  };
}

export function saleBraintree(data: any) {
  return {
    type: ActionType.SALE_BRAINTREE,
    data,
  }
}

export function saleBraintreeFulFilled(redirect: any) {
  return {
    type: ActionType.SALE_BRAINTREE_FUl_FILLED,
    redirect,
  }
}

export function calculateValues() {
  return {
    type: ActionType.CALCULATE_VALUES,
  }
}

export function fetchSettingsStoreFullFilled(settings: any) {
  return {
    type: ActionType.FETCH_STORE_SETTINGS_FULFILLED,
    settings,
  };
}


export function increaseFoodItemQuantity(restaurant: any, product: any) {
  return {
    type: ActionType.INCREASE_FOOD_ITEM_QUANTITY,
    restaurant,
    product,
  };
}

export function changeZone(zone: any) {
  return {
    type: ActionType.CHANGE_ZONE,
    zone,
  }
}

export function decreaseFoodItemQuantity(restaurant: any, product: any) {
  return {
    type: ActionType.DECREASE_FOOD_ITEM_QUANTITY,
    restaurant,
    product,
  };
}

export function calculateOrderTotal() {
  return {
    type: ActionType.CALCULATE_ORDER_TOTAL,
  }
}

export function getOrderCartData() {
  return {
    type: ActionType.GET_ORDER_CART_DATA,
  }
}

// Drinks
export function addDrinkItem(category: any, product: any) {
  return {
    type: ActionType.ADD_DRINK_ITEM,
    category,
    product,
  };
}

export function removeDrinkItem(category: any, product: any) {
  return {
    type: ActionType.REMOVE_DRINK_ITEM,
    category,
    product,
  };
}

export function increaseDrinkItemQuantity(category: any, product: any) {
  return {
    type: ActionType.INCREASE_DRINK_ITEM_QUANTITY,
    category,
    product,
  };
}

export function decreaseDrinkItemQuantity(category: any, product: any) {
  return {
    type: ActionType.DECREASE_DRINK_ITEM_QUANTITY,
    category,
    product,
  };
}

// Gifts
export function addGiftItem(product: any) {
  return {
    type: ActionType.ADD_GIFT_ITEM,
    product,
  };
}

export function removeGiftItem(product: any) {
  return {
    type: ActionType.REMOVE_GIFT_ITEM,
    product,
  };
}

export function increaseGiftItemQuantity(product: any) {
  return {
    type: ActionType.INCREASE_GIFT_ITEM_QUANTITY,
    product,
  };
}

export function decreaseGiftItemQuantity(product: any) {
  return {
    type: ActionType.DECREASE_GIFT_ITEM_QUANTITY,
    product,
  };
}
