import { ActionType } from './types';

const requestMap: any = {
  [ActionType.REQUEST_ACCOMMODATION]: 'accommodation',
  [ActionType.REQUEST_PERSONAL_SERVICE]: 'personal-service',
  [ActionType.REQUEST_RENTING]: 'renting',
  [ActionType.REQUEST_RESERVATION]: 'reservation',
  [ActionType.REQUEST_TRIP_PLANNING]: 'trip-planning',
  [ActionType.REQUEST_CONTACT_FORM]: 'contact',
};

export function conciergeRequest(requestType: string, payload: any) {
  return {
    type: requestType,
    requestType: requestMap[requestType],
    payload,
  };
}

export function conciergeRequestFulFilled() {
  return {
    type: ActionType.REQUEST_FUL_FILLED,
  }
}

export function conciergeRequestUnFilFill() {
  return {
    type: ActionType.REQUEST_UN_FUL_FILL,
  }
}

export function fetchRentingTypes() {
  return {
    type: ActionType.FETCH_RENTING_TYPES,
  }
}

export function fetchRentingTypesFulFilled(rentingTypes: [] = []) {
  return {
    type: ActionType.FETCH_RENTING_TYPES_FUL_FILLED,
    rentingTypes,
  }
}

export function fetchPersonalServiceTypes() {
  return {
    type: ActionType.FETCH_PERSONAL_SERVICE_TYPES,
  }
}

export function fetchPersonalServiceTypesFulFilled(personalServiceTypes: [] = []) {
  return {
    type: ActionType.FETCH_PERSONAL_SERVICE_TYPES_FUL_FILLED,
    personalServiceTypes,
  }
}

export function fetchReservationTree() {
  return {
    type: ActionType.FETCH_RESERVATION_TREE,
  }
}

export function fetchReservationTreeFulFilled(reservationTree: [] = []) {
  return {
    type: ActionType.FETCH_RESERVATION_TREE_FUL_FILLED,
    reservationTree,
  }
}

export function fetchTripPlanningTree() {
  return {
    type: ActionType.FETCH_TRIP_PLANNING_TREE,
  }
}

export function fetchTripPlanningTreeFulFilled(tripPlanningTree: [] = []) {
  return {
    type: ActionType.FETCH_TRIP_PLANNING_TREE_FUL_FILLED,
    tripPlanningTree,
  }
}
