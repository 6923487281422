export const ActionType = {
  REQUEST_CUSTOM: 'REQUEST_CUSTOM',
  REQUEST_CART: 'REQUEST_CART',
  REQUEST_MARKET: 'REQUEST_MARKET',

  REQUEST_FUL_FILLED: 'REQUEST_FUL_FILLED',
  REQUEST_UN_FUL_FILL: 'REQUEST_UN_FUL_FILL',

  SET_REQUESTED_ORDER: 'SET_REQUESTED_ORDER',

  SET_REQUEST_ORDER_STATUS: 'SET_REQUEST_ORDER_STATUS',

  FETCH_RESTAURANT: 'FETCH_RESTAURANT',
  FETCH_RESTAURANT_FULFILLED: 'FETCH_RESTAURANT_FULFILLED',

  FETCH_RESTAURANT_CATEGORIES: 'FETCH_RESTAURANT_CATEGORIES',
  FETCH_RESTAURANT_CATEGORIES_FULFILLED: 'FETCH_RESTAURANT_CATEGORIES_FULFILLED',

  FETCH_RESTAURANT_PRODUCTS: 'FETCH_RESTAURANT_PRODUCTS',
  FETCH_RESTAURANT_PRODUCTS_FULFILLED: 'FETCH_RESTAURANT_PRODUCTS_FULFILLED',

  FETCH_GIFTS: 'FETCH_GIFTS',
  FETCH_GIFTS_FULFILLED: 'FETCH_GIFTS_FULFILLED',

  FETCH_DRINKS: 'FETCH_DRINKS',
  FETCH_DRINKS_FULFILLED: 'FETCH_DRINKS_FULFILLED',

  ADD_MARKET_SHOP_PRODUCT: 'ADD_MARKET_SHOP_PRODUCT',
  REMOVE_MARKET_SHOP_PRODUCT: 'REMOVE_MARKET_SHOP_PRODUCT',
  SET_SETTINGS: 'SET_SETTINGS',
  CHANGE_ZONE_MARKER: 'CHANGE_ZONE_MARKER',
  GET_AMOUNT: 'GET_AMOUNT',
  RESET_STATE: 'RESET_STATE',
  GET_BETWEEN: 'GET_BETWEEN',
  CHANGE_MARKET_SHOP_PRODUCT_QUANTITY: 'CHANGE_MARKET_SHOP_PRODUCT_QUANTITY',
  CHANGE_MARKET_SHOP_PRODUCT_LABEL: 'CHANGE_MARKET_SHOP_PRODUCT_LABEL',
};
