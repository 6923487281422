import React, { FC, useCallback, useEffect } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTripPlanningTree } from '../Store/actions';
import { Redirect } from 'react-router-dom';
import { routeNames } from '../Routing';
import ConciergeTripPlanningForm from '../Component/ConciergeTripPlanningForm';

const ConciergeTripPlanning: FC = () => {
  const { redirect, tripPlanningTree } = useSelector((state: any) => state.concierge);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchTripPlanningTree());
  }, [stableDispatch]);

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  return (
    <>
      <Helmet>
        <title>Concierge Trip Planning</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              First time in Mykonos? Or you are already Mykonos lover, but you just want to test us. Try us! ;) <br/>
              The more information you give us below in the field "Notes" the more tailored trip we will plan for you!<br/>
              Please fill up the form and let your Minions do the rest.
            </p>
          </div>
        </div>

        <div className="container container--small">
          <ConciergeTripPlanningForm tripPlanningTree={tripPlanningTree} />
        </div>
      </div>
    </>
  )
};

export default ConciergeTripPlanning;
