import React from "react";
import { Route } from "react-router-dom";
import { RouteNames, RoutingDefinition } from "context/Core/types";
import ContactPage from "./Page/Contact";

export const routeNames: RouteNames = {
  CONTACT: "/contact"
};

const Routing: RoutingDefinition = [
  <Route
    key={routeNames.CONTACT}
    path={routeNames.CONTACT}
    exact
    component={ContactPage}
  />
];

export default Routing;
