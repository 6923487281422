import _ from 'lodash';
import { ActionType } from './types';

const initialState: any = {
  orderAmount: 0,
  orderTotal: 0,
  orderExtraStopValue: 0,
  orderDeliveryFee: 0,
  orderSurcharge: 0,
  orderZone: 2,

  items: {
    food: {},
    drinks: {},
    gifts: {},
    foodExtraCopies: [],
  },

  restaurants: {},
  categories: {},

  settings: {},
  orderCartData: null,

  brainTreeClientToken: null,
  redirect: false,
};

export const foodItemExistInState = (foodItems: any, id: string) => {
  let exist = !_.isEmpty(foodItems) && foodItems.hasOwnProperty(id);
  if (!exist) {
    console.info('There is no place id in food items state.');
  }

  return exist;
};

export const drinkItemExistInState = (drinkItems: any, id: any) => {
  let exist = !_.isEmpty(drinkItems) && drinkItems.hasOwnProperty(id);
  if (!exist) {
    console.info('There is no category id in drink items state.');
  }

  return exist;
};

export const giftItemExistInState = (giftItems: any, id: any) => {
  let exist = !_.isEmpty(giftItems) && giftItems.hasOwnProperty(id);
  if (!exist) {
    console.info('There is no category id in gift items state.');
  }

  return exist;
};


const calculateOrder = (items: any) => {
  let orderTotal = 0;
  let { food, drinks, gifts } = items;
  for (let idx in food) {
    if (!food.hasOwnProperty(idx)) {
      continue
    }

    let placeProducts = food[idx];
    for (let key in placeProducts) {
      if (!placeProducts.hasOwnProperty(key)) {
        continue;
      }

      let product = placeProducts[key];
      let { price, quantity, extras } = product;
      let nums = {
        price: Number(price),
        quantity: Number(quantity),
      };

      let extrasPrice = _.sumBy(extras.map((item: any) => {
        item.price = Number(item.price);
        return item;
      }), 'price');

      orderTotal = orderTotal + extrasPrice + (nums.price * nums.quantity)
    }
  }

  for (let idx in drinks) {
    if (!drinks.hasOwnProperty(idx)) {
      continue;
    }

    let drinkProducts = drinks[idx];
    for (let key in drinkProducts) {
      if (!drinkProducts.hasOwnProperty(key)) {
        continue;
      }

      let product = drinkProducts[key];
      let { price, quantity } = product;
      let nums = {
        price: Number(price),
        quantity: Number(quantity),
      };

      orderTotal = orderTotal + (nums.price * nums.quantity);
    }
  }

  for (let idx in gifts) {
    if (!gifts.hasOwnProperty(idx)) {
      continue;
    }

    let product = gifts[idx];
    let { price, quantity } = product;
    let nums = {
      price: Number(price),
      quantity: Number(quantity),
    };

    orderTotal = orderTotal + (nums.price * nums.quantity);
  }

  return orderTotal;
};

const calculateValues = (state: any) => {
  let { settings, orderTotal, orderZone } = state;

  let shouldSurcharge = orderTotal > Number(settings.surchargeAfter);
  state.orderSurcharge = shouldSurcharge
    ? state.orderSurcharge = Number(((orderTotal / 100) * Number(settings.surchargePercent))).toFixed(2)
    : 0;

  state.orderExtraStopValue = 0;
  let numOfPlaces = (Object.keys(state.items.food)).length;
  let numOfDrinks = (Object.keys(state.items.drinks)).length;
  if (numOfPlaces > 1) {
    state.orderExtraStopValue = ((numOfPlaces - 1) * state.settings.amountPerExtraStop);
  }

  if (numOfPlaces > 1 && numOfDrinks > 0) {
    state.orderExtraStopValue = Number(((numOfPlaces - 1) * state.settings.amountPerExtraStop)) + Number(state.settings.amountPerExtraStop);
  }

  state.orderDeliveryFee = 0;
  if (settings.hasOwnProperty('zones')) {
    const { zones, zonesDiscount }: any = settings;
    const orderInZone = zones[Number(orderZone)];
    state.orderDeliveryFee = Number(
      zonesDiscount[orderZone] ? orderInZone.discountAmount : orderInZone.chargeAmount
    );
  }

  state.orderAmount = (
    Number(orderTotal) +
    Number(state.orderSurcharge) +
    Number(state.orderDeliveryFee) +
    Number(state.orderExtraStopValue)
  ).toFixed(2);

  return state;
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.PURGE_STATE:
      state = initialState;
      state.items = {
        food: {},
        drinks: {},
        gifts: {},
        foodExtraCopies: [],
      };

      state.restaurants = {};
      state.categories = {};

      return { ...state };

    case ActionType.SALE_BRAINTREE_FUl_FILLED:
      return { ...state, redirect: action.redirect };

    case ActionType.CALCULATE_VALUES:
      return { ...calculateValues(state) };

    case ActionType.FETCH_BRAINTREE_CLIENT_TOKEN:
      return { ...state, brainTreeClientToken: initialState.brainTreeClientToken };

    case ActionType.FETCH_BRAINTREE_CLIENT_TOKEN_DONE:
      return { ...state, brainTreeClientToken: action.token };

    case ActionType.FETCH_STORE_SETTINGS:
      return { ...state, settings: initialState.settings };

    case ActionType.FETCH_STORE_SETTINGS_FULFILLED:
      return { ...state, settings: action.settings };

    case ActionType.SALE_BRAINTREE:
      return { ...state, data: action.data };

    case ActionType.CALCULATE_ORDER_TOTAL:
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.GET_ORDER_CART_DATA:
      let {
        items,
        orderSurcharge: surcharge,
        orderDeliveryFee: deliveryFee,
        orderZone: zone,
        orderExtraStopValue: extraStop,
        orderAmount,
      } = state;

      state.orderCartData = {
        items,
        surcharge,
        deliveryFee,
        zone,
        extraStop,
        orderAmount,
      };

      return { ...state };

    case ActionType.CHANGE_ZONE:
      state.orderZone = action.zone;
      return { ...calculateValues(state) };

    case ActionType.ADD_FOOD_ITEM:
      let foodItems = state.items.food;
      if (!foodItems.hasOwnProperty(action.restaurant.id)) {
        foodItems[action.restaurant.id] = {}
      }

      state.restaurants[action.restaurant.id] = action.restaurant;

      let product: any = _.cloneDeep({ ...action.product, quantity: 1, extras: [] });
      foodItems[action.restaurant.id][product.id] = product;
      state.items.food = foodItems;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.REMOVE_FOOD_ITEM:
      let foodItemsR = state.items.food[action.restaurant.id];
      if (!foodItemExistInState(foodItemsR, action.product.id)) {
        return { ...state };
      }

      delete foodItemsR[action.product.id];
      state.items.food[action.restaurant.id] = foodItemsR;
      state.items.foodExtraCopies[action.product.id] = [];

      if (_.isEmpty(Object.values(foodItemsR))) {
        delete state.items.food[action.restaurant.id]
      }

      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };
    case ActionType.INCREASE_FOOD_ITEM_QUANTITY:
      let foodItemsI = state.items.food[action.restaurant.id];
      if (!foodItemExistInState(foodItemsI, action.product.id)) {
        let foodItems = state.items.food;
        if (!foodItems.hasOwnProperty(action.restaurant.id)) {
          foodItems[action.restaurant.id] = {}
        }

        state.restaurants[action.restaurant.id] = action.restaurant;
        let product: any = _.cloneDeep({ ...action.product, quantity: 1 });
        foodItems[action.restaurant.id][product.id] = product;
        state.items.food = foodItems;
        state.items = { ...state.items };
        state.orderTotal = calculateOrder(state.items);

        return { ...state };
      }

      let quantity = foodItemsI[action.product.id].quantity;
      foodItemsI[action.product.id].quantity = quantity + 1;
      state.items.food[action.restaurant.id] = foodItemsI;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };
    case ActionType.DECREASE_FOOD_ITEM_QUANTITY:
      let foodItemsD = state.items.food[action.restaurant.id];
      if (!foodItemExistInState(foodItemsD, action.product.id)) {
        return { ...state };
      }

      let quantityD = foodItemsD[action.product.id].quantity;
      if (quantityD === 1) {
        return { ...state };
      }

      foodItemsD[action.product.id].quantity = quantityD - 1;
      state.items.food[action.restaurant.id] = foodItemsD;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.ADD_DRINK_ITEM:
      let drinkItems = state.items.drinks;
      let { id: categoryId } = action.category;
      if (!drinkItems.hasOwnProperty(categoryId)) {
        drinkItems[categoryId] = {};
      }

      state.categories[categoryId] = action.category;

      action.product.quantity = 1;
      drinkItems[categoryId][action.product.id] = action.product;
      state.items.drinks = drinkItems;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };
    case ActionType.REMOVE_DRINK_ITEM:
      let drinkItemsR = state.items.drinks[action.category.id];
      if (!drinkItemExistInState(drinkItemsR, action.product.id)) {
        return { ...state };
      }

      delete drinkItemsR[action.product.id];
      state.items.drinks[action.category.id] = drinkItemsR;
      if (_.isEmpty(Object.values(drinkItemsR))) {
        delete state.items.drinks[action.category.id];
      }

      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.INCREASE_DRINK_ITEM_QUANTITY:
      let drinkItemsQ = state.items.drinks[action.category.id];
      if (!drinkItemExistInState(drinkItemsQ, action.product.id)) {
        return { ...state };
      }

      let quantityQ = drinkItemsQ[action.product.id].quantity;
      drinkItemsQ[action.product.id].quantity = quantityQ + 1;
      state.items.drinks[action.category.id] = drinkItemsQ;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.DECREASE_DRINK_ITEM_QUANTITY:
      let drinkItemsQR = state.items.drinks[action.category.id];
      if (!drinkItemExistInState(drinkItemsQR, action.product.id)) {
        return { ...state };
      }

      let quantityIQ = drinkItemsQR[action.product.id].quantity;
      if (quantityIQ === 1) {
        return { ...state }
      }

      drinkItemsQR[action.product.id].quantity = quantityIQ - 1;
      state.items.drinks[action.category.id] = drinkItemsQR;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.ADD_GIFT_ITEM:
      let giftItems = state.items.gifts;

      action.product.quantity = 1;
      giftItems[action.product.id] = action.product;
      state.items.gifts = giftItems;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };
    case ActionType.REMOVE_GIFT_ITEM:
      let giftItemsR = state.items.gifts;
      if (!giftItemExistInState(giftItemsR, action.product.id)) {
        return { ...state };
      }

      delete giftItemsR[action.product.id];
      state.items.gifts = giftItemsR;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };
    case ActionType.INCREASE_GIFT_ITEM_QUANTITY:
      let giftItemsQ = state.items.gifts;
      if (!giftItemExistInState(giftItemsQ, action.product.id)) {
        return { ...state };
      }

      let quantityG = giftItemsQ[action.product.id].quantity;
      giftItemsQ[action.product.id].quantity = quantityG + 1;
      state.items.gifts = giftItemsQ;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };
    case ActionType.DECREASE_GIFT_ITEM_QUANTITY:
      let giftItemsIT = state.items.gifts;
      if (!giftItemExistInState(giftItemsIT, action.product.id)) {
        return { ...state };
      }

      let quantityOT = giftItemsIT[action.product.id].quantity;
      if (quantityOT === 1) {
        return { ...state };
      }

      giftItemsIT[action.product.id].quantity = quantityOT - 1;
      state.items.gifts = giftItemsIT;
      state.items = { ...state.items };
      state.orderTotal = calculateOrder(state.items);

      return { ...state };

    case ActionType.ADD_FOOD_ITEM_EXTRA_COPIES:
      state.items.foodExtraCopies = action.copies;
      state.items = { ...state.items };
      return { ...state };

    case ActionType.ADD_FOOD_ITEM_EXTRA:
      let eFoodItems = state.items.food;
      eFoodItems[action.restaurant.id][action.product.id].extras.push(action.extra);
      state.items.food = eFoodItems;
      state.items = { ...state.items };
      return { ...state };

    case ActionType.REMOVE_FOOD_ITEM_EXTRA:
      let erFoodItems = state.items.food;
      erFoodItems[action.restaurant.id][action.product.id].extras = erFoodItems[action.restaurant.id][action.product.id].extras.filter(
        (exItem: any) => exItem.label !== action.extra.label
      );

      state.items.food = erFoodItems;
      state.items = { ...state.items };
      return { ...state };

    default:
      return state;
  }
};
