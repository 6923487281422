/* eslint-disable */
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import Total from '../../Cart/Page/Total';
import { addDrinkItem, decreaseDrinkItemQuantity, increaseDrinkItemQuantity, removeDrinkItem } from '../../Cart/Store/actions';
import OrderingDrinksCategories from './OrderingDrinksCategories';
import { fetchDrinks } from '../Store/actions';

export const getDrinkItemQuantity = (items: any, category: any, id: string): boolean => {
  return items.drinks[category.id][id].quantity;
};

export const hasDrinkItem = (items: any, category: any, id: string): boolean => {
  return items.drinks.hasOwnProperty(category.id) && items.drinks[category.id].hasOwnProperty(id);
};

const OrderingDrinks: FC<any> = () => {
  const {drinks}: any = useSelector((state: any) => state.order);
  const {items}: any = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const location = useLocation();

  let {category: categoryUrl}: any = parse(location.search);

  useEffect(() => {
    stableDispatch(fetchDrinks(categoryUrl !== 'all' ? categoryUrl : 'all'));
  }, [stableDispatch]);

  if (!drinks.categories || drinks.categories.length <= 0) {
    return (<></>);
  }

  return (
    <>
      <Helmet>
        <title>Ordering Drinks</title>
      </Helmet>

      <OrderingDrinksCategories categories={drinks.categories} fetchDrinks={fetchDrinks}  />

      <div className="menu">
        <div className="container container--menu">
          <p className="boxes__text">
            Check the drinks you want to take, quantity as well, make an order and your minions will bring you drink asap!
          </p>

          {
            drinks.categories.map((category: any) => {
              if (categoryUrl !== 'all' && category.id !== categoryUrl) {
                return;
              }

              return (
                <Fragment key={`${category.id}-fragment`}>
                  <div className={'menu__title'} key={category.id}>
                    {category.title}
                  </div>

                  {
                    drinks.drinks.map((product: any) => {
                      if (product.category !== category.id) {
                        return;
                      }

                      return (
                        <Fragment key={`${product.id}-fragment-2`}>
                          <div className="menu__item" key={`${product.id}-${Math.random() * Math.random()}`}>
                            <div className="menu__item-wrapper">
                              <div className="menu__item-name">
                                <span>
                                  {product.title}
                                </span>
                                {product.description}
                              </div>

                              <div className="menu__item-inner">
                                <div className="menu__item-inner">
                                  <div className="menu__item-checkbox">
                                    <input
                                      onChange={() => undefined}
                                      type="checkbox" checked={hasDrinkItem(items, category, product.id)}/>
                                    <label onClick={
                                      () => stableDispatch(
                                        hasDrinkItem(items, category, product.id)
                                          ? removeDrinkItem(category, product) : addDrinkItem(category, product),
                                      )
                                    }>
                                      <span/>
                                    </label>
                                  </div>
                                  <div className="menu__item-price">{product.price}€</div>
                                </div>

                                <div className="menu__item-step">
                                  <a
                                    href="#"
                                    className="menu__item-arrow menu__item-arrow--down"
                                    onClick={() => stableDispatch(decreaseDrinkItemQuantity(category, product))}
                                  />
                                  <span className="menu__item-number">
                                    {
                                      hasDrinkItem(items, category, product.id) ? getDrinkItemQuantity(items, category, product.id) : 0
                                    }
                                  </span>

                                  <a
                                    href="#"
                                    className="menu__item-arrow menu__item-arrow--up"
                                    onClick={() => stableDispatch(increaseDrinkItemQuantity(category, product))}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                  }
                </Fragment>
              );
            })
          }
        </div>
      </div>

      <Total/>
    </>
  );
};

export default OrderingDrinks;
