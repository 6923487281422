export const ActionType = {
  ADD_FOOD_ITEM: 'ADD_FOOD_ITEM',
  ADD_FOOD_ITEM_EXTRA: 'ADD_FOOD_ITEM_EXTRA',
  ADD_FOOD_ITEM_EXTRA_COPIES: 'ADD_FOOD_ITEM_EXTRA_COPIES',
  REMOVE_FOOD_ITEM_EXTRA: 'REMOVE_FOOD_ITEM_EXTRA',

  PURGE_STATE: 'PURGE_STATE',

  REMOVE_FOOD_ITEM: 'REMOVE_FOOD_ITEM',
  INCREASE_FOOD_ITEM_QUANTITY: 'INCREASE_FOOD_ITEM_QUANTITY',
  DECREASE_FOOD_ITEM_QUANTITY: 'DECREASE_FOOD_ITEM_QUANTITY',
  HAS_FOOD_ITEM: 'HAS_FOOD_ITEM',
  GET_FOOD_ITEM: 'GET_FOOD_ITEM',
  GET_FOOD_ITEM_QUANTITY: 'GET_FOOD_ITEM_QUANTITY',

  ADD_DRINK_ITEM: 'ADD_DRINK_ITEM',
  REMOVE_DRINK_ITEM: 'REMOVE_DRINK_ITEM',
  INCREASE_DRINK_ITEM_QUANTITY: 'INCREASE_DRINK_ITEM_QUANTITY',
  DECREASE_DRINK_ITEM_QUANTITY: 'DECREASE_DRINK_ITEM_QUANTITY',

  HAS_DRINK_ITEM: 'HAS_DRINK_ITEM',
  GET_DRINK_ITEM: 'GET_DRINK_ITEM',
  GET_DRINK_ITEM_QUANTITY: 'GET_DRINK_ITEM_QUANTITY',

  ADD_GIFT_ITEM: 'ADD_GIFT_ITEM',
  REMOVE_GIFT_ITEM: 'REMOVE_GIFT_ITEM',
  INCREASE_GIFT_ITEM_QUANTITY: 'INCREASE_GIFT_ITEM_QUANTITY',
  DECREASE_GIFT_ITEM_QUANTITY: 'DECREASE_GIFT_ITEM_QUANTITY',

  HAS_GIFT_ITEM: 'HAS_GIFT_ITEM',
  GET_GIFT_ITEM: 'GET_GIFT_ITEM',
  GET_GIFT_ITEM_QUANTITY: 'GET_GIFT_ITEM_QUANTITY',

  GET_ORDER_AMOUNT: 'GET_ORDER_AMOUNT',
  GET_ORDER_TOTAL: 'GET_ORDER_TOTAL',
  GET_ORDER_EXTRA_STOP: 'GET_ORDER_EXTRA_STOP',
  GET_ORDER_DELIVERY_FEE: 'GET_ORDER_DELIVERY_FEE',
  GET_ORDER_SURCHARGE: 'GET_ORDER_SURCHARGE',
  GET_ORDER_ZONE: 'GET_ORDER_ZONE',

  GET_SETTING: 'GET_SETTING',
  FETCH_STORE_SETTINGS: 'FETCH_STORE_SETTINGS',
  FETCH_STORE_SETTINGS_FULFILLED: 'FETCH_STORE_SETTINGS_FULFILLED',
  CALCULATE_VALUES: 'CALCULATE_VALUES',
  CALCULATE_ORDER_TOTAL: 'CALCULATE_ORDER_TOTAL',
  GET_ORDER_CART_DATA: 'GET_ORDER_CART_DATA',

  FETCH_BRAINTREE_CLIENT_TOKEN: 'FETCH_BRAINTREE_CLIENT_TOKEN',
  FETCH_BRAINTREE_CLIENT_TOKEN_DONE: 'FETCH_BRAINTREE_CLIENT_TOKEN_DONE',

  SALE_BRAINTREE: 'SALE_BRAINTREE',
  SALE_BRAINTREE_FUl_FILLED: 'SALE_BRAINTREE_FUl_FILLED',
  SALE_BRAINTREE_DONE: 'SALE_BRAINTREE_DONE',

  CHANGE_ZONE: 'CHANGE_ZONE',
};
