import { Map as Gmap, Marker, GoogleApiWrapper, Polygon } from 'google-maps-react';
import React, { Component } from 'react';
import { MapStyles } from './MapStyles';
import { MapZones } from './Zone';
import MapAutocomplete from './MapAutocomplete';

const mapLoaded = (_mapProps: any, map: any) => {
  map.setOptions({
    styles: MapStyles,
    disableDefaultUI: true,
    scaleControl: true,
    scrollwheel: true,
    draggable: true,
    zoomControl: false,
    mapTypeControl: false,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'satellite']
    },
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
  });
};

export class Map extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      location: { lat:37.45330461355368, lng: 25.380000101693696 },
      zone: 2,
    };

    this.mapChange = this.mapChange.bind(this);
  }

  componentDidMount(): void {
    this.props.onLocation(this.state.location);
  }

  mapChange(_props: any, marker: any, place = false, polygon = false) {
    let location = (() => {
      if (place) {
        const { geometry: { location } } = marker;
        return location;
      }

      if (polygon) {
        return marker.latLng;
      }

      const { position } = marker;
      return position;
    })();

    let foundMarkerInZone: any = null;
    const zoneMap: any = {
      'first': 1,
      'second': 2,
      'third': 3,
      'city': 4,
    };

    const polygons: any = {
      'first': new this.props.google.maps.Polygon({ paths: MapZones[0].paths }),
      'second': new this.props.google.maps.Polygon({ paths: MapZones[1].paths }),
      'third': new this.props.google.maps.Polygon({ paths: MapZones[2].paths }),
      'city': new this.props.google.maps.Polygon({ paths: MapZones[3].paths }),
    };

    for (let zone in polygons) {
      if (!polygons.hasOwnProperty(zone)) {
        continue;
      }

      let zonePolygon = polygons[zone];
      let zoneContainsMarker = this.props.google.maps.geometry.poly.containsLocation(location, zonePolygon);

      if (zoneContainsMarker) {
        if (zone === 'city') {
          if (this.props.checkForInCity === true) {
            this.props.onCheckInCity();
            continue;
          }

          foundMarkerInZone = zoneMap[zone];
          continue;
        }

        foundMarkerInZone = zoneMap[zone];
      }
    }

    this.props.onLocation({ lat: location.lat(), lng: location.lng() });
    this.props.onZone(foundMarkerInZone);

    this.setState({
      ...this.state,
      location
    });
  }

  render() {
    return (
      <div style={{ width: '100%', height: '300px' }} className={"mapParent"}>
        <Gmap
          google={this.props.google}
          zoom={11}
          initialCenter={this.state.location}
          center={this.state.location}
          onReady={(mapProps, map) => mapLoaded(mapProps, map)}
          draggable={false}
        >
          {
            MapZones.map(({ paths, options: { fillColor, fillOpacity, strokeOpacity, strokeWeight } }: any, idx: any) => {
              return (
                <Polygon
                  key={`polygon-${idx}`}
                  paths={paths}
                  strokeOpacity={strokeOpacity}
                  strokeWeight={strokeWeight}
                  fillColor={fillColor}
                  fillOpacity={fillOpacity}
                  onClick={(mapProps: any, _an: any, event: any) => {
                    this.mapChange(mapProps, event, false, true);
                  }}
                />
              );
            })
          }

          <Marker
            icon={"/images/markers/employment.png"}
            draggable={true}
            position={this.state.location}
            onDragend={(props: any, marker: any) => this.mapChange(props, marker)}
          />
        </Gmap>

        {
          this.props.google ? (
            <MapAutocomplete
              google={this.props.google}
              onAutocomplete={(place: any) => {
                this.mapChange(null, place, true);
                this.props.onAutocomplete(place.name);
              }}
            />
          ) : (<></>)
        }
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyB8j3DCjzYnANYnIu5yA6sCQEI-sKCAtro'),
  libraries: ['places']
})(Map)
