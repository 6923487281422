import { ActionType } from './types';

const initialState = {
  showModal: false,
  modal: {
    title: '',
    description: '',
  },
  settings: {},
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_SETTINGS:
      return { ...state, settings: initialState.settings };

    case ActionType.FETCH_SETTINGS_FULFILLED:
      return { ...state, settings: action.settings };

    case ActionType.SHOW_MODAL:
        const { title, description } = action;
        return {
          ...state,
          showModal: true,
          ...{
            modal: {
              title,
              description
            }
          }
        };

    case ActionType.HIDE_MODAL:
      return { ...state, showModal: false };
    default:
      return state;
  }
};
