import React, { FC } from "react";
import TextField from "context/Core/Form/Text";
import SelectField from "context/Core/Form/Select";
import { dialCodes, DialCodeConfig } from "context/Core/Form/config";
import FormSpanError from './SpanError';

type Props = {
  errors: any;
  register: any;
  label?: string;
  sValue: any;
};

const PhoneNumber: FC<Props> = ({ label, errors, register, sValue: setValue }) => {
  const dialCodeOptions = dialCodes.map(({ name, dialCode }: DialCodeConfig): {
    value: string;
    label: string;
  } => ({
    value: dialCode,
    label: `${dialCode} (${name})`
  }));

  return (
    <>
      <label className="form__label">
        {label || "Your phone number (WhatsApp preferred)"}
      </label>
      <div className="phoneNumber">
        <SelectField
          name="phonePrefix"
          options={dialCodeOptions}
          error={errors.phoneDialCode}
          className="phonePrefix"
          childRef={register({
            required: "Required",
            name: "phonePrefix",
          })}
          onChange={(val: any) => setValue("phonePrefix", val.value)}
          styles={{
            control: (styles: object): object => ({
              ...styles,
              height: "50px"
            })
          }}
        />

        <TextField
          name="phoneNumber"
          error={errors.phoneDialCode}
          childRef={register({
            required: "Required",
            pattern: {
              value: /^\d+$/i,
              message: "invalid number"
            }
          })}
        />
      </div>

      <FormSpanError error={errors.phoneNumber} />
    </>
  );
};

export default PhoneNumber;
