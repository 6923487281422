import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { LinkDefinition, SocialLink } from "context/Core/types";
import { formatRoute } from "react-router-named-routes";
import { routeNames as homeRoutes } from "context/Home/Routing";

type FooterProps = {
  groupedLinks?: LinkDefinition[][];
  socialLinks?: SocialLink[];
};

const Footer: FC<FooterProps> = ({ groupedLinks = [], socialLinks = [] }) => {
  return (
    <div className="footer">
      <div className="footer__bottom">
        <div className="container">
          <div className="footer__links">
            {groupedLinks.map(
              (linkGroup: LinkDefinition[], i: number): ReactNode => (
                <ul className="footer__list" key={i}>
                  {linkGroup.map(
                    ({ to, label }: LinkDefinition, j: number): ReactNode => (
                      <li className="footer__list-item" key={j}>
                        <Link to={to} className="footer__list-link">
                          {label}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              )
            )}
          </div>

          <div className="footer__social">
            <div>
              <Link
                to={formatRoute(homeRoutes.HOME)}
                className="footer__logo"
              />

              <ul className="footer__social-list">
                {socialLinks.map(
                  ({ title, href }: SocialLink, i: number): ReactNode => (
                    <li className="footer__social-list-item" key={i}>
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer__social-list-link"
                        title={title}
                      >
                        {title}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>

            <p className="footer__copyright">
              &copy; {new Date().getFullYear()} MykoMinions. All Rights
              Reserved.
            <a href="https://www.timepad.rs/" target="_blank"> Developed by Timepad</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
