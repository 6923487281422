import React, { FC, useEffect } from "react";
const InstagramFeed = require("instafeed.js");

const attachInstagramFeed = (): void => {
  new InstagramFeed({
    accessToken:
      "IGQVJYX0ZA5T054NFFLT1pUQ3I1VGgxajdtNXV1ZAmtOYVpVV1NKb1J3TnFXaGRpWmZAaSWV6cTh2aEVYdVdoYjRJZAjdpbUp1OGFEZAW01a0JQRlZAmeXhEQ2JXWUN5M1hnYW5BSUZA2TG5EaHk0NnJ6TWhkbgZDZD",
    get: "user",
    limit: 4,
    resolution: "standard_resolution",
    target: "instagram",
    template: `<div class="instagram__image" style="background-image: url('{{image}}');"></div>`,
    userId: "7685168546"  
}).run();
};

const Instagram: FC = () => {
  useEffect(() => attachInstagramFeed(), []);
  return (
    <>
      <div className="instagram">
        <div className="container">
          <div className="instagram__title">Sneak peak</div>
          <a
            href="https://www.instagram.com/myko.minions/"
            target="_blank"
            className="instagram__link"
            rel="noopener noreferrer"
          >
            @myko.minions
          </a>
          <div id="instagram" className="instagram__wrapper"></div>
        </div>
      </div>
    </>
  );
};

export default Instagram;
