/* eslint-disable */
import React, { FC, useCallback, useEffect } from 'react';
import Helmet from "react-helmet";
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from '../Routing';
import { formatRoute } from "react-router-named-routes";
import OrderingFoodCategories from './OrderingFoodCategories';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurantCategories, fetchRestaurants } from '../Store/actions';
import { parse } from 'query-string';
import { placeWorkingTimeClass } from './WorkingHours';

type Props = {};

const OrderingFood: FC<Props> = () => {
  const { restaurants }: any = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const location = useLocation();

  let { category }: any = parse(location.search);

  useEffect(() => {
    stableDispatch(fetchRestaurants(category !== 'all' ? category : 'all'));
    stableDispatch(fetchRestaurantCategories());
  }, [stableDispatch]);

  return (
    <>
      <Helmet>
        <title>Ordering Food</title>
      </Helmet>

      <OrderingFoodCategories fetchRestaurants={fetchRestaurants} />

      <div className="boxes">
        <div className="container">
          <p className="boxes__text">
            Chose your restaurant (or more than one), make an order(s) and your minions will bring you your food asap!
          </p>

          <div className="boxes__wrapper">
            {
              restaurants.map((place: any, _k: any) => {
                return (
                  <Link to={formatRoute(routeNames.ORDERING_FOOD_PLACE, { slug: place.slug })} key={place.id} className={"box"}>
                    <div className="box__inner">
                      <div className="box__image" style={{ backgroundImage: `url(${place.thumbnail})` }}>
                        <span className={placeWorkingTimeClass(place)}>
                          {placeWorkingTimeClass(place, false)}
                        </span>
                    </div>
                      <div className="box__content">
                        <h4 className="box__title">{ place.name }</h4>
                        <div className="scrollableTags">
                          {
                            place.category && place.category.length > 0 ? (
                              place.category.map((item: any) => {
                                return (
                                  <span className="box__category" key={item.id}>
                                  { item.title }
                                </span>
                                )
                              })
                            ) : (<></>)
                          }
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderingFood;
