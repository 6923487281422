import React, { FC, useCallback, useEffect, useState } from 'react';
import Helmet from "react-helmet";
import ConciergeReservationForm from '../Component/ConciergeReservationForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationTree } from '../Store/actions';
import { Redirect } from 'react-router-dom';
import { routeNames } from '../Routing';

const ConciergeReservations: FC = () => {
  const [selected, setSelected]: any = useState("known");
  const { redirect, reservationTree } = useSelector((state: any) => state.concierge);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchReservationTree());
  }, [stableDispatch]);

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  return (
    <>
      <Helmet>
        <title>Concierge Reservations</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              To all the hedonist, if you came for a good party, or for the best food and wine, don't worry about the reservation part, because our people have already saved for you the best seats and tables at the best clubs, beach bars, and restaurants.
              All you have to do is to fill up the form below and tell us if you know where would you like to go, or you need recommendations from us based on your style and mood ?
            </p>
          </div>
        </div>

        <div className="form">
          <div className="container container--small">
            <div className="form__group-wrapper form__group-wrapper--checkbox">
              <div className="form__group form__group--half">
                <input
                  name="type"
                  type="checkbox"
                  className="form__control"
                  checked={selected && selected === "known"}
                  onChange={() => undefined}
                />
                <label className="form__label form__label--checkbox" onClick={() => setSelected("known")}>
                  <span/>
                  I KNOW WHERE I'M GOING
                </label>
              </div>

              <div className="form__group form__group--half">
                <input
                  name="type"
                  type="checkbox"
                  className="form__control"
                  checked={selected && selected === "help"}
                  onChange={() => undefined}
                />
                <label className="form__label form__label--checkbox" onClick={() => setSelected("help")}>
                  <span/>
                  I NEED SOME HELP
                </label>
              </div>
            </div>

            <ConciergeReservationForm
              selected={selected}
              reservationTree={reservationTree}
            />
          </div>
        </div>
      </div>

    </>
  )
};

export default ConciergeReservations;
