import React, { FC, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { routeNames as homeRoutes } from "context/Home/Routing";
import { LinkDefinition } from "context/Core/types";

type NavigationProps = {
  links?: LinkDefinition[];
};

const Navigation: FC<NavigationProps> = ({ links = [] }) => {
  const location = useLocation();

  return (
    <>
      <Link
        to={formatRoute(homeRoutes.HOME)}
        className="navigation__logo navigation__logo--black"
      />

      <ul className="navigation__links">
        {links.map(
          ({ to, label }: LinkDefinition): ReactNode => (
            <li
              className={`navigation__links-item ${
                location.pathname === to ? "router-link-active" : ""
              }`}
              key={to}
            >
              <Link to={to} className="navigation__links-link" title={label}>
                {label}
              </Link>
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default Navigation;
