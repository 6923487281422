import React from "react";
import { Route } from "react-router-dom";
import { RouteNames, RoutingDefinition } from "context/Core/types";
import OrderingPage from "./Page/Ordering";
import OrderingCustomPage from "./Page/OrderingCustom";
import OrderingDrinksPage from "./Page/OrderingDrinks";
import OrderingFoodPage from "./Page/OrderingFood";
import OrderingGiftsPage from "./Page/OrderingGift";
import OrderingTransportPage from "./Page/OrderingTransport";
import OrderingFoodPlace from './Page/OrderingFoodPlace';
import OrderingMarketShop from './Page/OrderingMarket';
import MarketSummary from './Page/MarketSummary';
import MarketCheckout from './Component/MarketCheckout';
import OrderingStatus from './Page/OrderingStatus';

export const routeNames: RouteNames = {
  ORDERING: "/ordering",
  ORDERING_CUSTOM: "/ordering/custom",
  ORDERING_MARKET: "/ordering/market",
  ORDERING_MARKET_SUMMARY: "/ordering/market-summary",
  ORDERING_MARKET_CHECKOUT: "/ordering/market-checkout",
  ORDERING_DRINKS: "/ordering/drinks",
  ORDERING_FOOD: "/ordering/food",
  ORDERING_FOOD_PLACE: '/ordering/:slug',
  ORDERING_GIFTS: "/ordering/gifts",
  ORDERING_TRANSPORT: "/ordering/transport",
  ORDERING_STATUS: "/ordering/:id/:status",
};

const Routing: RoutingDefinition = [
  <Route
    key={routeNames.ORDERING_STATUS}
    path={routeNames.ORDERING_STATUS}
    exact
    component={OrderingStatus}
  />,
  <Route
    key={routeNames.ORDERING}
    path={routeNames.ORDERING}
    exact
    component={OrderingPage}
  />,
  <Route
    key={routeNames.ORDERING_CUSTOM}
    path={routeNames.ORDERING_CUSTOM}
    exact
    component={OrderingCustomPage}
  />,
  <Route
    key={routeNames.ORDERING_MARKET}
    path={routeNames.ORDERING_MARKET}
    exact
    component={OrderingMarketShop}
  />,
  <Route
    key={routeNames.ORDERING_MARKET_SUMMARY}
    path={routeNames.ORDERING_MARKET_SUMMARY}
    exact
    component={MarketSummary}
  />,
  <Route
    key={routeNames.ORDERING_MARKET_CHECKOUT}
    path={routeNames.ORDERING_MARKET_CHECKOUT}
    exact
    component={MarketCheckout}
  />,
  <Route
    key={routeNames.ORDERING_DRINKS}
    path={routeNames.ORDERING_DRINKS}
    exact
    component={OrderingDrinksPage}
  />,
  <Route
    key={routeNames.ORDERING_FOOD}
    path={routeNames.ORDERING_FOOD}
    exact
    component={OrderingFoodPage}
  />,
  <Route
    key={routeNames.ORDERING_GIFTS}
    path={routeNames.ORDERING_GIFTS}
    exact
    component={OrderingGiftsPage}
  />,
  <Route
    key={routeNames.ORDERING_TRANSPORT}
    path={routeNames.ORDERING_TRANSPORT}
    exact
    component={OrderingTransportPage}
  />,
  <Route
    key={routeNames.ORDERING_FOOD_PLACE}
    path={routeNames.ORDERING_FOOD_PLACE}
    exact
    component={OrderingFoodPlace}
  />
];

export default Routing;
