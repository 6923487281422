import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from '../Routing';
import { useSelector } from 'react-redux';

const MarketTotal: FC = () => {
  const { market: { total } }: any = useSelector((state: any) => state.order);

  return (
    <>
      <div className={"order-total"}>
        <div className={"container"}>
          <div className={"order-total__inner"}>
            Total {total}€
            <Link to={routeNames.ORDERING_MARKET_SUMMARY} className={"button"}>
              Order
            </Link>
          </div>
        </div>
      </div>
    </>
  )
};

export default MarketTotal;
