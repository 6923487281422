import { ActionType } from './types';

const requestMap: any = {
  [ActionType.REQUEST_CUSTOM]: 'custom',
  [ActionType.REQUEST_CART]: 'cart',
  [ActionType.REQUEST_MARKET]: 'market',
};

export function orderRequest(requestType: string, payload: any, showNotification = true) {
  return {
    type: requestType,
    requestType: requestMap[requestType],
    payload,
    showNotification,
  };
}

export function resetState() {
  return {
    type: ActionType.RESET_STATE,
  }
}

export function setRequestedOrder(order: any) {
  return {
    type: ActionType.SET_REQUESTED_ORDER,
    order,
  }
}

export function orderRequestStatus(id: any, status: any) {
  return {
    type: ActionType.SET_REQUEST_ORDER_STATUS,
    id,
    status,
  };
}

export function orderRequestFulFilled() {
  return {
    type: ActionType.REQUEST_FUL_FILLED,
  }
}

export function orderRequestUnFilFill() {
  return {
    type: ActionType.REQUEST_UN_FUL_FILL,
  }
}

export function fetchRestaurants(category: string = 'all') {
  return {
    type: ActionType.FETCH_RESTAURANT,
    category,
  }
}

export function fetchRestaurantsFulFilled(restaurants: [] = []) {
  return {
    type: ActionType.FETCH_RESTAURANT_FULFILLED,
    restaurants,
  }
}

export function fetchRestaurantCategories() {
  return {
    type: ActionType.FETCH_RESTAURANT_CATEGORIES,
  }
}

export function fetchRestaurantCategoriesFulFilled(categories: [] = []) {
  return {
    type: ActionType.FETCH_RESTAURANT_CATEGORIES_FULFILLED,
    categories,
  }
}

export function fetchRestaurantProducts(slug: string, tag: string = 'all') {
  return {
    type: ActionType.FETCH_RESTAURANT_PRODUCTS,
    slug,
    tag
  }
}

export function fetchRestaurantProductsFulFilled(products: [] = []) {
  return {
    type: ActionType.FETCH_RESTAURANT_PRODUCTS_FULFILLED,
    products,
  }
}

export function fetchGifts() {
  return {
    type: ActionType.FETCH_GIFTS,
  }
}

export function fetchGiftsFulFilled(gifts: [] = []) {
  return {
    type: ActionType.FETCH_GIFTS_FULFILLED,
    gifts,
  }
}

export function fetchDrinks(category: string = 'all') {
  return {
    type: ActionType.FETCH_DRINKS,
    category,
  }
}

export function fetchDrinksFulFilled(drinks: [] = []) {
  return {
    type: ActionType.FETCH_DRINKS_FULFILLED,
    drinks,
  }
}

export function addMarketShopProduct(product: any) {
  return {
    type: ActionType.ADD_MARKET_SHOP_PRODUCT,
    product,
  }
}

export function removeMarketShopProduct(key: any) {
  return {
    type: ActionType.REMOVE_MARKET_SHOP_PRODUCT,
    key,
  }
}

export function setSetting(settings: any) {
  return {
    type: ActionType.SET_SETTINGS,
    settings,
  }
}

export function changeZoneMarker(zone: any) {
  return {
    type: ActionType.CHANGE_ZONE_MARKER,
    zone,
  }
}

export function orderRequestMarket(requestType: string, payload: any) {
  return {
    type: requestType,
    requestType: requestMap[requestType],
    payload,
  }
}

export function calculateAmount() {
  return {
    type: ActionType.GET_AMOUNT,
  }
}

export function changeMarketShopProductQuantity(key: any, value: any) {
  return {
    type: ActionType.CHANGE_MARKET_SHOP_PRODUCT_QUANTITY,
    key,
    value,
  }
}

export function changeMarketShopProductLabel(key: any, value: any) {
  return {
    type: ActionType.CHANGE_MARKET_SHOP_PRODUCT_LABEL,
    key,
    value,
  }
}
