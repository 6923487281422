import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../Store/actions';

export const modalMessages: any = {
  marker: {
    'not-in-zone': {
      title: 'Dear customer',
      text: 'Please select a delivery location in Mykonos!\n' +
        'We are Mykonos delivery service only.\n' +
        'Thank you!'
    },

    'in-city-zone': {
      title: 'Save your money!',
      text: 'You have everything around you in the Mykonos downtown ;)\n' +
        '\n' +
        'However, if you still need our services, please make a custom order and wait for our reply.'
    }
  },

  concierge: {
    accommodation: {
      title: 'Success!',
      text: 'We received your accommodation request. Check your email for detailed information. Your minions.'
    },

    renting: {
      title: 'Success!',
      text: 'We received your renting boat request. Check your email for detailed information. Your minions.'
    },

    reservation: {
      title: 'Success!',
      text: 'We received your reservation request. Check your email for detailed information. Your minions.'
    },

    personalService: {
      title: 'Success!',
      text: 'We received your personal service request. Check your email for detailed information. Your minions.'
    },

    tripPlanning: {
      title: 'Success!',
      text: 'We received your trip planning request. Check your email for detailed information. Your minions.'
    },

    contact: {
      title: 'Success!',
      text: 'Thank you for your message. Check your email we will contact you ASAP.',
    },
  },

  ordering: {
    closed: {
      title: 'Dear Mykonian!',
      text: 'Minions are not yet on the island. Stay tuned.'
    },

    cart: {
      title: 'Success!',
      text: 'We received your order! Check your email for detailed information. Your minions.'
    },

    waiting_response: {
      title: 'Hey!',
      text: 'Thank you for using our services. Your minions.'
    },

    custom: {
      title: 'Success!',
      text: 'We received your custom order request. Check your email for detailed information. Your minions.'
    },

    market: {
      title: 'Success!',
      text: 'We received your market shop order request. Check your email for detailed information. Your minions.'
    },

    transport: {
      title: 'Success!',
      text: 'We received your transport order request. Check your email for detailed information. Your minions.'
    }
  },
};

const Modal: FC = () => {
  const {
    showModal: modalVisible,
    modal: { title, description }
  }: any = useSelector((state: any) => state.core);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  if (!modalVisible) {
    return (<></>);
  }

  return (
    <>
    <div className="popup">
      <div className="popup__title">
        { title }
      </div>

      <div className="popup__text">
        <p>{ description }</p>
      </div>

      <a href="/"
         onClick={(e: any) => {
           e.preventDefault();
           stableDispatch(hideModal());
         }}
         className="button button--full">
        Close
      </a>
    </div>

    </>
  );
};

export default Modal;
