import { flatMap, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import { ConciergeSite } from 'medium/api';
import {
  conciergeRequestFulFilled,
  fetchPersonalServiceTypesFulFilled,
  fetchRentingTypesFulFilled,
  fetchReservationTreeFulFilled,
  fetchTripPlanningTreeFulFilled
} from './actions';
import { showModal } from '../../Core/Store/actions';
import { modalMessages } from '../../Core/Component/Modal';

const { concierge }: any = modalMessages;

const conciergeRequest = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(
      ActionType.REQUEST_ACCOMMODATION,
      ActionType.REQUEST_RENTING,
      ActionType.REQUEST_PERSONAL_SERVICE,
      ActionType.REQUEST_RESERVATION,
      ActionType.REQUEST_TRIP_PLANNING,
      ActionType.REQUEST_CONTACT_FORM,
    ),
    mergeMap(({ payload, requestType: type }: any) => {
      return from(Service.request(ConciergeSite.request, { type }, payload)).pipe(
        flatMap(() => {
            if (type === 'personal-service') {
              type = 'personalService';
            }

            if (type === 'trip-planning') {
              type = 'tripPlanning';
            }

            return concat(
              of(showModal(concierge[type].title, concierge[type].text)),
              of(conciergeRequestFulFilled()),
            );
          },
        ),
      );
    }),
  );
};

const conciergeFetchRentingTypes = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RENTING_TYPES),
    mergeMap(() => {
      return from(Service.request(ConciergeSite.fetchRentingTypes)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchRentingTypesFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const conciergeFetchPersonalServiceTypes = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_PERSONAL_SERVICE_TYPES),
    mergeMap(() => {
      return from(Service.request(ConciergeSite.fetchPersonalServiceTypes)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchPersonalServiceTypesFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const conciergeFetchReservationTree = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESERVATION_TREE),
    mergeMap(() => {
      return from(Service.request(ConciergeSite.fetchReservationTree)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchReservationTreeFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const conciergeFetchTripPlanningTree = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_TRIP_PLANNING_TREE),
    mergeMap(() => {
      return from(Service.request(ConciergeSite.fetchTripPlanningTree)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchTripPlanningTreeFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

export default {
  conciergeRequest,
  conciergeFetchRentingTypes,
  conciergeFetchPersonalServiceTypes,
  conciergeFetchReservationTree,
  conciergeFetchTripPlanningTree,
};
