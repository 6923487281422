import React, { FC } from "react";
import Helmet from "react-helmet";
import OrderingCustomForm from '../Component/OrderingCustom';

const OrderingCustom: FC = () => (
  <>
    <Helmet>
      <title>Ordering Custom</title>
    </Helmet>

    <div>
      <div className="tagline tagline--small">
        <div className="container">
          <p>
            Hate shopping? Foreign products in markets that confuse you, the crowd in busy stores, long waiting lines, busy streets, and roads that you don't know ?
            Or maybe you forgot your keys somewhere on the island and you don't have time to pick them up? You need adapters or similar stuff and you don't know where to find it?
            Or any else custom situation? You don't need to waste your Mykonos time for this. Your Minions will make the job done for you.
            Please just choose below do you want market shopping or something else
          </p>
        </div>
      </div>

      <OrderingCustomForm />
    </div>
  </>
);

export default OrderingCustom;
