import React, { FC } from "react";
import Helmet from "react-helmet";
import ContactForm from "context/Contact/Component/ContactForm";

const Contact: FC = () => (
  <>
    <Helmet>
      <title>Contact Us</title>
    </Helmet>

    <div>
      <div className="single-header single-header--flat">
        <h1 className="single-header__title">Contact Us</h1>
      </div>
      <div className="tagline tagline--small">
        <div className="container">
          Phone:
          <b>
            <a href="tel:+306974613241"> +30 697 461 3241</a>
          </b>
          <br />
          Email:
          <b>
            <a href="mailto:office@myko-minions.com"> office@myko-minions.com</a>
          </b>
          <br />
        </div>
      </div>

      <div className="single">
        <div className="container container--small">
          <ContactForm />
        </div>
      </div>
    </div>
  </>
);

export default Contact;
