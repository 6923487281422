import React, { FC, useCallback, useEffect } from 'react';
import Helmet from "react-helmet";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { orderRequestUnFilFill } from '../Store/actions';

const orderMap: any = [
  {
    food: {
      image: 'food.jpg',
      path: 'ordering/food',
      label: 'Food',
    },
  },

  {
    drinks: {
      image: 'drinks.jpg',
      path: 'ordering/drinks',
      label: 'Drinks',
    },
  },

  {
    custom: {
      image: 'custom.jpg',
      path: 'ordering/custom',
      label: 'Custom',
    },
  },


  {
    market: {
      image: 'custom.jpg',
      path: 'ordering/market',
      label: 'Market Shop',
    },
  },

  {
    gift: {
      image: 'gift.jpg',
      path: 'ordering/gifts',
      label: 'Gift',
    },
  },

  {
    transport: {
      image: 'transport.jpg',
      path: 'ordering/transport',
      label: 'Transport',
    },
  },
];

const Ordering: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(orderRequestUnFilFill());
  }, [stableDispatch]);

  return (
    <>
      <Helmet>
        <title>Ordering</title>
      </Helmet>
      <div>
        <div className="tagline">
          <div className="container">
            <p>
              We are Myko Minions, premium delivery service company founded to meet your desires
              and to make stay at Mykonos as pleasant as possible.
            </p>
          </div>
        </div>

        <div className="orders">
          <div className="container">
            <div className="orders__wrapper">
              {
                orderMap.map((item: any, name: string) => {
                  const order: any = item[Object.keys(item)[0]];
                  return (
                    <div className={"order"} onClick={() => history.push(order.path)} key={`order-${name}`}>
                      <img src={`/images/ordering/${order.image}`} className={"order__image"} alt={name}/>
                      <div className="order__content">
                        {order.label}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ordering;
