import React, { FC } from 'react';

const Offer: FC = () => (
  <>
    <div className="delivery">
      <div className="container">
        <div className="delivery__offers">
          <div className="delivery__offer">
            <div className="delivery__offer-icon" />

            <div className="delivery__offer-content">
              <div className="delivery__offer-title">Fast delivery</div>

              <p className="delivery__offer-text">We know how Mykonos breathes, and thats why we can ensure the fastest
                delivery possible! Delivery time - max 45 min</p>
            </div>
          </div>

          <div className="delivery__offer">
            <div className="delivery__offer-icon" />

            <div className="delivery__offer-content">
              <div className="delivery__offer-title">Delivery anywhere</div>

              <p className="delivery__offer-text">Wherever you are on Mykonos, we can deliver. Just contact us and we
                will be there soon!</p>
            </div>
          </div>

          <div className="delivery__offer">
            <div className="delivery__offer-icon" />

            <div className="delivery__offer-content">
              <div className="delivery__offer-title">Around the clock</div>

              <p className="delivery__offer-text">
                Mykonos never sleeps! We don't sleep either. Make your order anytime of the day, every day of the week!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Offer;
