import React, { FC,  } from 'react';
import { useForm } from "react-hook-form";
import TextField from "context/Core/Form/Text";
import EmailField from "context/Core/Form/Email";
import NumericField from "context/Core/Form/Numeric";
import { Redirect } from 'react-router-dom';
import TextAreaField from "context/Core/Form/TextArea";
import PhoneNumberField from "context/Core/Form/PhoneNumber";
import DatePicker from "context/Core/Form/DatePicker";
import { useDispatch, useSelector } from 'react-redux';
import { conciergeRequest } from '../Store/actions';
import { ActionType } from '../Store/types';
import { routeNames } from '../Routing';

type Props = {};

const ConciergeAccommodationForm: FC<Props> = () => {
  const redirect = useSelector((state: any) => state.concierge.redirect);
  const { handleSubmit, register, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: object): any => {
    return dispatch(conciergeRequest(ActionType.REQUEST_ACCOMMODATION, data));
  };

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="container container--small">
        <div className="form__group-wrapper">
          <div className="form__group form__group--half">
            <TextField
              name="name"
              label="Your name"
              childRef={register({
                required: "Required"
              })}
              error={errors.name}
            />
          </div>
          <div className="form__group form__group--half">
            <PhoneNumberField
              sValue={setValue}
              errors={errors}
              register={register}
            />
          </div>
        </div>

        <div className="form__group-wrapper">
          <div className="form__group form__group--half">
            <EmailField
              name="email"
              label="Your email"
              error={errors.email}
              childRef={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address"
                }
              })}
            />
          </div>
          <div className="form__group form__group--half">
            <NumericField
              name="budget"
              label="Your budget"
              placeholder="500 €"
              error={errors.budget}
              childRef={register({
                required: "Required",
                pattern: {
                  value: /^\d+$/i,
                  message: "invalid number"
                }
              })}
            />
          </div>
        </div>

        <div className="form__group-wrapper">
          <div className="form__group form__group--half">
            <NumericField
              name="numberOfPeople"
              label="Number of People"
              error={errors.numberOfPeople}
              childRef={register({
                required: "Required",
                pattern: {
                  value: /^\d+$/i,
                  message: "invalid number"
                }
              })}
            />
          </div>

          <div className="form__group form__group--half">
            <NumericField
              name="numberOfBedrooms"
              label="Number of Bedrooms"
              error={errors.numberOfBedrooms}
              childRef={register({
                required: "Required",
                pattern: {
                  value: /^\d+$/i,
                  message: "invalid number"
                }
              })}
            />
          </div>
        </div>

        <div className="form__group-wrapper">
          <div className="form__group form__group--half">
            <label className="form__label">Check in Date</label>
             <DatePicker
               name={"checkInDate"}
               error={errors.checkInDate}
               className={"checkInDate"}
               onChange={(val: any) => setValue("checkInDate", val)}
               childRef={register({
                 name: "checkInDate",
                 required: "Field Check In Date is required.",
               })}
             />
          </div>

          <div className="form__group form__group--half">
            <label className="form__label">Check out Date</label>
            <DatePicker
              name={"checkOutDate"}
              error={errors.checkOutDate}
              className={"checkOutDate"}
              onChange={(val: any) => setValue("checkOutDate", val)}
              childRef={register({
                name: "checkOutDate",
                required: "Field Check Out Date is required.",
              })}
            />
          </div>
        </div>

        <div className="form__group">
          <TextAreaField
            name="notes"
            label="Notes"
            cols={30}
            rows={6}
            error={errors.notes}
            onChange={(val: any) => setValue("notes", val.target.value)}
            childRef={register({ name: "notes" })}
          />
        </div>

        <button type="submit" className="button">
          Order
        </button>
      </div>
    </form>
  );
};

export default ConciergeAccommodationForm;
