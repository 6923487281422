import _ from 'lodash';
import moment from 'moment';

export const placeWorkingTimeClass = (place: any, returnClass: any = true) => {
  let currentWeekDay = moment().weekday();
  let defaultPlaceClass = 'box__status box__status--';
  let placeClassCases = {
    open: 'open',
    closed: 'closed',
    closing: 'closing',
  };

  let foundWorkingHourDay = null;
  for (let k in place.workingHours) {
    if (!place.workingHours.hasOwnProperty(k)) {
      continue;
    }

    if ((Number(k)) === Number(currentWeekDay)) {
      foundWorkingHourDay = place.workingHours[k];
    }
  }

  let placeClass = '';

  if (foundWorkingHourDay !== null) {
    let openClosed = null;
    let { to: timeTill, from: timeFrom} = foundWorkingHourDay;
    let currentDate = moment();
    let currentHours = currentDate.hours();
    let currentMinutes = currentDate.minutes();

    let currentTime = moment(`${currentHours}:${currentMinutes}`, 'HH:mm', 'Europe/Athens');

    let workingTill = timeTill.split(':');
    let workingTillHours = Number(workingTill[0]);
    let workingTillMinutes = Number(workingTill[1]);
    if (workingTillMinutes === 0) {
      workingTillMinutes = 60;
    }

    let workingFromFormatted = moment(timeFrom, 'HH:mm', 'Europe/Athens');
    let workingTillFormatted = moment(timeTill, 'HH:mm', 'Europe/Athens');
    let workingTillTomorrow = false;

    let tomorrowDate = _.cloneDeep(workingTillFormatted).add(1, 'days');
    if (Number(workingTill[0].charAt(0)) === 0 && currentTime.isBefore(tomorrowDate)) {
      workingTillFormatted = workingTillFormatted.add(1, 'days');
      workingTillTomorrow = true;
    }

    if (currentTime.isBetween(workingFromFormatted, workingTillFormatted)) {
      // Place is open
      openClosed = 'Open';
      placeClass = `${defaultPlaceClass}${placeClassCases.open}`;

      let shouldCheckForClosing = (currentHours + 1) >= workingTillHours && !workingTillTomorrow;
      if (shouldCheckForClosing && (workingTillMinutes - Number(currentMinutes)) <= 30) {
        openClosed = 'Closing soon';
        placeClass = `${defaultPlaceClass}${placeClassCases.closing}`;
      }

      if (!returnClass) {
        return openClosed;
      }

      return placeClass;
    }

    if (!returnClass) {
      return 'Closed';
    }

    return `${defaultPlaceClass}${placeClassCases.closed}`;
  }
};
