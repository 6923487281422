import React, { FC } from "react";
import Helmet from "react-helmet";

const OrderingTransport: FC = () => (
  <>
    <Helmet>
      <title>Ordering Transport</title>
    </Helmet>
  </>
);

export default OrderingTransport;
