import React from "react";
import { Route } from "react-router-dom";
import { RouteNames, RoutingDefinition } from "context/Core/types";
import ConciergePage from "./Page/Concierge";
import ConciergeAccommodationPage from "./Page/ConciergeAccommodation";
import ConciergePersonalServices from "./Page/ConciergePersonalServices";
import ConciergeRenting from "./Page/ConciergeRenting";
import ConciergeReservationsPage from "./Page/ConciergeReservations";
import ConciergeTripPlanningPage from "./Page/ConciergeTripPlanning";

export const routeNames: RouteNames = {
  CONCIERGE: "/concierge",
  CONCIERGE_ACCOMMODATION: "/concierge/accommodation",
  CONCIERGE_PERSONAL_SERVICES: "/concierge/personal-services",
  CONCIERGE_RENT_A_VEHICLE: "/concierge/renting",
  CONCIERGE_RESERVATIONS: "/concierge/reservations",
  CONCIERGE_TRIP_PLANNING: "/concierge/trip-planning"
};

const Routing: RoutingDefinition = [
  <Route
    key={routeNames.CONCIERGE}
    path={routeNames.CONCIERGE}
    exact
    component={ConciergePage}
  />,
  <Route
    key={routeNames.CONCIERGE_ACCOMMODATION}
    path={routeNames.CONCIERGE_ACCOMMODATION}
    component={ConciergeAccommodationPage}
  />,
  <Route
    key={routeNames.CONCIERGE_PERSONAL_SERVICES}
    path={routeNames.CONCIERGE_PERSONAL_SERVICES}
    component={ConciergePersonalServices}
  />,
  <Route
    key={routeNames.CONCIERGE_RENT_A_VEHICLE}
    path={routeNames.CONCIERGE_RENT_A_VEHICLE}
    component={ConciergeRenting}
  />,
  <Route
    key={routeNames.CONCIERGE_RESERVATIONS}
    path={routeNames.CONCIERGE_RESERVATIONS}
    component={ConciergeReservationsPage}
  />,
  <Route
    key={routeNames.CONCIERGE_TRIP_PLANNING}
    path={routeNames.CONCIERGE_TRIP_PLANNING}
    component={ConciergeTripPlanningPage}
  />
];

export default Routing;
