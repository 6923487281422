import React, { FC } from 'react';
import { useForm } from "react-hook-form";
import TextField from "context/Core/Form/Text";
import EmailField from "context/Core/Form/Email";
import { Redirect } from 'react-router-dom';
import TextAreaField from "context/Core/Form/TextArea";
import PhoneNumberField from "context/Core/Form/PhoneNumber";
import { useDispatch, useSelector } from 'react-redux';
import { conciergeRequest } from 'context/Concierge/Store/actions';
import { ActionType } from 'context/Concierge/Store/types';
import LocationPicker from '../../Core/Form/LocationPicker';

const ContactForm: FC<any> = () => {
  const { redirect } = useSelector((state: any) => state.concierge);
  const { handleSubmit, register, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: object): any => {
    return dispatch(conciergeRequest(ActionType.REQUEST_CONTACT_FORM, data));
  };

  if (redirect === true) {
    return <Redirect to={'/'} />;
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <TextField
            name="name"
            label="Your name"
            childRef={register({
              required: "Required"
            })}
            error={errors.name}
          />
        </div>
        <div className="form__group form__group--half">
          <PhoneNumberField
            sValue={setValue}
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <EmailField
            name="email"
            label="Your email"
            error={errors.email}
            childRef={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
          />
        </div>
        <div className="form__group form__group--half">
          <LocationPicker
            label={"Your location"}
            onLocation={(latLng: any) => setValue("location", latLng)}
            onAutocomplete={(place: any) => setValue("optional", { name: place })}
            onZone={(zone: any) => setValue("zone", zone)}
            name={"location"}
            register={register}
            required={false}
          />
        </div>
      </div>

      <div className="form__group withTop">
        <TextAreaField
          name="question"
          label="Your question"
          cols={30}
          rows={6}
          error={errors.question}
          onChange={(val: any) => setValue("question", val.target.value)}
          childRef={register({ name: "question", required: "Question field is required." })}
        />
      </div>

      <button type="submit" className="button">
        Order
      </button>
    </form>
  );
};

export default ContactForm;
