/* eslint-disable */
import React, { FC, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decreaseFoodItemQuantity, increaseFoodItemQuantity, removeFoodItem } from '../Store/actions';
import { getFoodItemQuantity, hasFoodItem } from '../../Order/Page/OrderingFoodPlace';

const SummaryFood: FC = () => {
  const {restaurants, items}: any = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  if (Object.values(restaurants).length <= 0) {
    return <></>;
  }

  return (
    <>
      <div>
        {
          Object.values(restaurants).map((restaurant: any) => {
            return (
              <Fragment key={restaurant.id}>
                <div className={'cart__order'}>
                  <div className="cart__order-title">
                    {restaurant.name}
                  </div>

                  <div className={'cart__order-items'}>
                    {
                      Object.entries(items.food).map(([restaurantId, products]: any) => {
                        if (restaurantId !== restaurant.id) {
                          return <></>;
                        }

                        return Object.values(products).map((item: any) => {
                          return (
                            <Fragment key={item.id}>
                              <div className={'cart__order-item'}>
                                <span className={'itemRemove'} onClick={() => removeFoodItem(restaurant, item)} />
                                <div className="menu__item-name">
                                  <span>{item.title}</span>
                                  {item.description}
                                </div>
                                <div className={'menu__item-inner'}>
                                  <div className={'menu__item-inner'}>
                                    <div className={'menu__item-price'}>
                                      {item.price}€
                                    </div>
                                  </div>

                                  <div className={'menu__item-inner'}>
                                    <div className={'menu__item-step'}>
                                      <a
                                        href="#"
                                        className="menu__item-arrow menu__item-arrow--down"
                                        onClick={() => stableDispatch(decreaseFoodItemQuantity(restaurant, item))}
                                      />

                                      <span className="menu__item-number">
                                      {
                                        hasFoodItem(items, restaurant, item.id) ? getFoodItemQuantity(items, restaurant, item.id) : 0
                                      }
                                    </span>

                                      <a
                                        href="#"
                                        className="menu__item-arrow menu__item-arrow--up"
                                        onClick={() => stableDispatch(increaseFoodItemQuantity(restaurant, item))}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })
                      })
                    }
                  </div>
                </div>
              </Fragment>
            );
          })
        }
      </div>
    </>
  );
};

export default SummaryFood;
