import React, { FC, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { RoutingDefinition } from "context/Core/types";
import Header from "context/Core/Header";
import Footer from "context/Core/Footer";
import HomeRouting from "context/Home/Routing";
import CartRouting from "context/Cart/Routing";
import OrderRouting from "context/Order/Routing";
import ContactRouting from "context/Contact/Routing";
import ConciergeRouting from "context/Concierge/Routing";
import {
  groupedLinks as footerGroupedLinks,
  socialLinks as footerSocialLinks
} from "context/Core/Footer/config";
import Modal from '../Component/Modal';
import ScrollToTop from '../Component/ScrollToTop';

const App: FC = () => {
  const appTitle: string = process.env.REACT_APP_NAME || "Myko Minions";

  const routingDefs: RoutingDefinition[] = [
    HomeRouting,
    OrderRouting,
    ContactRouting,
    ConciergeRouting,
    CartRouting
  ];

  return (
    <BrowserRouter>
      <Helmet titleTemplate={`${appTitle} | %s`} defaultTitle={appTitle}>
        <meta charSet="utf-8" />
        <meta name="description" content="Myko Minions" />
      </Helmet>

      <Header />

      <ScrollToTop />
      <Switch>
        {routingDefs.map(
          (routingDef: RoutingDefinition): ReactNode => {
            return routingDef.map((route: ReactNode): ReactNode => route);
          }
        )}
        <Route
          render={(): React.ReactNode => {
            return <h1>NoMatch!</h1>;
          }}
        />
      </Switch>


      <Footer
        groupedLinks={footerGroupedLinks}
        socialLinks={footerSocialLinks}
      />

      <Modal />
    </BrowserRouter>
  );
};

export default App;
