import React, { FC } from "react";
import Helmet from "react-helmet";
import ConciergeAccommodationForm from "context/Concierge/Component/ConciergeAccommodationForm";

const ConciergeAccommodations: FC = () => (
  <>
    <Helmet>
      <title>Concierge Accomodations</title>
    </Helmet>

    <div>
      <div className="tagline tagline--small">
        <div className="container">
          <p>
            Why spend hours and hours in searching for accommodation in Mykonos?
            Look no further, you got your Minions! Just tell us your needs and
            your budget in the form below and we will send you an offer that
            meets your desires.
          </p>
        </div>
      </div>

      <ConciergeAccommodationForm />
    </div>
  </>
);

export default ConciergeAccommodations;
