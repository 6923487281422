import React, { FC, useState } from 'react';
import { useForm } from "react-hook-form";
import TextField from "context/Core/Form/Text";
import EmailField from "context/Core/Form/Email";
import { Redirect } from 'react-router-dom';
import TextAreaField from "context/Core/Form/TextArea";
import PhoneNumberField from "context/Core/Form/PhoneNumber";
import { useDispatch, useSelector } from 'react-redux';
import { conciergeRequest } from '../Store/actions';
import { ActionType } from '../Store/types';
import { routeNames } from '../Routing';

const ConciergeReservationForm: FC<any> = ({ selected, reservationTree }) => {
  const [checked, addChecked]: any = useState([]);
  const [checkedChild0, addCheckedChild0]: any = useState([]);
  const [checkedChild1, addCheckedChild1]: any = useState([]);
  const [checkedChild2, addCheckedChild2]: any = useState([]);
  const [checkedChild3, addCheckedChild3]: any = useState([]);
  const [checkedChild4, addCheckedChild4]: any = useState([]);
  const [checkedChild5, addCheckedChild5]: any = useState([]);
  const [checkedChild6, addCheckedChild6]: any = useState([]);
  const [checkedChild7, addCheckedChild7]: any = useState([]);
  const [checkedChild8, addCheckedChild8]: any = useState([]);
  const [checkedChild9, addCheckedChild9]: any = useState([]);
  const [checkedChild10, addCheckedChild10]: any = useState([]);

  const { redirect } = useSelector((state: any) => state.concierge);
  const { handleSubmit, register, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: object): any => {
    return dispatch(conciergeRequest(ActionType.REQUEST_RESERVATION, {
      ...{
        answers: [
          checked,
          checkedChild0,
          checkedChild1,
          checkedChild2,
          checkedChild3,
          checkedChild4,
          checkedChild5,
          checkedChild6,
          checkedChild7,
          checkedChild8,
          checkedChild9,
          checkedChild10,
        ]
      },
      reservationType: selected,
      ...data,
    }));
  };

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  const clearChildren = () => {
    addCheckedChild0(() => []);
    addCheckedChild1(() => []);
    addCheckedChild2(() => []);
    addCheckedChild3(() => []);
    addCheckedChild4(() => []);
    addCheckedChild5(() => []);
    addCheckedChild6(() => []);
    addCheckedChild7(() => []);
    addCheckedChild8(() => []);
    addCheckedChild9(() => []);
    addCheckedChild10(() => []);
  };

  const renderChildren: any = (item: any, first: any = true) => {
    if (!item || !item.hasOwnProperty('id')) {
      return (<></>);
    }

    return (
      <>
        <div key={item.id}>
          <h2>{item.name}</h2>
          <div className={"form__group-wrapper form__group-wrapper--checkbox"} style={{ marginBottom: 0 }}>
            {
              item.children ? item.children.map((item: any) => {
                const isChecked = checked.find((i: any) => i.id === item.id)
                  || checkedChild0.find((s: any) => s.id === item.id)
                  || checkedChild1.find((s: any) => s.id === item.id)
                  || checkedChild2.find((s: any) => s.id === item.id)
                  || checkedChild3.find((s: any) => s.id === item.id)
                  || checkedChild4.find((s: any) => s.id === item.id)
                  || checkedChild5.find((s: any) => s.id === item.id)
                  || checkedChild6.find((s: any) => s.id === item.id)
                  || checkedChild7.find((s: any) => s.id === item.id)
                  || checkedChild8.find((s: any) => s.id === item.id)
                  || checkedChild9.find((s: any) => s.id === item.id)
                  || checkedChild10.find((s: any) => s.id === item.id);

                return (
                  <div className="form__group form__group--half" key={`${item.id}-${Math.random() * 5}`}>
                    <input type="checkbox"
                           className="form__control"
                           checked={isChecked}
                           onChange={() => undefined}
                    />
                    <label className="form__label form__label--checkbox" onClick={() => {
                      if (first === true) {
                        clearChildren();
                        return addChecked(() => [item]);
                      }

                      const invokeCheckMap: any = {
                        0: addCheckedChild0,
                        1: addCheckedChild1,
                        2: addCheckedChild2,
                        3: addCheckedChild3,
                        4: addCheckedChild4,
                        5: addCheckedChild5,
                        6: addCheckedChild6,
                        7: addCheckedChild7,
                        8: addCheckedChild8,
                        9: addCheckedChild9,
                        10: addCheckedChild10,
                      };

                      if (Object.keys(invokeCheckMap).includes(`${first}`)) {
                        invokeCheckMap[first === 0 ? 1 : first + 1](() => [item])
                      }
                    }}>
                      <span />
                      { item.name}
                    </label>
                  </div>
                )
              }) : null
            }
          </div>
        </div>
      </>
    )
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      {
        selected === 'help' ? (
          reservationTree.map((item: any) => {
            return renderChildren(item, true);
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checked.length > 0 ? (
          [checked.length].map((_, k: any) => {
            return renderChildren(checked[k].children[0], 0)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild1.length > 0 ? (
          [checkedChild1.length].map((_, k: any) => {
            return renderChildren(checkedChild1[k].children[0], 1)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild2.length > 0 ? (
          [checkedChild2.length].map((_, k: any) => {
            return renderChildren(checkedChild2[k].children[0], 2)
          })
        ) : (<></>)
      }


      {
        selected === 'help' && checkedChild3.length > 0 ? (
          [checkedChild3.length].map((_, k: any) => {
            return renderChildren(checkedChild3[k].children[0], 3)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild4.length > 0 ? (
          [checkedChild4.length].map((_, k: any) => {
            return renderChildren(checkedChild4[k].children[0], 4)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild5.length > 0 ? (
          [checkedChild5.length].map((_, k: any) => {
            return renderChildren(checkedChild5[k].children[0], 5)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild6.length > 0 ? (
          [checkedChild1.checkedChild6].map((_, k: any) => {
            return renderChildren(checkedChild6[k].children[0], 6)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild7.length > 0 ? (
          [checkedChild7.length].map((_, k: any) => {
            return renderChildren(checkedChild7[k].children[0], 7)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild8.length > 0 ? (
          [checkedChild8.length].map((_, k: any) => {
            return renderChildren(checkedChild8[k].children[0], 8)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild9.length > 0 ? (
          [addCheckedChild9.length].map((_, k: any) => {
            return renderChildren(addCheckedChild9[k].children[0], 9)
          })
        ) : (<></>)
      }

      {
        selected === 'help' && checkedChild10.length > 0 ? (
          [checkedChild10.length].map((_, k: any) => {
            return renderChildren(checkedChild10[k].children[0], 10)
          })
        ) : (<></>)
      }

      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <TextField
            name="name"
            label="Your name"
            childRef={register({
              required: "Required"
            })}
            error={errors.name}
          />
        </div>
        <div className="form__group form__group--half">
          <PhoneNumberField
            sValue={setValue}
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className={selected === 'known' ? "form__group-wrapper" : ""}>
        <div className={selected === 'known' ? "form__group form__group--half" : "form__group"}>
          <EmailField
            name="email"
            label="Your email"
            error={errors.email}
            childRef={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
          />
        </div>
        <div className="form__group form__group--half">
          {
            selected === 'known' ? (
              <TextField
                name="where"
                label="Where do you want to go ?"
                childRef={register({
                  required: "Required"
                })}
                error={errors.name}
              />
            ) : (<></>)
          }
        </div>
      </div>

      <div className="form__group withTop">
        <TextAreaField
          name="notes"
          label="Notes"
          cols={30}
          rows={6}
          error={errors.notes}
          onChange={(val: any) => setValue("notes", val.target.value)}
          childRef={register({ name: "notes" })}
        />
      </div>

      <button type="submit" className="button">
        Order
      </button>
    </form>
  );
};

export default ConciergeReservationForm;
