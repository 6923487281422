import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { routeNames } from '../Routing';
import { useSelector } from 'react-redux';

const Total: FC = () => {
  const { orderTotal }: any = useSelector((state: any) => state.cart);

  return (
    <>
      <div className={"order-total"}>
        <div className={"container"}>
          <div className={"order-total__inner"}>
            Total {orderTotal}€
            <Link to={routeNames.CART_SUMMARY} className={"button"}>
              Order
            </Link>
          </div>
        </div>
      </div>
    </>
  )
};

export default Total;
