import React, { FC } from "react";
import { FieldProps } from "./types";
import FormSpanError from "./SpanError";
import ReactSelect from "react-select";

type Props = FieldProps & {
  options: any;
  onChange?: any;
  styles?: any;
};

const selectStyles = {
  menu: (base: any) => ({
    ...base,
    zIndex: 9999,
  })
};

const Select: FC<Props> = ({
  name,
  childRef,
  label,
  error,
  className,
  options,
  onChange,
}) => (
  <>
    {label && <label className="form__label">{label}</label>}
    <ReactSelect
      name={name}
      ref={childRef}
      defaultValue={options[0]}
      className={className}
      options={options}
      onChange={onChange}
      styles={selectStyles}
    />
    <FormSpanError error={error} />
  </>
);

export default Select;
