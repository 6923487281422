import city from './city'
import first from './first'
import second from './second'
import third from './third'

export const MapZones = [
  {
    paths: first,
    options: { fillColor: '#102f51', fillOpacity: 0, strokeOpacity: 0, strokeWeight: 0 },
  },
  {
    paths: second,
    options: { fillColor: '#79b2f4', fillOpacity: 0, strokeOpacity: 0, strokeWeight: 0 },
  },
  {
    paths: third,
    options: { fillColor: '#c0e2ff', fillOpacity: 0, strokeOpacity: 0, strokeWeight: 0 },
  },
  {
    paths: city,
    options: { fillColor: '#102f51', fillOpacity: 0, strokeOpacity: 0, strokeWeight: 0 },
  },
];
