import { formatRoute } from "react-router-named-routes";
import { routeNames as homeRoutes } from "context/Home/Routing";
import { routeNames as orderRoutes } from "context/Order/Routing";
import { routeNames as conciergeRoutes } from "context/Concierge/Routing";
import { routeNames as contactRoutes } from "context/Contact/Routing";
import {
  LinkDefinition,
  NavigationSidebarLinkDefinition
} from "context/Core/types";

export const orderNavigationLinks: LinkDefinition[] = [
  {
    label: "Food",
    to: formatRoute(orderRoutes.ORDERING_FOOD)
  },
  {
    label: "Drinks",
    to: formatRoute(orderRoutes.ORDERING_DRINKS)
  },
  {
    label: "Custom",
    to: formatRoute(orderRoutes.ORDERING_CUSTOM)
  },
  {
    label: "Market Shop",
    to: formatRoute(orderRoutes.ORDERING_MARKET)
  },
  {
    label: "Gifts",
    to: formatRoute(orderRoutes.ORDERING_GIFTS)
  },
  {
    label: "Transport",
    to: formatRoute(orderRoutes.ORDERING_TRANSPORT)
  }
];

export const conciergeNavigationLinks: LinkDefinition[] = [
  {
    label: "Accommodation",
    to: formatRoute(conciergeRoutes.CONCIERGE_ACCOMMODATION)
  },
  {
    label: "Renting",
    to: formatRoute(conciergeRoutes.CONCIERGE_RENT_A_VEHICLE)
  },
  {
    label: "Reservations",
    to: formatRoute(conciergeRoutes.CONCIERGE_RESERVATIONS)
  },
  {
    label: "Personal Services",
    to: formatRoute(conciergeRoutes.CONCIERGE_PERSONAL_SERVICES)
  },
  {
    label: "Trip Planning",
    to: formatRoute(conciergeRoutes.CONCIERGE_TRIP_PLANNING)
  }
];

export const navigationSidebarLinks: NavigationSidebarLinkDefinition[] = [
  {
    label: "Food",
    to: formatRoute(orderRoutes.ORDERING_FOOD)
  },
  {
    label: "Drinks",
    to: formatRoute(orderRoutes.ORDERING_DRINKS)
  },
  {
    label: "Custom",
    to: formatRoute(orderRoutes.ORDERING_CUSTOM)
  },
  {
    label: "Gifts",
    to: formatRoute(orderRoutes.ORDERING_GIFTS)
  },
  {
    label: "Transport",
    to: formatRoute(orderRoutes.ORDERING_TRANSPORT),
    distanced: true
  },
  {
    label: "Home",
    to: formatRoute(homeRoutes.HOME),
    large: true
  },
  {
    label: "Contact",
    to: formatRoute(contactRoutes.CONTACT),
    large: true
  }
];
