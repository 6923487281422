import { ActionType } from './types';

const initialState = {
  data: {},
  redirect: false,
  restaurants: [],
  categories: [],
  products: [],
  gifts: [],
  drinks: {},
  order: {},
  market: {
    products: [
      { label: 'Item 1', quantity: 1 },
    ],
    total: 0,
    amount: 0,
  },
  settings: {},
  orderZone: 2,
  orderDeliveryFee: 0,
};

const getBetween = (marketShopRules: any, extraProducts: any) => {
  let extraProductsNum: any = 0;
  let foundPrice: any = 0;

  for (let item of extraProducts) {
    extraProductsNum = Number(extraProductsNum) + Number(item.quantity);
  }

  for (let item of marketShopRules) {
    if (extraProductsNum >= Number(item.from) && extraProductsNum <= Number(item.to)) {
      foundPrice = item.price;
    }
  }

  return foundPrice;
};

export default (
  state: any = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.SET_REQUEST_ORDER_STATUS:
      return { ...state, id: action.id, status: action.status };

    case ActionType.RESET_STATE:
      state = {
        data: {},
        redirect: false,
        restaurants: [],
        categories: [],
        products: [],
        gifts: [],
        drinks: {},
        order: {},
        market: {
          products: [
            { label: 'Item 1', quantity: 1 },
          ],
          total: 0,
          amount: 0,
        },
        settings: {},
        orderZone: 2,
        orderDeliveryFee: 0,
      };

      return { ...state };

    case ActionType.GET_AMOUNT:
      state.orderDeliveryFee = 0;
      if (state.settings.hasOwnProperty('zones')) {
        const { zones, zonesDiscount }: any = state.settings;
        const orderInZone = zones[Number(state.orderZone)];
        state.orderDeliveryFee = Number(
          zonesDiscount[state.orderZone] ? orderInZone.discountAmount : orderInZone.chargeAmount
        );
      }

      state.market.amount = Number(state.market.total) + Number(state.orderDeliveryFee);
      return { ...state };

    case ActionType.CHANGE_ZONE_MARKER:
      state.orderZone = action.zone;
      state.orderDeliveryFee = 0;
      if (state.settings.hasOwnProperty('zones')) {
        const { zones, zonesDiscount }: any = state.settings;
        const orderInZone = zones[Number(action.zone)];
        state.orderDeliveryFee = Number(
          zonesDiscount[action.zone] ? orderInZone.discountAmount : orderInZone.chargeAmount
        );
      }

      state.market.amount = Number(state.market.total) + Number(state.orderDeliveryFee);

      return { ...state };

    case ActionType.GET_BETWEEN:
      state.market.total = getBetween(state.settings.marketShop, state.market.products);

      return { ...state };
    case ActionType.SET_SETTINGS:
      state.settings = action.settings;
      if (action.settings.hasOwnProperty('marketShop')) {
        state.market.total = getBetween(action.settings.marketShop, state.market.products);
      }

      return {...state};

    case ActionType.ADD_MARKET_SHOP_PRODUCT:
      state.market.products.push(action.product);
      state.market.total = getBetween(state.settings.marketShop, state.market.products);

      return { ...state };
    case ActionType.REMOVE_MARKET_SHOP_PRODUCT:
      state.market.products = state.market.products.filter((_product: any, key: any) => key !== action.key);
      state.market.total = getBetween(state.settings.marketShop, state.market.products);

      return { ...state };
    case ActionType.CHANGE_MARKET_SHOP_PRODUCT_QUANTITY:
      state.market.products[action.key].quantity = Number(action.value);
      state.market.total = getBetween(state.settings.marketShop, state.market.products);

      return { ...state };

    case ActionType.CHANGE_MARKET_SHOP_PRODUCT_LABEL:
      state.market.products[action.key].label = action.value;
      return { ...state };

    case ActionType.SET_REQUESTED_ORDER:
      return {
        ...state,
        order: action.order,
      };

    case ActionType.FETCH_DRINKS:
      return {
        ...state,
        drinks: initialState.drinks,
      };

    case ActionType.FETCH_DRINKS_FULFILLED:
      return {
        ...state,
        drinks: action.drinks,
      };

    case ActionType.FETCH_GIFTS:
      return {
        ...state,
        gifts: initialState.gifts,
      };

    case ActionType.FETCH_GIFTS_FULFILLED:
      return {
        ...state,
        gifts: action.gifts,
      };

    case ActionType.FETCH_RESTAURANT_PRODUCTS:
      return {
        ...state,
        products: initialState.products,
      };

    case ActionType.FETCH_RESTAURANT_PRODUCTS_FULFILLED:
      return {
        ...state,
        products: action.products,
      };


    case ActionType.FETCH_RESTAURANT:
      return {
        ...state,
        restaurants: initialState.restaurants,
      };

    case ActionType.FETCH_RESTAURANT_FULFILLED:
      return {
        ...state,
        restaurants: action.restaurants,
      };

    case ActionType.FETCH_RESTAURANT_CATEGORIES:
      return {
        ...state,
        categories: initialState.categories,
      };

    case ActionType.FETCH_RESTAURANT_CATEGORIES_FULFILLED:
      return {
        ...state,
        categories: action.categories,
      };

    case ActionType.REQUEST_CUSTOM:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_CART:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_FUL_FILLED:
      return {
        ...state,
        redirect: true,
      };

    case ActionType.REQUEST_UN_FUL_FILL:
      return {
        ...state,
        redirect: false,
      };

    default:
      return state;
  }
};
