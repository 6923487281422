import { flatMap, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import { OrderRequestCenter, OrderSite } from 'medium/api';
import {
  fetchDrinksFulFilled,
  fetchGiftsFulFilled,
  fetchRestaurantCategoriesFulFilled,
  fetchRestaurantProductsFulFilled,
  fetchRestaurantsFulFilled,
  orderRequestFulFilled, resetState, setRequestedOrder,
} from './actions';
import { hideModal, showModal } from '../../Core/Store/actions';
import { modalMessages } from '../../Core/Component/Modal';
import { purgeState } from '../../Cart/Store/actions';

const { ordering }: any = modalMessages;

const orderRequest = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(
      ActionType.REQUEST_CUSTOM,
      ActionType.REQUEST_CART,
    ),
    mergeMap(({ payload, requestType: type, showNotification }: any) => {
      return from(Service.request(OrderSite.request, { type }, payload)).pipe(
        flatMap((response: any) => {
            return concat(
              of(showNotification ? showModal(ordering[type].title, ordering[type].text) : hideModal()),
              of(orderRequestFulFilled()),
              of(setRequestedOrder(response)),
              of(showNotification ? purgeState() : hideModal())
            );
          },
        ),
      );
    }),
  );
};

const orderRequestMarket = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.REQUEST_MARKET),
    mergeMap(({ payload, requestType: type }: any) => {
      return from(Service.request(OrderSite.request, { type }, payload)).pipe(
        flatMap(() => {
            return concat(
              of(showModal(ordering[type].title, ordering[type].text)),
              of(orderRequestFulFilled()),
              of(resetState()),
            );
          },
        ),
      );
    }),
  );
};

const fetchRestaurants = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT),
    mergeMap(({ category }: any) => {
      return from(Service.request(OrderSite.fetchRestaurant, { category })).pipe(
        flatMap((response) => {
            return concat(
              of(fetchRestaurantsFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const fetchRestaurantProducts = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_PRODUCTS),
    mergeMap(({ slug, tag }: any) => {
      return from(Service.request(OrderSite.fetchRestaurantProducts, { slug, tag })).pipe(
        flatMap((response) => {
            return concat(
              of(fetchRestaurantProductsFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const fetchRestaurantCategories = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_RESTAURANT_CATEGORIES),
    mergeMap(() => {
      return from(Service.request(OrderSite.fetchRestaurantCategories)).pipe(
        flatMap((response) => {
            return concat(
              of(fetchRestaurantCategoriesFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};


const fetchGifts = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_GIFTS),
    mergeMap(() => {
      return from(Service.request(OrderSite.fetchGifts)).pipe(
        flatMap((response) => {
            return concat(
              of(fetchGiftsFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const fetchDrinks = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_DRINKS),
    mergeMap(({ category }: any) => {
      return from(Service.request(OrderSite.fetchDrinks, { category })).pipe(
        flatMap((response) => {
            return concat(
              of(fetchDrinksFulFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const orderRequestStatus = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.SET_REQUEST_ORDER_STATUS),
    mergeMap(({ id, status }: any) => {
      return from(Service.request(OrderRequestCenter.modifyRequest, { id }, { action: 'WAIT_RESPONSE', status })).pipe(
        flatMap(() => {
            return concat(
              of(orderRequestFulFilled()),
              of(showModal(ordering.waiting_response.title, ordering.waiting_response.text))
            );
          },
        ),
      );
    }),
  );
};

export default {
  orderRequest,
  fetchRestaurants,
  fetchRestaurantCategories,
  fetchRestaurantProducts,
  fetchGifts,
  fetchDrinks,
  orderRequestMarket,
  orderRequestStatus,
};
