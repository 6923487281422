import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { routeNames } from '../Routing';
import { useSelector } from 'react-redux';
import SummaryDrink from './SummaryDrink';
import SummaryGift from './SummaryGift';
import SummaryFood from './SummaryFood';

const Summary: FC = () => {
  const { orderTotal }: any = useSelector((state: any) => state.cart);

  return (
    <>
      <Helmet>
        <title>Checkout Summary</title>
      </Helmet>
      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              Hello again! Here you can find all the things that you have put in the shopping cart for now. Would you like to add something else, or
              you have just finished your ordering process and want to proceed to checkout form?
            </p>
          </div>
        </div>

        <div className="single-header single-header--flat">
          <h1 className="single-header__title">Cart Summary</h1>
        </div>

        <div className="cart">
          <div className="container">

            <div className="cart__orders">
              <SummaryFood />
              <SummaryGift />
              <SummaryDrink />
            </div>

            <div className="cart__total">
              <div className="cart__total-price">
                <div>
                  Total <span>(without delivery fee)</span>
                </div>
                {orderTotal}€
              </div>

              <Link to={routeNames.CART_CHECKOUT} className={'button button--full'}>
                Proceed to checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Summary;
