/* eslint-disable */
import React, { FC, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { routeNames } from '../Routing';
import { useSelector } from 'react-redux';

const MarketSummary: FC = () => {
  const { market: { total, products } }: any = useSelector((state: any) => state.order);

  return (
    <>
      <Helmet>
        <title>Checkout Market Shop Summary</title>
      </Helmet>
      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              Hello again! Here you can find all the things that you have put in the shopping cart for now. Would you like to add something else, or
              you have just finished your ordering process and want to proceed to checkout form?
            </p>
          </div>
        </div>

        <div className="single-header single-header--flat">
          <h1 className="single-header__title">Market Shop Cart Summary</h1>
        </div>

        <div className="cart">
          <div className="container">

            <div className="cart__orders">
              <Fragment key={Math.random()}>
                <div className={'cart__order'}>
                  <div className="cart__order-title">
                    Market Shop Cart
                  </div>

                  <div className={'cart__order-items'}>
                    {
                      products.map((item: any) => {
                        return (
                          <Fragment key={`${item.label}-${Math.random() * 10}-mat-${Math.random()}`}>
                            <div className={'cart__order-item'}>
                              <span className={'itemRemove'} onClick={() => undefined} />
                              <div className="menu__item-name">
                                <span>{item.label}</span>
                              </div>
                              <div className={'menu__item-inner'}>
                                <div className={'menu__item-inner'}>
                                  <div className={'menu__item-step'}>
                                    <a
                                      href="#"
                                      className="menu__item-arrow menu__item-arrow--down"
                                      onClick={() => undefined}
                                    />

                                    <span className="menu__item-number">
                                      { item.quantity }
                                    </span>

                                    <a
                                      href="#"
                                      className="menu__item-arrow menu__item-arrow--up"
                                      onClick={() => undefined}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })
                    }
                  </div>
                </div>
              </Fragment>
            </div>

            <div className="cart__total">
              <div className="cart__total-price">
                <div>
                  Total <span>(without delivery fee)</span>
                </div>
                {total}€
              </div>

              <Link to={routeNames.ORDERING_MARKET_CHECKOUT} className={'button button--full'}>
                Proceed to checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default MarketSummary;
