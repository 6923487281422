/* eslint-disable */
import React, { FC, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  decreaseGiftItemQuantity,
  increaseGiftItemQuantity,
  removeGiftItem,
} from '../Store/actions';
import { getGiftItemQuantity, hasGiftItem } from '../../Order/Page/OrderingGift';

const SummaryGift: FC = () => {
  const {items}: any = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  if (Object.values(items.gifts).length <= 0) {
    return <></>;
  }

  return (
    <>
      <div>
        <Fragment key={Math.random()}>
          <div className={'cart__order'}>
            <div className="cart__order-title">
              Gifts
            </div>

            <div className={'cart__order-items'}>
              {
                Object.values(items.gifts).map((item: any) => {
                  return (
                    <Fragment key={item.id}>
                      <div className={'cart__order-item'}>
                        <span className={'itemRemove'} onClick={() => removeGiftItem(item)} />
                        <div className="menu__item-name">
                          <span>{item.title}</span>
                          {item.description}
                        </div>
                        <div className={'menu__item-inner'}>
                          <div className={'menu__item-inner'}>
                            <div className={'menu__item-price'}>
                              {item.price}€
                            </div>
                          </div>

                          <div className={'menu__item-inner'}>
                            <div className={'menu__item-step'}>
                              <a
                                href="#"
                                className="menu__item-arrow menu__item-arrow--down"
                                onClick={() => stableDispatch(decreaseGiftItemQuantity(item))}
                              />

                              <span className="menu__item-number">
                                {
                                  hasGiftItem(items, item.id) ? getGiftItemQuantity(items, item.id) : 0
                                }
                              </span>

                              <a
                                href="#"
                                className="menu__item-arrow menu__item-arrow--up"
                                onClick={() => stableDispatch(increaseGiftItemQuantity(item))}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              }
            </div>
          </div>
        </Fragment>
      </div>
    </>
  );
};

export default SummaryGift;
