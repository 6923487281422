import { formatRoute } from "react-router-named-routes";
import { LinkDefinition, SocialLink } from "context/Core/types";
import { routeNames as orderRoutes } from "context/Order/Routing";
import { routeNames as contactRoutes } from "context/Contact/Routing";
import { routeNames as conciergeRoutes } from "context/Concierge/Routing";

export const groupedLinks: LinkDefinition[][] = [
  [
    {
      to: formatRoute(orderRoutes.ORDERING_FOOD),
      label: "Food"
    },
    {
      to: formatRoute(orderRoutes.ORDERING_DRINKS),
      label: "Drinks"
    },
    {
      to: formatRoute(orderRoutes.ORDERING_CUSTOM),
      label: "Custom"
    },
    {
      to: formatRoute(orderRoutes.ORDERING_MARKET),
      label: "Market Shop"
    },
    {
      to: formatRoute(orderRoutes.ORDERING_GIFTS),
      label: "Gifts"
    },
    {
      to: formatRoute(orderRoutes.ORDERING_TRANSPORT),
      label: "Transport"
    }
  ],
  [
    {
      to: formatRoute(conciergeRoutes.CONCIERGE_ACCOMMODATION),
      label: "Accommodation"
    },
    {
      to: formatRoute(conciergeRoutes.CONCIERGE_RENT_A_VEHICLE),
      label: "Renting"
    },
    {
      to: formatRoute(conciergeRoutes.CONCIERGE_RESERVATIONS),
      label: "Reservations"
    },
    {
      to: formatRoute(conciergeRoutes.CONCIERGE_PERSONAL_SERVICES),
      label: "Personal Services"
    },
    {
      to: formatRoute(conciergeRoutes.CONCIERGE_TRIP_PLANNING),
      label: "Trip Planning"
    }
  ],
  [
    {
      to: formatRoute(contactRoutes.CONTACT),
      label: "Contact"
    }
  ]
];

export const socialLinks: SocialLink[] = [
  {
    title: "Facebook",
    href: "https://www.facebook.com/MykoMinions/"
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/myko.minions/"
  }
];
