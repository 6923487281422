import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from './store';
import App from "context/Core/App";
import * as serviceWorker from "./serviceWorker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/styles/styles.css";
import "assets/styles/custom.css";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
