import React, { FC, useCallback, useEffect, useState } from 'react';
import Helmet from "react-helmet";
import ConciergeRentingForm from '../Component/ConciergeRentingForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRentingTypes } from '../Store/actions';
import { Redirect } from 'react-router-dom';
import { routeNames } from '../Routing';

const ConciergeRenting: FC = () => {
  const [selected, setSelected]: any = useState({});
  const { redirect, rentingTypes } = useSelector((state: any) => state.concierge);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchRentingTypes());
  }, [stableDispatch]);

  useEffect(() => {
    if (rentingTypes.length > 0) {
      setSelected(rentingTypes[0]);
    }
  }, [rentingTypes]);

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  return (
    <>
      <Helmet>
        <title>Concierge Renting</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              Busy island streets shouldn't bother you, 'cause we'll provide you with a Ride - car, scooter, ATV, you name it!
              Or if you're thinking of splurging yourself a bit, be free to go bigger - a Yacht or Helicopter, anyone?
              Just please choose your needs, fill up the form below, and let us do the rest for you.
            </p>
          </div>
        </div>
      </div>

      <div className="form">
        <div className="container container--small">
          <div className="form__group-wrapper form__group-wrapper--checkbox">
            {
              rentingTypes.map((type: any) => {
                return (
                  <div className="form__group form__group form__group--fifth" key={type.item.id}>
                    <input
                      name="type"
                      type="checkbox"
                      className="form__control"
                      checked={selected && selected.item && selected.item.id === type.item.id}
                      onChange={() => undefined}
                    />
                    <label className="form__label form__label--checkbox" onClick={() => setSelected(type)}>
                      <span/>
                      { type.item.name }
                    </label>
                  </div>
                )
              })
            }
          </div>

          <ConciergeRentingForm selected={selected} subTypes={selected.subTypes} />

        </div>
      </div>
    </>
  );
};

export default ConciergeRenting;
