const apiUrl = process.env.REACT_APP_SYSTEM_API_URL;

export const Admin: any = {
  create: { path: `${apiUrl}/admin/`, method: 'POST' },
  find: { path: `${apiUrl}/admin/:id`, method: 'GET' },
  update: { path: `${apiUrl}/admin/`, method: 'PUT' },
  remove: { path: `${apiUrl}/admin/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/admin/`, method: 'GET' },
};

export const Gift: any = {
  create: { path: `${apiUrl}/order/gift/`, method: 'POST' },
  find: { path: `${apiUrl}/order/gift/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order/gift/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order/gift/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order/gift/`, method: 'GET' },
};

export const DrinkItem: any = {
  create: { path: `${apiUrl}/order-drink-item/`, method: 'POST' },
  find: { path: `${apiUrl}/order-drink-item/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order-drink-item/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order-drink-item/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order-drink-item/`, method: 'GET' },
};

export const DrinkCategory: any = {
  create: { path: `${apiUrl}/order-drink-category/`, method: 'POST' },
  find: { path: `${apiUrl}/order-drink-category/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order-drink-category/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order-drink-category/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order-drink-category/`, method: 'GET' },
};

export const Restaurant: any = {
  create: { path: `${apiUrl}/order/food/restaurant/`, method: 'POST' },
  find: { path: `${apiUrl}/order/food/restaurant/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order/food/restaurant/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order/food/restaurant/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order/food/restaurant/`, method: 'GET' },
};

export const RestaurantCategory: any = {
  create: { path: `${apiUrl}/order/food/restaurant-category/`, method: 'POST' },
  find: { path: `${apiUrl}/order/food/restaurant-category/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order/food/restaurant-category/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order/food/restaurant-category/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order/food/restaurant-category/`, method: 'GET' },
};

export const RestaurantTag: any = {
  create: { path: `${apiUrl}/order/food/restaurant-tag/create/:restaurantId`, method: 'POST' },
  find: { path: `${apiUrl}/order/food/restaurant-tag/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order/food/restaurant-tag/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order/food/restaurant-tag/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order/food/restaurant-tag/list/:restaurantId`, method: 'GET' },
};

export const RestaurantTagItem: any = {
  create: { path: `${apiUrl}/order/food/restaurant-tag-item/:restaurantId/:tagId`, method: 'POST' },
  find: { path: `${apiUrl}/order/food/restaurant-tag-item/:id`, method: 'GET' },
  update: { path: `${apiUrl}/order/food/restaurant-tag-item/`, method: 'PUT' },
  delete: { path: `${apiUrl}/order/food/restaurant-tag-item/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/order/food/restaurant-tag-item/:restaurantId/:tagId`, method: 'GET' },
};

export const ConciergeReservation: any = {
  update: { path: `${apiUrl}/concierge/reservation/`, method: 'PUT' },
  list: { path: `${apiUrl}/concierge/reservation/`, method: 'GET' },
};

export const ConciergeTripPlanning: any = {
  update: { path: `${apiUrl}/concierge/trip-planning/`, method: 'PUT' },
  list: { path: `${apiUrl}/concierge/trip-planning/`, method: 'GET' },
};

export const Page: any = {
  create: { path: `${apiUrl}/page/`, method: 'POST' },
  find: { path: `${apiUrl}/page/:id`, method: 'GET' },
  update: { path: `${apiUrl}/page/`, method: 'PUT' },
  delete: { path: `${apiUrl}/page/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/page/`, method: 'GET' },
};

export const SectionItem: any = {
  create: { path: `${apiUrl}/section-item/`, method: 'POST' },
  find: { path: `${apiUrl}/section-item/:id`, method: 'GET' },
  update: { path: `${apiUrl}/section-item/`, method: 'PUT' },
  delete: { path: `${apiUrl}/section-item/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/section-item/`, method: 'GET' },
};

export const SectionCategory: any = {
  create: { path: `${apiUrl}/section-category/`, method: 'POST' },
  find: { path: `${apiUrl}/section-category/:id`, method: 'GET' },
  update: { path: `${apiUrl}/section-category/`, method: 'PUT' },
  delete: { path: `${apiUrl}/section-category/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/section-category/`, method: 'GET' },
};

export const Settings: any = {
  update: { path: `${apiUrl}/settings/`, method: 'PUT' },
  list: { path: `${apiUrl}/settings/`, method: 'GET' },
};

export const SiteSettings: any = {
  list: { path: `${apiUrl}/site/settings/`, method: 'GET' },
};

export const NotificationWeb: any = {
  subscribe: { path: `${apiUrl}/notification-web/`, method: 'POST' },
  list: { path: `${apiUrl}/notification-web/`, method: 'GET' },
};

export const PartnerItem: any = {
  create: { path: `${apiUrl}/partner-item/`, method: 'POST' },
  find: { path: `${apiUrl}/partner-item/:id`, method: 'GET' },
  update: { path: `${apiUrl}/partner-item/`, method: 'PUT' },
  delete: { path: `${apiUrl}/partner-item/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/partner-item/`, method: 'GET' },
};

export const PartnerType: any = {
  create: { path: `${apiUrl}/partner-type/`, method: 'POST' },
  find: { path: `${apiUrl}/partner-type/:id`, method: 'GET' },
  update: { path: `${apiUrl}/partner-type/`, method: 'PUT' },
  delete: { path: `${apiUrl}/partner-type/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/partner-type/`, method: 'GET' },
};

export const Blog: any = {
  create: { path: `${apiUrl}/blog/`, method: 'POST' },
  find: { path: `${apiUrl}/blog/:id`, method: 'GET' },
  update: { path: `${apiUrl}/blog/`, method: 'PUT' },
  delete: { path: `${apiUrl}/blog/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/blog/`, method: 'GET' },
};

export const ConciergeRentingType: any = {
  create: { path: `${apiUrl}/concierge/renting-type/`, method: 'POST' },
  find: { path: `${apiUrl}/concierge/renting-type/:id`, method: 'GET' },
  update: { path: `${apiUrl}/concierge/renting-type/`, method: 'PUT' },
  delete: { path: `${apiUrl}/concierge/renting-type/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/concierge/renting-type/`, method: 'GET' },
};

export const ConciergeRentingSubtype: any = {
  create: { path: `${apiUrl}/concierge/renting-subtype/`, method: 'POST' },
  find: { path: `${apiUrl}/concierge/renting-subtype/:id`, method: 'GET' },
  update: { path: `${apiUrl}/concierge/renting-subtype/`, method: 'PUT' },
  delete: { path: `${apiUrl}/concierge/renting-subtype/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/concierge/renting-subtype/`, method: 'GET' },
};

export const ConciergeServiceItem: any = {
  create: { path: `${apiUrl}/concierge/service-item/`, method: 'POST' },
  find: { path: `${apiUrl}/concierge/service-item/:id`, method: 'GET' },
  update: { path: `${apiUrl}/concierge/service-item/`, method: 'PUT' },
  delete: { path: `${apiUrl}/concierge/service-item/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/concierge/service-item/`, method: 'GET' },
};

export const Auth: any = {
  login: { path: `${apiUrl}/auth/login`, method: 'POST' },
};

export const ConciergeSite: any = {
  request: { path: `${apiUrl}/site/concierge/:type`, method: 'PUT' },
  fetchRentingTypes: { path: `${apiUrl}/site/concierge/renting-types`, method: 'GET' },
  fetchPersonalServiceTypes: { path: `${apiUrl}/site/concierge/personal-service-types`, method: 'GET' },
  fetchReservationTree: { path: `${apiUrl}/site/concierge/reservation-tree`, method: 'GET' },
  fetchTripPlanningTree: { path: `${apiUrl}/site/concierge/trip-planning-tree`, method: 'GET' },
};

export const ConciergeRequestCenter: any = {
  fetchRequests: { path: `${apiUrl}/concierge/request/requests`, method: 'POST' },
};

export const ConciergeCenter: any = {
  update: { path: `${apiUrl}/concierge/center/update`, method: 'POST' },
  find: { path: `${apiUrl}/concierge/center/find/:id`, method: 'POST' },
};

export const OrderSite: any = {
  request: { path: `${apiUrl}/site/ordering/:type`, method: 'PUT' },
  fetchRestaurant: { path: `${apiUrl}/site/ordering/:category`, method: 'GET' },
  fetchRestaurantCategories: { path: `${apiUrl}/site/ordering/restaurant/categories`, method: 'GET' },
  fetchRestaurantProducts: { path: `${apiUrl}/site/ordering/restaurant/products/:slug/:tag`, method: 'GET' },
  fetchGifts: { path: `${apiUrl}/site/ordering/order/gifts`, method: 'GET' },
  fetchDrinks: { path: `${apiUrl}/site/ordering/order/drinks/:category`, method: 'GET' },
  fetchBraintreeClientToken: { path: `${apiUrl}/site/ordering/order/braintree-client-token`, method: 'GET' },
  braintreeSale: { path: `${apiUrl}/site/ordering/order/braintree-sale`, method: 'POST' },
};

export const OrderRequestCenter: any = {
  fetchRequests: { path: `${apiUrl}/order/request/order/requests`, method: 'POST' },
  find: { path: `${apiUrl}/order/request/find/:id`, method: 'POST' },
  modifyRequest: { path: `${apiUrl}/order/request/modify/:id`, method: 'POST' },
};

export const Migration: any = {
  migrate: { path: `${apiUrl}/migration/migrate`, method: 'GET' },
};

export const Courier: any = {
  create: { path: `${apiUrl}/courier/`, method: 'POST' },
  find: { path: `${apiUrl}/courier/:id`, method: 'GET' },
  update: { path: `${apiUrl}/courier/`, method: 'PUT' },
  remove: { path: `${apiUrl}/courier/:id`, method: 'DELETE' },
  list: { path: `${apiUrl}/courier/`, method: 'GET' },
};

export const MobileCustomer: any = {
  loginOrRegister: { path: `${apiUrl}/mobile/customer/loginOrRegister`, method: 'POST' },
};

export const MobileAuth: any = {
  login: { path: `${apiUrl}/mobile/auth/login`, method: 'POST' },
};
