import React from "react";
import { Route } from "react-router-dom";
import { RouteNames, RoutingDefinition } from "context/Core/types";
import SummaryPage from "./Page/Summary";
import CheckoutPage from "./Page/Checkout";

export const routeNames: RouteNames = {
  CART_SUMMARY: "/cart/summary",
  CART_CHECKOUT: "/cart/checkout"
};

const Routing: RoutingDefinition = [
  <Route
    key={routeNames.CART_SUMMARY}
    path={routeNames.CART_SUMMARY}
    exact
    component={SummaryPage}
  />,
  <Route
    key={routeNames.CART_CHECKOUT}
    path={routeNames.CART_CHECKOUT}
    exact
    component={CheckoutPage}
  />
];

export default Routing;
