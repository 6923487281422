import { ActionType } from './types';

const initialState = {
  data: {},
  redirect: false,
  rentingTypes: [],
  personalServiceTypes: [],
  reservationTree: [],
  tripPlanningTree: [],
};

export default (
  state = initialState,
  action: any,
): object => {
  switch (action.type) {
    case ActionType.FETCH_TRIP_PLANNING_TREE:
      return { ...state, tripPlanningTree: initialState.tripPlanningTree };

    case ActionType.FETCH_TRIP_PLANNING_TREE_FUL_FILLED:
      return { ...state, tripPlanningTree: action.tripPlanningTree };

    case ActionType.FETCH_RESERVATION_TREE:
      return { ...state, reservationTree: initialState.reservationTree };

    case ActionType.FETCH_RESERVATION_TREE_FUL_FILLED:
      return { ...state, reservationTree: action.reservationTree };

    case ActionType.FETCH_RENTING_TYPES:
      return { ...state, rentingTypes: initialState.rentingTypes };

    case ActionType.FETCH_RENTING_TYPES_FUL_FILLED:
      return { ...state, rentingTypes: action.rentingTypes };

    case ActionType.FETCH_PERSONAL_SERVICE_TYPES:
      return { ...state, personalServiceTypes: initialState.personalServiceTypes };

    case ActionType.FETCH_PERSONAL_SERVICE_TYPES_FUL_FILLED:
      return { ...state, personalServiceTypes: action.personalServiceTypes };

    case ActionType.REQUEST_ACCOMMODATION:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_CONTACT_FORM:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_TRIP_PLANNING:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_RESERVATION:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_PERSONAL_SERVICE:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_RENTING:
      return {
        ...state,
        requestType: action.requestType,
        payload: action.payload
      };

    case ActionType.REQUEST_FUL_FILLED:
      return {
        ...state,
        redirect: true,
      };

    case ActionType.REQUEST_UN_FUL_FILL:
      return {
        ...state,
        redirect: false,
      };

    default:
      return state;
  }
};
