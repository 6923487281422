let coordinates = [
  [37.46708, 25.32295],
  [37.46696, 25.32218],
  [37.46696, 25.32087],
  [37.46657, 25.31935],
  [37.46594, 25.3189],
  [37.46496, 25.31878],
  [37.46366, 25.31927],
  [37.46278, 25.32032],
  [37.46226, 25.32228],
  [37.46174, 25.32417],
  [37.46173, 25.32453],
  [37.46162, 25.32501],
  [37.4614, 25.32568],
  [37.4634, 25.3284],
  [37.46158, 25.32837],
  [37.46055, 25.32843],
  [37.46018, 25.32799],
  [37.45985, 25.32803],
  [37.45954, 25.32825],
  [37.45892, 25.32875],
  [37.45769, 25.32825],
  [37.45712, 25.32799],
  [37.45667, 25.32743],
  [37.45611, 25.32708],
  [37.45534, 25.32697],
  [37.45461, 25.32716],
  [37.45383, 25.32688],
  [37.45285, 25.32683],
  [37.45189, 25.32699],
  [37.45101, 25.32631],
  [37.45084, 25.32562],
  [37.45051, 25.3254],
  [37.45106, 25.3281],
  [37.45074, 25.32839],
  [37.4505, 25.32862],
  [37.45026, 25.32881],
  [37.4499, 25.3291],
  [37.44953, 25.32935],
  [37.44943, 25.3295],
  [37.44939, 25.32969],
  [37.44937, 25.32986],
  [37.44947, 25.33016],
  [37.44959, 25.33063],
  [37.4494, 25.33078],
  [37.44913, 25.33092],
  [37.44905, 25.33098],
  [37.44899, 25.33102],
  [37.44882, 25.33118],
  [37.44871, 25.33092],
  [37.44855, 25.33103],
  [37.44844, 25.33125],
  [37.44837, 25.33151],
  [37.44831, 25.33152],
  [37.4483, 25.33166],
  [37.44816, 25.33165],
  [37.4481, 25.33162],
  [37.44797, 25.33158],
  [37.44784, 25.33145],
  [37.44778, 25.33124],
  [37.44764, 25.33123],
  [37.44757, 25.33137],
  [37.44754, 25.33118],
  [37.44754, 25.33106],
  [37.44749, 25.33101],
  [37.44739, 25.33075],
  [37.447, 25.33091],
  [37.44695, 25.33079],
  [37.44687, 25.33069],
  [37.44675, 25.33072],
  [37.44662, 25.33069],
  [37.44647, 25.33073],
  [37.44645, 25.33058],
  [37.44636, 25.3306],
  [37.44623, 25.33063],
  [37.44615, 25.33055],
  [37.44601, 25.33053],
  [37.44581, 25.33045],
  [37.4449, 25.3307],
  [37.44464, 25.3312],
  [37.44441, 25.33098],
  [37.44428, 25.33107],
  [37.44418, 25.33101],
  [37.44402, 25.33076],
  [37.44407, 25.33067],
  [37.44404, 25.33042],
  [37.44402, 25.33031],
  [37.44397, 25.33026],
  [37.44375, 25.3302],
  [37.44373, 25.32986],
  [37.44339, 25.32999],
  [37.44331, 25.32981],
  [37.44325, 25.32981],
  [37.44294, 25.32995],
  [37.44305, 25.3298],
  [37.44304, 25.32968],
  [37.44293, 25.32953],
  [37.44288, 25.32931],
  [37.44278, 25.32873],
  [37.44265, 25.32884],
  [37.44239, 25.32856],
  [37.44244, 25.32848],
  [37.44236, 25.32832],
  [37.44234, 25.32807],
  [37.44236, 25.32793],
  [37.44237, 25.32778],
  [37.44235, 25.3274],
  [37.44235, 25.32674],
  [37.44211, 25.32668],
  [37.44194, 25.32619],
  [37.44101, 25.32661],
  [37.43927, 25.32672],
  [37.43836, 25.32529],
  [37.43806, 25.32372],
  [37.43743, 25.32295],
  [37.43559, 25.32328],
  [37.4347, 25.32425],
  [37.43072, 25.32346],
  [37.42708, 25.32202],
  [37.42709, 25.31976],
  [37.42869, 25.31945],
  [37.42919, 25.31909],
  [37.43038, 25.31894],
  [37.43064, 25.31906],
  [37.43085, 25.31918],
  [37.43104, 25.31927],
  [37.43149, 25.31946],
  [37.43162, 25.31952],
  [37.43173, 25.31952],
  [37.43188, 25.31954],
  [37.432, 25.31947],
  [37.43221, 25.31929],
  [37.43254, 25.31895],
  [37.43309, 25.31839],
  [37.43425, 25.31796],
  [37.43543, 25.31659],
  [37.4356, 25.31608],
  [37.43589, 25.31564],
  [37.43554, 25.31502],
  [37.43491, 25.31464],
  [37.43442, 25.31453],
  [37.43397, 25.31408],
  [37.43297, 25.31417],
  [37.43253, 25.31457],
  [37.4325, 25.31477],
  [37.43235, 25.31502],
  [37.43181, 25.31495],
  [37.43138, 25.31441],
  [37.42577, 25.3154],
  [37.4232, 25.31578],
  [37.42231, 25.31604],
  [37.42098, 25.31777],
  [37.42101, 25.31961],
  [37.42092, 25.31992],
  [37.42073, 25.32006],
  [37.42078, 25.32073],
  [37.4209, 25.32071],
  [37.4211, 25.32067],
  [37.42112, 25.32141],
  [37.42165, 25.32167],
  [37.42227, 25.32196],
  [37.42244, 25.32293],
  [37.42222, 25.32484],
  [37.4211, 25.32511],
  [37.42157, 25.32668],
  [37.42185, 25.32728],
  [37.42247, 25.32842],
  [37.42364, 25.3293],
  [37.42377, 25.33242],
  [37.42422, 25.33307],
  [37.42429, 25.33393],
  [37.42467, 25.33588],
  [37.42513, 25.33693],
  [37.42753, 25.33814],
  [37.42953, 25.33831],
  [37.43222, 25.33953],
  [37.43385, 25.33978],
  [37.43362, 25.33997],
  [37.43365, 25.34002],
  [37.43367, 25.34009],
  [37.43359, 25.34016],
  [37.43359, 25.34027],
  [37.43355, 25.34029],
  [37.43356, 25.34034],
  [37.43367, 25.34029],
  [37.43378, 25.34025],
  [37.43398, 25.34017],
  [37.43399, 25.3406],
  [37.43394, 25.34071],
  [37.43399, 25.34083],
  [37.4341, 25.34112],
  [37.43439, 25.341],
  [37.43453, 25.34088],
  [37.43465, 25.34082],
  [37.43491, 25.34094],
  [37.43505, 25.3412],
  [37.43523, 25.34108],
  [37.43558, 25.34086],
  [37.43657, 25.34187],
  [37.4385, 25.34221],
  [37.44212, 25.34418],
  [37.4433, 25.34347],
  [37.44473, 25.34379],
  [37.445, 25.34431],
  [37.44571, 25.34464],
  [37.44621, 25.34521],
  [37.447, 25.3451],
  [37.44741, 25.34572],
  [37.44777, 25.3462],
  [37.44833, 25.34615],
  [37.4488, 25.34616],
  [37.44937, 25.34577],
  [37.44963, 25.34519],
  [37.45041, 25.34447],
  [37.45274, 25.34373],
  [37.45331, 25.34349],
  [37.45389, 25.34336],
  [37.455, 25.34293],
  [37.45588, 25.34289],
  [37.45624, 25.34267],
  [37.45639, 25.34245],
  [37.45698, 25.34201],
  [37.45699, 25.34163],
  [37.4577, 25.34106],
  [37.45818, 25.34107],
  [37.45827, 25.34126],
  [37.45837, 25.34135],
  [37.45847, 25.34138],
  [37.45855, 25.34144],
  [37.45865, 25.34165],
  [37.45869, 25.34175],
  [37.45878, 25.3418],
  [37.45898, 25.34183],
  [37.46037, 25.34176],
  [37.46048, 25.34133],
  [37.46139, 25.34047],
  [37.46174, 25.3395],
  [37.46182, 25.33926],
  [37.46201, 25.33905],
  [37.46218, 25.33855],
  [37.46227, 25.33812],
  [37.46251, 25.33761],
  [37.46307, 25.33751],
  [37.46306, 25.33681],
  [37.46368, 25.33616],
  [37.46375, 25.33544],
  [37.46387, 25.33501],
  [37.46413, 25.33465],
  [37.46411, 25.33413],
  [37.46403, 25.33352],
  [37.46382, 25.33306],
  [37.46395, 25.33283],
  [37.46418, 25.3326],
  [37.46456, 25.33227],
  [37.46471, 25.33197],
  [37.46472, 25.33158],
  [37.46526, 25.33031],
  [37.4653, 25.33009],
  [37.46544, 25.32996],
  [37.46571, 25.32981],
  [37.46594, 25.33003],
  [37.46632, 25.32998],
  [37.46662, 25.32956],
  [37.46671, 25.32942],
  [37.46671, 25.32923],
  [37.46735, 25.32758],
  [37.46746, 25.32739],
  [37.46756, 25.32698],
  [37.46758, 25.32673],
  [37.46755, 25.32647],
  [37.4672, 25.32619],
  [37.46757, 25.3251],
  [37.46757, 25.32494],
  [37.46756, 25.32479],
  [37.46747, 25.32464]
]

export default coordinates.map(item => {
	return { lat: item[0], lng: item[1] }
})
