import React, { FC, useCallback, useEffect } from 'react';
import Helmet from "react-helmet";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { conciergeRequestUnFilFill } from '../Store/actions';

const conciergeMap: any = [
  {
    accommodation: {
      image: 'accomodation.jpg',
      path: 'concierge/accommodation',
      label: 'Accommodation'
    },
  },

  {
    rent_a_vehicle: {
      image: 'rent-a-vehicle.jpg',
      path: 'concierge/renting',
      label: 'Renting'
    },
  },

  {
    reservations: {
      image: 'reservations.jpg',
      path: 'concierge/reservations',
      label: 'Reservations'
    },
  },

  {
    personal_services: {
      image: 'personal-services.jpg',
      path: 'concierge/personal-services',
      label: 'Personal Services'
    },
  },

  {
    trip_planning: {
      image: 'trip-planning.jpg',
      path: '/concierge/trip-planning',
      label: 'Trip Planning'
    }
  },
];

const Concierge: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(conciergeRequestUnFilFill());
  }, [stableDispatch]);

  return (
    <>
      <Helmet>
        <title>Concierge</title>
      </Helmet>
      <div>
        <div className="tagline">
          <div className="container">
            <p>
              We are Myko Minions, premium delivery service company founded to meet your desires
              and to make stay at Mykonos as pleasant as possible.
            </p>
          </div>
        </div>

        <div className="orders">
          <div className="container">
            <div className="orders__wrapper">
              {
                conciergeMap.map((item: any, name: string) => {
                  const concierge: any = item[Object.keys(item)[0]];
                  return (
                    <div className={"order"} onClick={() => history.push(concierge.path)} key={`order-${name}`}>
                      <img src={`/images/ordering/${concierge.image}`} className={"order__image"} alt={name}/>
                      <div className="order__content">
                        {concierge.label}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Concierge;
