import React, { FC } from "react";

type Props = {
  error: any;
};

const SpanError: FC<Props> = ({ error }: any) => (
  <div>{error && <span className="form__error">{error.message}</span>}</div>
);

export default SpanError;
