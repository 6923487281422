import React, { FC, useCallback, useState } from 'react';
import FormSpanError from './SpanError';
import Map from '../Component/Map';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from 'react-redux';
import { showModal } from '../Store/actions';
import { modalMessages } from '../Component/Modal';

const LocationPicker: FC<any> = ({
  label,
  onLocation,
  onAutocomplete,
  name,
  onZone,
  register,
  required = true,
  checkForInCity = false,
}) => {
  const [mapVisible, setMapVisible] = useState(false);
  const [location, setLocation]: any = useState(false);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  register({ name: "zone" });
  register({ name: "optional" });
  register({ name: "location" });

  return (
    <>
      <div>
        { label && <label className="form__label">{label}</label> }

        <input type={"hidden"} name={name} value={location} />

        <button
          style={{ marginBottom: '5px' }}
          className="button button-location"
          onClick={(e) => {
            e.preventDefault();
            setMapVisible(!mapVisible);
          }}>
          <FontAwesomeIcon icon={faPlus} />
        </button>

        {
          mapVisible ? (
            <Map
              onAutocomplete={onAutocomplete}
              onZone={onZone}
              onLocation={(location: any) => {
                setLocation(location);
                onLocation(location);
              }}
              checkForInCity={checkForInCity}
              onCheckInCity={() => {
                if (checkForInCity) {
                  stableDispatch(showModal(
                    modalMessages.marker['in-city-zone'].title,
                    modalMessages.marker['in-city-zone'].text
                  ));
                }
              }}
            />
          ) : (<></>)
        }
      </div>

      <FormSpanError error={ !location && required ? { message: 'Location is required' } : '' } />
    </>
  );
};

export default LocationPicker;
