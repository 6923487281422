/* eslint-disable */
import React, { FC, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify, ParsedQuery } from "query-string";
import { useDispatch, useSelector } from 'react-redux';

const OrderingFoodCategories: FC<any> = ({ fetchRestaurants }: any) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const { categories }: any = useSelector((state: any) => state.order);

  let { category }: ParsedQuery = parse(location.search);

  if (!category) {
    category = "all";
    history.push(`${location.pathname}?${stringify({ category })}`);
  }

  const resolveCategory = (id: string) => {
    history.push(`${location.pathname}?${stringify({ category: id })}`);
    stableDispatch(fetchRestaurants(id));
  };

  return (
    <>
      <div>
        <div className="filters">
          <a
            key={'tagsAll'}
            className={`filter filterCustom ${category === 'all' ? 'filterActive' : '' }`}
            onClick={() => resolveCategory('all')}>
          All
        </a>

          {
            categories.length > 0 && categories.map((item: any) => {
              return (
                <a className={`filter filterCustom ${category === item.id ? 'filterActive' : '' }`}
                 key={item.id} onClick={() => resolveCategory(item.id)}>
                  { item.title }
                </a>
              )
            })
          }
        </div>

      </div>
    </>
  );
};

export default OrderingFoodCategories;
