import { flatMap, mergeMap } from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { ServiceInterface } from 'util/Service';
import { ActionType } from './types';
import {
  fetchSettingsFullFilled,
} from './actions';
import { SiteSettings } from 'medium/api';

const fetchSettingsEpic = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_SETTINGS),
    mergeMap(() => {
      return from(Service.request(SiteSettings.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchSettingsFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

export default {
  fetchSettingsEpic,
};
