import React, { FC } from 'react';
import { useForm } from "react-hook-form";
import TextField from "context/Core/Form/Text";
import EmailField from "context/Core/Form/Email";
import { Redirect } from 'react-router-dom';
import TextAreaField from "context/Core/Form/TextArea";
import PhoneNumberField from "context/Core/Form/PhoneNumber";
import { useDispatch, useSelector } from 'react-redux';
import LocationPicker from '../../Core/Form/LocationPicker';
import { changeZone } from '../Store/actions';
import Braintree from './Braintree';

const CartForm: FC<any> = ({ onFormSubmit, onChange, payment, payed, payWithCCCallback }) => {
  const { concierge: { redirect: conciergeRedirect }, cart: { redirect: cartRedirect } } = useSelector((state: any) => state);
  const { handleSubmit, register, errors, setValue, getValues } = useForm();
  const dispatch = useDispatch();

  let onSubmit = function(data: object) {
    return onFormSubmit(data);
  };

  if ((conciergeRedirect === true && payment === 'cache') || cartRedirect) {
    return <Redirect to={'/'} />;
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)} onChange={() => onChange(getValues())}>
      {
        payed === null ? <>
          <div className="form__group-wrapper">
            <div className="form__group form__group--half">
              <TextField
                name="name"
                label="Your name"
                childRef={register({
                  required: "Required"
                })}
                error={errors.name}
              />
            </div>
            <div className="form__group form__group--half">
              <PhoneNumberField
                sValue={setValue}
                errors={errors}
                register={register}
              />
            </div>
          </div>

          <div className="form__group-wrapper">
            <div className="form__group form__group--half">
              <EmailField
                name="email"
                label="Your email"
                error={errors.email}
                childRef={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address"
                  }
                })}
              />
            </div>
            <div className="form__group form__group--half">
              <LocationPicker
                label={"Your location"}
                onLocation={(latLng: any) => setValue("location", latLng)}
                onAutocomplete={(place: any) => setValue("optional", { name: place })}
                onZone={(zone: any) => {
                  dispatch(changeZone(zone));
                  setValue("zone", zone);
                }}
                name={"location"}
                register={register}
                required={true}
                checkForInCity={true}
              />
            </div>
          </div>

          <div className="form__group withTop">
            <TextAreaField
              name="notes"
              label="Your notes"
              cols={30}
              rows={6}
              error={errors.notes}
              onChange={(val: any) => setValue("notes", val.target.value)}
              childRef={register({ name: "notes", required: "Notes field is required." })}
            />
          </div>
        </> : (<></>)
      }

      {
        payment === 'cc' && payed !== null && <Braintree
          payWithCCCallback={(callback: any) => payWithCCCallback(callback)}
        />
      }
    </form>
  );
};

export default CartForm;
