import React from "react";
import { Route } from "react-router-dom";
import { RouteNames, RoutingDefinition } from "context/Core/types";
import HomePage from "./Page/Home";

export const routeNames: RouteNames = {
  HOME: "/"
};

const Routing: RoutingDefinition = [
  <Route
    key={routeNames.HOME}
    path={routeNames.HOME}
    exact
    component={HomePage}
  />
];

export default Routing;
