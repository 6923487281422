/* eslint-disable */
import React, { FC, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import CartForm from '../Component/Form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings, getOrderCartData } from '../Store/actions';
import { calculateValues } from '../Store/actions';
import { orderRequest } from '../../Order/Store/actions';
import { ActionType } from '../../Order/Store/types';

const Checkout: FC = () => {
  const {
    orderTotal,
    orderSurcharge,
    orderDeliveryFee,
    orderAmount,
    orderExtraStopValue,
    settings,
    orderCartData
  }: any = useSelector((state: any) => state.cart);
  const [state, setState]: any = useState({
    selected: "cache",
    payed: null,
    payWithCC: false,
    payWithCCCallback: null,
  });

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchSettings());
  }, [stableDispatch]);

  useEffect(() => {
    stableDispatch(calculateValues());
    stableDispatch(getOrderCartData());
  }, [settings.hasOwnProperty('zones')]);

  let formData: any = null;
  const onFormSubmit = (data: any) => {
    formData = data;
  };

  const onChange = (data: any) => {
    formData = data;
  };

  const submit = () => {
    if (!state.payWithCC) {
      const data = { contact: formData, ...orderCartData, paymentType: state.selected };
      stableDispatch(orderRequest(ActionType.REQUEST_CART, data, state.selected === 'cache'));
      return setState({ ...state, payed: 'payed', payWithCC: true });
    }

    if (state.payWithCC && state.payWithCCCallback !== null) {
      return state.payWithCCCallback();
    }
  };

  return (
    <>
      <Helmet>
        <title>Cart Checkout</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              Wooohoo! Final step!<br/>
              Please have a look at your final receipt.<br/>
              If everything looks good, please fill up the form below so your minions can be at your service as soon as possible!
            </p>
          </div>
        </div>

        <div className="single-header single-header--flat">
          <h1 className="single-header__title">Cart Checkout</h1>
        </div>

        <div className="container container--small">
          <div className="form__group-wrapper form__group-wrapper--checkbox">
            <div className="form__group form__group--half">
              <input
                name="type"
                type="checkbox"
                className="form__control"
                checked={state.selected && state.selected === 'cache'}
                onChange={() => undefined}
              />
              <label className="form__label form__label--checkbox" onClick={() => setState({ ...state, selected: 'cache' })}>
                <span/>
                Cache Payment
              </label>
            </div>

            <div className="form__group form__group--half">
              <input
                name="type"
                type="checkbox"
                className="form__control"
                checked={state.selected && state.selected === 'cc'}
                onChange={() => undefined}
              />
              <label className="form__label form__label--checkbox" onClick={() => setState({ ...state, selected: 'cc' })}>
                <span/>
                Credit Card/PayPal Payment
              </label>
            </div>
          </div>
        </div>

        <div className="cart">
          <div className="container">
            <div className="cart__form form">
              <div className="cart__form-title" id="contactDetails">
                { state.selected === 'cc' && state.payed === 'payed' ? 'Payment' : 'Contact details' }
              </div>
              <CartForm
                onFormSubmit={onFormSubmit}
                onChange={onChange}
                payment={state.selected}
                payed={state.payed}
                payWithCCCallback={(callback: any) => {
                  if (state.payWithCCCallback === null) {
                    setState({ ...state, payWithCCCallback: callback });
                  }
                }}
              />
            </div>

            <div className="cart__total">
              <div className="cart__total-title">Final reciept</div>

              <div className="cart__total-items">
                <div className="cart__total-item">
                  Order <span> {orderTotal}€ </span>
                </div>

                <div className="cart__total-item">
                  Surcharge <span> {orderSurcharge}€</span>
                </div>

                <div className="cart__total-item cart__total-item--special">
                  Delivery fee <span> {orderDeliveryFee}€ </span>
                </div>

                <div className="cart__total-item cart__total-item--special">
                  Extra stops <span>{orderExtraStopValue}€</span>
                </div>
              </div>

              <div className="cart__total-price">
                <div> Total</div>
                {orderAmount}€
              </div>

              <a href="#" className="button button--full" onClick={() => submit()}>
                { state.selected === 'cc' && state.payed === 'payed' ? 'Pay' : 'Order' }
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Checkout;
