import React, { FC } from "react";
import { FieldProps } from "./types";
import FormSpanError from "./SpanError";

type Props = FieldProps & {
  cols: number;
  rows: number;
};

const TextArea: FC<Props> = ({
  name,
  childRef,
  label,
  error,
  onChange,
  className,
  placeholder,
  cols,
  rows
}) => (
  <>
    {label && <label className="form__label">{label}</label>}
    <textarea
      name={name}
      placeholder={placeholder}
      ref={childRef}
      cols={cols}
      rows={rows}
      className={`form__control ${
        error ? "form__control--error" : ""
      } ${className || ""}`}
      onChange={onChange}
    />
    <FormSpanError error={error} />
  </>
);

export default TextArea;
