import React, { FC, ReactNode, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { routeNames as cartRoutes } from "context/Cart/Routing";
import { formatRoute } from "react-router-named-routes";
import { NavigationSidebarLinkDefinition } from "context/Core/types";

type Props = {
  links?: NavigationSidebarLinkDefinition[];
};

const NavigatinSidebar: FC<Props> = ({ links = [] }) => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  let previousPath = "";

  history.listen(location => {
    if (previousPath === location.pathname) {
      return;
    }

    previousPath = location.pathname;
    setOpen(false);
  });

  return (
    <>
      <Link to={formatRoute(cartRoutes.CART_SUMMARY)}>
        <div className="navigation__cart">
          <span>Cart</span>
          <i className="navigation__cart-icon"></i>
        </div>
      </Link>

      <button
        onClick={(): void => setOpen(!open)}
        className={`navigation__menu ${open ? "open" : ""}`}
      >
        <span className="navigation__menu-bar"></span>
        <span className="navigation__menu-bar"></span>
        <span className="navigation__menu-bar"></span>
      </button>

      <div className={`navigation__collapse ${open ? "open" : ""}`}>
        <div className="container">
          <ul className="navigation__menu-list">
            {links.map(
              (
                {
                  to,
                  label,
                  distanced,
                  large
                }: NavigationSidebarLinkDefinition,
                i: number
              ): ReactNode => (
                <li key={i}>
                  <Link
                    to={to}
                    className={`navigation__menu-list-link ${
                      large ? "" : "orderListItem"
                    }`}
                    style={{
                      marginBottom: distanced ? "40px" : ""
                    }}
                  >
                    {label}
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavigatinSidebar;
