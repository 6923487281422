/* eslint-disable */
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurantProducts } from '../Store/actions';
import { parse } from 'query-string';
import OrderingFoodPlaceCategories from './OrderingFoodPlaceCategories';
import Total from '../../Cart/Page/Total';
import {
  addFoodItem,
  addFoodItemExtra, addFoodItemExtraCopies,
  decreaseFoodItemQuantity,
  increaseFoodItemQuantity,
  removeFoodItem,
  removeFoodItemExtra,
} from '../../Cart/Store/actions';
// import { placeWorkingTimeClass } from './WorkingHours';
import { showModal } from '../../Core/Store/actions';
import { modalMessages } from '../../Core/Component/Modal';

export const getFoodItemQuantity = (items: any, restaurant: any, id: string): boolean => {
  return items.food[restaurant.id][id].quantity;
};

export const hasFoodItem = (items: any, restaurant: any, id: string): boolean => {
  return items.food.hasOwnProperty(restaurant.id) && items.food[restaurant.id].hasOwnProperty(id)
};

export const getFoodExtraCopies = (items: any) => items.foodExtraCopies;
export const getFoodItemExtraCopies = (items: any, id: any) => items.foodExtraCopies.hasOwnProperty(id) ? items.foodExtraCopies[id] : [];
export const hasFoodItemExtra = (items: any, restaurant: any, product: any, extra: any) => {
  if (items.food.hasOwnProperty(restaurant.id) && items.food[restaurant.id].hasOwnProperty(product.id)) {
    return _.find(items.food[restaurant.id][product.id].extras, { label: extra.label });
  }

  return false;
};

const OrderingFoodPlace: FC<any> = () => {
  const { products: restaurant }: any = useSelector((state: any) => state.order);
  const { items }: any = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const location = useLocation();
  const params = useParams();

  let { category }: any = parse(location.search);
  let { slug }: any = params;

  useEffect(() => {
    stableDispatch(fetchRestaurantProducts(slug, category !== 'all' ? category : 'all'));
  }, [stableDispatch]);

  if (restaurant.length <= 0) {
    return (<></>);
  }

  const canOrder = (_restaurant: any) => {
    // const accessor: any = placeWorkingTimeClass(restaurant, false);
    // accessor.toLowerCase() !== 'closed';
    return false;
  };

  return (
    <>
      <Helmet>
        <title>{restaurant.name}</title>
      </Helmet>

      <OrderingFoodPlaceCategories restaurant={restaurant} fetchRestaurantProducts={fetchRestaurantProducts}/>

      <div className="menu">
        <div className="container container--small">
          <p className="boxes__text">
            * Myko Minions keeps the right to charge additional 5€ if the order is from restaurant that is not on the list.
          </p>

          {
            restaurant.tag.map((tag: any) => {
              return (
                <Fragment key={`${tag.id}-fragment-${Math.random() * 16 * Math.random()}`}>
                  <div className={'menu__title'} key={tag.id}>
                    {tag.title}
                  </div>

                  {
                    tag.items.map((product: any) => {
                      return (
                        <Fragment key={`${product.id}-fragment-2-${Math.random() * 2 * Math.random()}`}>
                          <div className="menu__item" key={`${product.id}-${Math.random() * Math.random()}`}>
                            <div className="menu__item-wrapper">
                              <div className="menu__item-name">
                                <span>
                                  { product.title }
                                </span>
                                { product.description }
                              </div>

                              {
                                product.extras.length > 0 && <>
                                  <div className="menu__item-extras menu__item-extras--mobile">
                                    {
                                      product.extras.map((extra: any) => {
                                        return (
                                          <div className="menu__item-extras-item"
                                               key={`${product.id}-${restaurant.id}-mobile-${Math.random() * 2}`}>
                                            <input
                                              onClick={() => {
                                                if (!canOrder(restaurant)) {
                                                  const { title, text } = modalMessages.ordering.closed;
                                                  return stableDispatch(showModal(title, text));
                                                }

                                                if (hasFoodItemExtra(items, restaurant, product, extra)) {
                                                  return stableDispatch(removeFoodItemExtra(restaurant, product, extra))
                                                }

                                                stableDispatch(addFoodItemExtra(restaurant, product, extra))
                                              }}
                                              type="checkbox"
                                              checked={hasFoodItemExtra(items, restaurant, product, extra)}
                                              onChange={() => undefined}
                                            />
                                            <label>
                                              { extra.label } ({ extra.price }€)
                                            </label>
                                          </div>
                                        )
                                      })
                                    }

                                    <a
                                      href="#"
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        let { id, extras } = product;
                                        let extraCopies = getFoodExtraCopies(items);
                                        if (!extraCopies.hasOwnProperty(id)) {
                                          extraCopies[id] = [];
                                        }

                                        extraCopies[id].push(extras.map((item: any) => {
                                          let extraCopyItem = _.cloneDeep(item);
                                          extraCopyItem.label = `${extraCopyItem.label} (${extraCopies[id].length + 1} Item)`;
                                          return extraCopyItem;
                                        }));

                                        stableDispatch(increaseFoodItemQuantity(restaurant, product));
                                        stableDispatch(addFoodItemExtraCopies(extraCopies));
                                      }} className="menu__item-extras-copy">
                                    <span>+</span> Next item
                                  </a>
                                </div>
                                </>
                              }

                              <div className="menu__item-inner">
                                <div className="menu__item-inner">
                                  <div className="menu__item-checkbox">
                                    <input
                                      onChange={() => undefined}
                                      type="checkbox" checked={hasFoodItem(items, restaurant, product.id)} />
                                    <label onClick={
                                      () => {
                                        if (!canOrder(restaurant)) {
                                          const { title, text } = modalMessages.ordering.closed;
                                          return stableDispatch(showModal(title, text));
                                        }

                                        stableDispatch(
                                          hasFoodItem(items, restaurant, product.id)
                                            ? removeFoodItem(restaurant, product) : addFoodItem(restaurant, product)
                                        )
                                      }
                                    }>
                                      <span/>
                                    </label>
                                  </div>
                                  <div className="menu__item-price">{product.price}€</div>
                                </div>

                                <div className="menu__item-step">
                                  <a
                                    href="#"
                                    className="menu__item-arrow menu__item-arrow--down"
                                    onClick={() => {
                                      if (!canOrder(restaurant)) {
                                        const { title, text } = modalMessages.ordering.closed;
                                        return stableDispatch(showModal(title, text));
                                      }

                                      stableDispatch(decreaseFoodItemQuantity(restaurant, product))
                                    }}
                                  />
                                  <span className="menu__item-number">
                                    {
                                      hasFoodItem(items, restaurant, product.id) ? getFoodItemQuantity(items, restaurant, product.id) : 0
                                    }
                                  </span>

                                  <a
                                    href="#"
                                    className="menu__item-arrow menu__item-arrow--up"
                                    onClick={() => {
                                      if (!canOrder(restaurant)) {
                                        const { title, text } = modalMessages.ordering.closed;
                                        return stableDispatch(showModal(title, text));
                                      }

                                      stableDispatch(increaseFoodItemQuantity(restaurant, product))
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {
                              product.extras.length > 0 && <>
                                <div className="menu__item-extras">
                                  {
                                    product.extras.map((extra: any) => {
                                      return (
                                        <div className="menu__item-extras-item"
                                             key={`${product.id}-extra-${extra.label}-${Math.random()}`}>
                                          <input
                                            onClick={() => {
                                              if (!canOrder(restaurant)) {
                                                const { title, text } = modalMessages.ordering.closed;
                                                return stableDispatch(showModal(title, text));
                                              }

                                              if (hasFoodItemExtra(items, restaurant, product, extra)) {
                                                return stableDispatch(removeFoodItemExtra(restaurant, product, extra))
                                              }

                                              if (!hasFoodItem(items, restaurant, product.id)) {
                                                stableDispatch(addFoodItem(restaurant, product));
                                              }

                                              stableDispatch(addFoodItemExtra(restaurant, product, extra))
                                            }}
                                            type="checkbox"
                                            checked={hasFoodItemExtra(items, restaurant, product, extra)}
                                            onChange={() => undefined}
                                          />
                                          <label>
                                            { extra.label } ({ extra.price }€)
                                          </label>
                                        </div>
                                      )
                                    })
                                  }

                                  <a
                                    href="#"
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      if (!canOrder(restaurant)) {
                                        const { title, text } = modalMessages.ordering.closed;
                                        return stableDispatch(showModal(title, text));
                                      }

                                      let { id, extras } = product;
                                      let extraCopies = getFoodExtraCopies(items);

                                      if (!extraCopies.hasOwnProperty(id)) {
                                        extraCopies[id] = [];
                                      }

                                      extraCopies[id].push(extras.map((item: any) => {
                                        let extraCopyItem = _.cloneDeep(item);
                                        extraCopyItem.label = `${extraCopyItem.label} (${extraCopies[id].length + 1} Item)`;
                                        return extraCopyItem;
                                      }));

                                      stableDispatch(increaseFoodItemQuantity(restaurant, product));
                                      stableDispatch(addFoodItemExtraCopies(extraCopies));
                                    }} className="menu__item-extras-copy">
                                    <span>+</span> Next item
                                  </a>
                                </div>
                              </>
                            }

                            {
                              (getFoodItemExtraCopies(items, product.id)).length > 0 && <>
                                {
                                  (getFoodItemExtraCopies(items, product.id)).map((extras: any, key: any) => {
                                    return (
                                      <div className="menu__item-extras"
                                           key={`${product.id}-extra-${key}-${Math.random() * 15 * Math.random()}`}
                                      >
                                        {
                                          extras.map((extra: any, k: any) => {
                                            return (
                                              <div className="menu__item-extras-item" key={`child-${restaurant.id}-extra-${k}`}>
                                                <input
                                                  onClick={() => {
                                                    if (!canOrder(restaurant)) {
                                                      const { title, text } = modalMessages.ordering.closed;
                                                      return stableDispatch(showModal(title, text));
                                                    }

                                                    if (hasFoodItemExtra(items, restaurant, product, extra)) {
                                                      return stableDispatch(removeFoodItemExtra(restaurant, product, extra))
                                                    }

                                                    if (!hasFoodItem(items, restaurant, product.id)) {
                                                      stableDispatch(addFoodItem(restaurant, product));
                                                    }

                                                    stableDispatch(addFoodItemExtra(restaurant, product, extra))
                                                  }}
                                                  type="checkbox"
                                                  checked={hasFoodItemExtra(items, restaurant, product, extra)}
                                                  onChange={() => undefined}
                                                />
                                                <label>
                                                  { extra.label } ({ extra.price }€)
                                                </label>
                                              </div>
                                            )
                                          })
                                        }

                                        <a href="#" onClick={(e: any) => {
                                          e.preventDefault();
                                          let extraCopies = getFoodExtraCopies(items);
                                          let productExtraCopies = extraCopies[product.id];

                                          productExtraCopies[key].map((item: any) => stableDispatch(
                                            removeFoodItemExtra(restaurant, product, item)
                                          ));

                                          delete productExtraCopies[key];

                                          productExtraCopies = productExtraCopies.filter((item: any) => item);

                                          extraCopies[product.id] = productExtraCopies;
                                          if (_.isEmpty(productExtraCopies)) {
                                            delete extraCopies[product.id];
                                          }

                                          stableDispatch(addFoodItemExtraCopies(extraCopies));

                                          let { id } = product;
                                          let extraCopiesData = getFoodItemExtraCopies(items, id);
                                          let foodItemQuantity: any = getFoodItemQuantity(items, restaurant, id);

                                          if (!_.isEmpty(extraCopiesData) && extraCopiesData.length > (foodItemQuantity - 1)) {
                                            return;
                                          }

                                          stableDispatch(decreaseFoodItemQuantity(restaurant, product));
                                        }

                                        } className="menu__item-extras-copy">
                                          <span>-</span> Remove
                                        </a>
                                      </div>
                                    )
                                  })
                                }
                              </>
                            }
                          </div>
                        </Fragment>
                      );
                    })
                  }
                </Fragment>
              );
            })
          }
        </div>
      </div>

      <Total />
    </>
  );
};

export default OrderingFoodPlace;
