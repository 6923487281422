/* eslint-disable */
import React, { FC, useCallback, useEffect } from 'react';
import Helmet from "react-helmet";
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { orderRequestStatus } from '../Store/actions';

const OrderingStatus: FC = () => {
  const { order: { redirect } } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const params = useParams();
  const stableDispatch = useCallback(dispatch, []);

  let { id, status }: any = params;

  useEffect(() => {
    stableDispatch(orderRequestStatus(id, status));
  }, [id, status]);

  if (redirect) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <Helmet>
        <title>Ordering Status</title>
      </Helmet>
    </>
  );
};

export default OrderingStatus;
