import React, { FC } from "react";
import Navigation from "./Navigation";
import NavigatinSidebar from "./NavigatinSidebar";
import { Link, useLocation } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { routeNames as homeRoutes } from "context/Home/Routing";
import {
  orderNavigationLinks,
  conciergeNavigationLinks,
  navigationSidebarLinks
} from "./config";
import { LinkDefinition } from "context/Core/types";

type Props = {};

const Header: FC<Props> = () => {
  const location = useLocation();

  const simplified = location.pathname === homeRoutes.HOME;
  const navigationLinks: LinkDefinition[] = location.pathname.indexOf('/concierge') >= 0
    ? conciergeNavigationLinks : orderNavigationLinks;

  return (
    <div className={`navigation ${simplified ? "navigation--home" : "m-0"}`}>
      <div className="container container--full">
        {simplified ? (
          <Link
            to={formatRoute(homeRoutes.HOME)}
            className="navigation__logo navigation__logo--home"
          />
        ) : (
          <Navigation links={navigationLinks} />
        )}
        <NavigatinSidebar links={navigationSidebarLinks} />
      </div>
    </div>
  );
};

export default Header;
