let coordinates = [
  [37.48954, 25.37861],
  [37.48836, 25.37904],
  [37.4877, 25.38049],
  [37.48683, 25.38052],
  [37.48558, 25.38008],
  [37.48435, 25.3803],
  [37.48347, 25.3808],
  [37.48228, 25.38042],
  [37.48095, 25.38145],
  [37.47943, 25.3817],
  [37.4783, 25.38205],
  [37.47688, 25.38186],
  [37.47564, 25.38266],
  [37.47376, 25.38293],
  [37.4723, 25.38343],
  [37.47128, 25.38367],
  [37.46995, 25.38314],
  [37.46849, 25.38302],
  [37.4685, 25.38174],
  [37.4675, 25.38063],
  [37.46609, 25.37982],
  [37.46545, 25.37885],
  [37.46505, 25.37908],
  [37.46486, 25.37968],
  [37.46488, 25.37998],
  [37.46489, 25.38027],
  [37.46492, 25.38088],
  [37.46503, 25.38144],
  [37.46512, 25.38199],
  [37.46522, 25.38252],
  [37.46531, 25.38305],
  [37.4655, 25.38411],
  [37.46559, 25.38415],
  [37.46568, 25.38418],
  [37.46584, 25.38423],
  [37.46615, 25.38433],
  [37.46653, 25.38446],
  [37.46692, 25.38458],
  [37.4677, 25.38485],
  [37.47009, 25.38656],
  [37.47002, 25.38692],
  [37.46995, 25.38729],
  [37.46982, 25.38802],
  [37.46953, 25.38946],
  [37.46916, 25.38942],
  [37.46887, 25.38938],
  [37.46858, 25.38935],
  [37.46799, 25.38927],
  [37.46772, 25.38921],
  [37.46747, 25.38916],
  [37.46695, 25.38905],
  [37.46593, 25.38882],
  [37.46389, 25.38837],
  [37.4635, 25.38841],
  [37.46311, 25.38845],
  [37.46232, 25.38852],
  [37.46075, 25.38866],
  [37.4605, 25.38892],
  [37.46025, 25.3892],
  [37.45974, 25.38978],
  [37.45921, 25.39039],
  [37.45869, 25.39102],
  [37.45837, 25.39235],
  [37.45805, 25.39368],
  [37.45843, 25.39486],
  [37.45912, 25.39554],
  [37.45924, 25.39576],
  [37.45936, 25.39596],
  [37.45958, 25.39637],
  [37.45921, 25.39638],
  [37.45881, 25.39638],
  [37.45838, 25.39665],
  [37.45796, 25.3969],
  [37.45774, 25.3969],
  [37.45752, 25.3969],
  [37.45708, 25.39689],
  [37.45619, 25.39687],
  [37.45601, 25.39669],
  [37.45582, 25.39649],
  [37.45556, 25.39637],
  [37.45531, 25.39627],
  [37.45516, 25.39653],
  [37.45503, 25.39677],
  [37.45458, 25.39719],
  [37.45414, 25.39758],
  [37.45002, 25.39927],
  [37.44972, 25.39946],
  [37.44943, 25.39964],
  [37.44885, 25.4],
  [37.44854, 25.40011],
  [37.44825, 25.40021],
  [37.44796, 25.40031],
  [37.44766, 25.40042],
  [37.44741, 25.40052],
  [37.44714, 25.40062],
  [37.44661, 25.40082],
  [37.44556, 25.40123],
  [37.44537, 25.40125],
  [37.44514, 25.40127],
  [37.44471, 25.4013],
  [37.44382, 25.40139],
  [37.44367, 25.40149],
  [37.4436, 25.40154],
  [37.44352, 25.40161],
  [37.44325, 25.40185],
  [37.44294, 25.40189],
  [37.44263, 25.40193],
  [37.44245, 25.40179],
  [37.44226, 25.40165],
  [37.4419, 25.40116],
  [37.44145, 25.40091],
  [37.441, 25.40064],
  [37.44151, 25.39939],
  [37.44118, 25.39857],
  [37.44033, 25.3977],
  [37.4406, 25.39709],
  [37.44086, 25.39649],
  [37.44077, 25.39591],
  [37.44068, 25.39541],
  [37.44066, 25.39519],
  [37.44063, 25.39491],
  [37.44059, 25.39442],
  [37.44051, 25.39338],
  [37.44043, 25.39234],
  [37.44017, 25.39172],
  [37.43992, 25.3911],
  [37.43941, 25.38985],
  [37.43904, 25.38964],
  [37.43866, 25.38942],
  [37.43793, 25.38901],
  [37.43748, 25.38883],
  [37.43703, 25.38867],
  [37.43698, 25.38845],
  [37.43682, 25.3883],
  [37.43674, 25.38817],
  [37.43662, 25.388],
  [37.4365, 25.38783],
  [37.43657, 25.38758],
  [37.43664, 25.38733],
  [37.43677, 25.38682],
  [37.43634, 25.38649],
  [37.43591, 25.38615],
  [37.43683, 25.38334],
  [37.43654, 25.38339],
  [37.43627, 25.38344],
  [37.43573, 25.38355],
  [37.43465, 25.38375],
  [37.43412, 25.38385],
  [37.4336, 25.38395],
  [37.43254, 25.38415],
  [37.43236, 25.38429],
  [37.43218, 25.38443],
  [37.43183, 25.38469],
  [37.43111, 25.38522],
  [37.431, 25.3853],
  [37.43089, 25.38539],
  [37.43068, 25.38554],
  [37.43028, 25.38584],
  [37.43012, 25.38597],
  [37.42995, 25.38609],
  [37.42964, 25.3863],
  [37.42963, 25.38626],
  [37.42962, 25.38617],
  [37.42959, 25.38592],
  [37.42954, 25.38555],
  [37.42944, 25.38479],
  [37.42937, 25.38426],
  [37.4293, 25.38373],
  [37.42929, 25.3836],
  [37.42926, 25.38347],
  [37.42921, 25.38321],
  [37.42911, 25.38269],
  [37.429, 25.38209],
  [37.42889, 25.3815],
  [37.42866, 25.38031],
  [37.42854, 25.3801],
  [37.42841, 25.3799],
  [37.42817, 25.37951],
  [37.42784, 25.37957],
  [37.42754, 25.37964],
  [37.42692, 25.37977],
  [37.42626, 25.37976],
  [37.42561, 25.37973],
  [37.42553, 25.37973],
  [37.42549, 25.37978],
  [37.42544, 25.37983],
  [37.42535, 25.37994],
  [37.42515, 25.38015],
  [37.42478, 25.38057],
  [37.42463, 25.38073],
  [37.42448, 25.3809],
  [37.42418, 25.38122],
  [37.42399, 25.38165],
  [37.42378, 25.38207],
  [37.42366, 25.38233],
  [37.42354, 25.38258],
  [37.42331, 25.38308],
  [37.42313, 25.38328],
  [37.42297, 25.38346],
  [37.42285, 25.38359],
  [37.42274, 25.38371],
  [37.42249, 25.38392],
  [37.42242, 25.38397],
  [37.42235, 25.38402],
  [37.42222, 25.38412],
  [37.42196, 25.3843],
  [37.42188, 25.38426],
  [37.42178, 25.3842],
  [37.42159, 25.38409],
  [37.42121, 25.38387],
  [37.42063, 25.38452],
  [37.42095, 25.38522],
  [37.42131, 25.38573],
  [37.42225, 25.38637],
  [37.4225, 25.38774],
  [37.42235, 25.38898],
  [37.42204, 25.39015],
  [37.42151, 25.39123],
  [37.4211, 25.3923],
  [37.42017, 25.39263],
  [37.419, 25.39292],
  [37.41745, 25.39467],
  [37.4163, 25.39586],
  [37.4153, 25.39655],
  [37.41394, 25.39818],
  [37.41333, 25.40008],
  [37.41391, 25.40026],
  [37.41448, 25.40073],
  [37.41499, 25.40211],
  [37.41745, 25.40248],
  [37.41901, 25.40231],
  [37.42026, 25.40305],
  [37.4215, 25.40238],
  [37.42274, 25.40264],
  [37.42384, 25.40316],
  [37.42488, 25.40448],
  [37.42668, 25.40451],
  [37.42772, 25.40535],
  [37.42933, 25.40479],
  [37.43066, 25.40473],
  [37.43215, 25.40444],
  [37.43295, 25.40622],
  [37.43386, 25.40827],
  [37.43236, 25.40987],
  [37.43116, 25.41124],
  [37.43096, 25.41282],
  [37.42952, 25.41385],
  [37.42965, 25.4157],
  [37.43005, 25.41685],
  [37.42966, 25.41792],
  [37.42942, 25.41906],
  [37.42987, 25.42001],
  [37.43044, 25.42053],
  [37.43188, 25.41989],
  [37.43336, 25.4191],
  [37.43471, 25.42001],
  [37.43438, 25.4214],
  [37.43377, 25.42181],
  [37.43308, 25.42159],
  [37.43195, 25.42249],
  [37.43189, 25.42389],
  [37.43225, 25.42446],
  [37.43297, 25.4248],
  [37.43347, 25.4262],
  [37.43454, 25.42641],
  [37.4358, 25.4253],
  [37.43557, 25.42317],
  [37.43627, 25.42231],
  [37.4382, 25.4209],
  [37.43992, 25.42148],
  [37.44157, 25.42257],
  [37.44194, 25.42463],
  [37.44196, 25.4265],
  [37.44322, 25.42684],
  [37.44226, 25.42909],
  [37.44264, 25.4313],
  [37.4423, 25.43352],
  [37.44312, 25.43596],
  [37.4445, 25.43644],
  [37.44446, 25.43786],
  [37.44352, 25.43864],
  [37.44292, 25.44],
  [37.44343, 25.44114],
  [37.44469, 25.44141],
  [37.44358, 25.44269],
  [37.44382, 25.4439],
  [37.4437, 25.44695],
  [37.4445, 25.44778],
  [37.44394, 25.44905],
  [37.44412, 25.45056],
  [37.44565, 25.45085],
  [37.44586, 25.45219],
  [37.44461, 25.45286],
  [37.44408, 25.45402],
  [37.44385, 25.45687],
  [37.44503, 25.45794],
  [37.44515, 25.45914],
  [37.44693, 25.46006],
  [37.44806, 25.46014],
  [37.44907, 25.46064],
  [37.44955, 25.45891],
  [37.45005, 25.45781],
  [37.45053, 25.45756],
  [37.45222, 25.45864],
  [37.45363, 25.45945],
  [37.45486, 25.46063],
  [37.45634, 25.45994],
  [37.45716, 25.4615],
  [37.45807, 25.46147],
  [37.45888, 25.46128],
  [37.45994, 25.46204],
  [37.46071, 25.46225],
  [37.46142, 25.46126],
  [37.46162, 25.46214],
  [37.46229, 25.46256],
  [37.46373, 25.46125],
  [37.46529, 25.46072],
  [37.46625, 25.46111],
  [37.46681, 25.46167],
  [37.46719, 25.46105],
  [37.46788, 25.46177],
  [37.46862, 25.4616],
  [37.46929, 25.46114],
  [37.46919, 25.45953],
  [37.47036, 25.4607],
  [37.47159, 25.4608],
  [37.4731, 25.4612],
  [37.47456, 25.46189],
  [37.47523, 25.46152],
  [37.47553, 25.46061],
  [37.47492, 25.45831],
  [37.47502, 25.45682],
  [37.47465, 25.45555],
  [37.47402, 25.45316],
  [37.47538, 25.45213],
  [37.47533, 25.45055],
  [37.47484, 25.44839],
  [37.47411, 25.44715],
  [37.47409, 25.44525],
  [37.47182, 25.44493],
  [37.47325, 25.44452],
  [37.4737, 25.44342],
  [37.47333, 25.44213],
  [37.47445, 25.44163],
  [37.47468, 25.44032],
  [37.47407, 25.43916],
  [37.47378, 25.43781],
  [37.47301, 25.43755],
  [37.47336, 25.43723],
  [37.47382, 25.43658],
  [37.47355, 25.43574],
  [37.47314, 25.43565],
  [37.47326, 25.43456],
  [37.47247, 25.43343],
  [37.47122, 25.43235],
  [37.47237, 25.43259],
  [37.47307, 25.43228],
  [37.47283, 25.4314],
  [37.47156, 25.43046],
  [37.47168, 25.42979],
  [37.47158, 25.42913],
  [37.4728, 25.42966],
  [37.4738, 25.42933],
  [37.47433, 25.43062],
  [37.47513, 25.43054],
  [37.47599, 25.43063],
  [37.47626, 25.43005],
  [37.4773, 25.43047],
  [37.47884, 25.431],
  [37.47931, 25.43093],
  [37.48018, 25.43142],
  [37.48054, 25.43016],
  [37.48112, 25.42964],
  [37.48185, 25.43037],
  [37.48277, 25.43046],
  [37.48299, 25.42978],
  [37.48311, 25.42825],
  [37.48246, 25.42676],
  [37.48115, 25.42404],
  [37.4822, 25.42385],
  [37.48316, 25.42424],
  [37.48421, 25.42433],
  [37.48507, 25.424],
  [37.48567, 25.4233],
  [37.48535, 25.42251],
  [37.48444, 25.42221],
  [37.48424, 25.42109],
  [37.48363, 25.42091],
  [37.48232, 25.4203],
  [37.48366, 25.41885],
  [37.48414, 25.41644],
  [37.48395, 25.41492],
  [37.48308, 25.41426],
  [37.48176, 25.41299],
  [37.481, 25.41182],
  [37.48114, 25.41084],
  [37.48225, 25.41172],
  [37.4829, 25.41144],
  [37.4841, 25.41161],
  [37.48455, 25.40994],
  [37.4837, 25.40868],
  [37.48409, 25.40681],
  [37.4837, 25.40567],
  [37.48451, 25.40541],
  [37.48463, 25.40622],
  [37.48495, 25.40665],
  [37.48546, 25.40569],
  [37.48573, 25.4065],
  [37.48619, 25.40695],
  [37.4871, 25.40629],
  [37.48729, 25.40807],
  [37.48849, 25.40765],
  [37.48953, 25.40688],
  [37.49096, 25.40759],
  [37.49169, 25.40761],
  [37.49241, 25.40732],
  [37.49257, 25.40605],
  [37.49411, 25.40627],
  [37.49437, 25.40463],
  [37.49582, 25.40442],
  [37.49612, 25.40239],
  [37.49713, 25.40045],
  [37.49692, 25.39973],
  [37.49642, 25.39891],
  [37.49663, 25.39783],
  [37.4963, 25.39694],
  [37.49695, 25.39569],
  [37.497, 25.39415],
  [37.49635, 25.39194],
  [37.49565, 25.3909],
  [37.49528, 25.38984],
  [37.49585, 25.38828],
  [37.49696, 25.38756],
  [37.49663, 25.3854],
  [37.49421, 25.38337],
  [37.4944, 25.38179],
  [37.49351, 25.38106],
  [37.49353, 25.37966],
  [37.49214, 25.38002],
  [37.49235, 25.37926],
  [37.49202, 25.37877],
  [37.49028, 25.37956],
  [37.49012, 25.37877]
]

export default coordinates.map(item => {
	return { lat: item[0], lng: item[1] }
})
