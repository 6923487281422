/* eslint-disable */
import React, { FC, useCallback, Fragment } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { parse, stringify, ParsedQuery } from 'query-string';
import { useDispatch } from 'react-redux';
import { placeWorkingTimeClass } from './WorkingHours';

const OrderingFoodPlaceCategories: FC<any> = ({ restaurant, fetchRestaurantProducts }: any) => {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  let { category }: ParsedQuery = parse(location.search);
  let { slug }: any = params;

  if (!category) {
    category = 'all';
    history.push(`${location.pathname}?${stringify({category})}`);
  }

  let categories = category === 'all' ? restaurant.tag : restaurant.tags;

  const resolveCategory = (id: string) => {
    history.push(`${location.pathname}?${stringify({category: id})}`);
    stableDispatch(fetchRestaurantProducts(slug, id));
  };

  const getWorkingClass = (restaurant: any) => {
    const defaultPlaceClass: any = 'single-header__hours single-header__hours--';
    const placeClassCases: any = {
      open: 'open',
      closed: 'closed',
      ['closing soon']: 'closing'
    };

    let accessor: any = placeWorkingTimeClass(restaurant, false);
    return `${defaultPlaceClass}${placeClassCases[accessor.toLowerCase()]}`;
  };

  return (
    <Fragment>
      <div>
      <div className={'single-header'}>
        <div>
          <h1 className={'single-header__title'}>
            {restaurant.name}
          </h1>

          <div className={'filters'}>
            <a className={`filter ${category === 'all' ? 'filterActive' : '' }`}
               onClick={() => resolveCategory('all')}>
              All
            </a>
            {
              categories.map((item: any) => {
                return (
                  <a className={`filter ${category === item.id ? 'filterActive' : '' }`}
                     onClick={() => resolveCategory(item.id)} key={item.id}>
                    { item.title }
                  </a>
                );
              })
            }
          </div>
        </div>
        <div className={getWorkingClass(restaurant)}>
          {placeWorkingTimeClass(restaurant, false)}
        </div>
      </div>

      <div className={'single-header single-header--mobile'}>
          <div>
            <h1 className={'single-header__title'}>
              {restaurant.name}
            </h1>
          </div>

          <div className={'filters'}>
            <a className={`filter ${category === 'all' ? 'filterActive' : '' }`}>All</a>
            {
              categories.map((item: any) => {
                return (
                  <a className={`filter ${category === item.id ? 'filterActive' : '' }`}
                     key={item.id} onClick={() => resolveCategory(item.id)}>
                    {item.title}
                  </a>
                );
              })
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderingFoodPlaceCategories;
