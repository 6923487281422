import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { ServiceInterface } from '../../../util/Service';
import { ActionType } from './types';
import { flatMap, mergeMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { OrderSite, SiteSettings } from '../../../medium/api';
import { fetchBraintreeClientTokenDone, fetchSettingsStoreFullFilled, purgeState, saleBraintreeFulFilled } from './actions';
import { orderRequestFulFilled } from '../../Order/Store/actions';
import { showModal } from '../../Core/Store/actions';
import { modalMessages } from '../../Core/Component/Modal';

const { ordering }: any = modalMessages;

const fetchStoreSettingsEpic = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_STORE_SETTINGS),
    mergeMap(() => {
      return from(Service.request(SiteSettings.list)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchSettingsStoreFullFilled(response)),
            );
          },
        ),
      );
    }),
  );
};

const fetchBraintreeClientToken = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.FETCH_BRAINTREE_CLIENT_TOKEN),
    mergeMap(() => {
      return from(Service.request(OrderSite.fetchBraintreeClientToken)).pipe(
        flatMap((response: any) => {
            return concat(
              of(fetchBraintreeClientTokenDone(response.clientToken)),
            );
          },
        ),
      );
    }),
  );
};

const saleBraintree = (
  action$: ActionsObservable<any>,
  _state$: StateObservable<any>,
  { Service }: { Service: ServiceInterface },
) => {
  return action$.pipe(
    ofType(ActionType.SALE_BRAINTREE),
    mergeMap(({ data }: any) => {
      return from(Service.request(OrderSite.braintreeSale, {}, data)).pipe(
        flatMap(() => {
            return concat(
              of(showModal(ordering.cart.title, ordering.cart.text)),
              of(saleBraintreeFulFilled(true)),
              of(orderRequestFulFilled()),
              of(purgeState()),
            );
          },
        ),
      );
    }),
  );
};

export default {
  fetchStoreSettingsEpic,
  fetchBraintreeClientToken,
  saleBraintree,
}
