import { useEffect, FC } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop: FC = ({ history }: any) => {
  useEffect(() => {
    const listener = history.listen(() => window.scrollTo(0, 0));
    return () => {
      listener();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
