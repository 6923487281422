/* eslint-disable */
import React, { FC, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify, ParsedQuery } from 'query-string';
import { useDispatch } from 'react-redux';

const OrderingDrinksCategories: FC<any> = ({ categories, fetchDrinks }: any) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  let { category }: ParsedQuery = parse(location.search);

  if (!category) {
    category = 'all';
    history.push(`${location.pathname}?${stringify({category})}`);
  }

  const resolveCategory = (id: string) => {
    history.push(`${location.pathname}?${stringify({category: id})}`);
    stableDispatch(fetchDrinks(id));
  };

  return (
    <>
      <div>
        <div className={'filters'}>
          <a className={`filter ${category === 'all' ? 'filterActive' : '' }`}
             onClick={() => resolveCategory('all')}>
            All
          </a>
          {
            categories.map((item: any) => {
              return (
                <a className={`filter ${category === item.id ? 'filterActive' : '' }`}
                   onClick={() => resolveCategory(item.id)} key={item.id}>
                  { item.title }
                </a>
              );
            })
          }
        </div>
      </div>
    </>
  );
};

export default OrderingDrinksCategories;
