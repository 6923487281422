import React, { FC } from 'react';
import { useForm } from "react-hook-form";
import TextField from "context/Core/Form/Text";
import EmailField from "context/Core/Form/Email";
import { Redirect } from 'react-router-dom';
import TextAreaField from "context/Core/Form/TextArea";
import PhoneNumberField from "context/Core/Form/PhoneNumber";
import DatePicker from "context/Core/Form/DatePicker";
import { useDispatch, useSelector } from 'react-redux';
import { conciergeRequest } from '../Store/actions';
import { ActionType } from '../Store/types';
import { routeNames } from '../Routing';
import Select from '../../Core/Form/Select';

const ConciergeRentingForm: FC<any> = ({
  selected,
  subTypes,
}) => {
  const { redirect } = useSelector((state: any) => state.concierge);
  const { handleSubmit, register, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: object): any => {
    return dispatch(conciergeRequest(ActionType.REQUEST_RENTING, {
      rentingType: selected.item.id,
      ...data,
    }));
  };

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <TextField
            name="name"
            label="Your name"
            childRef={register({
              required: "Required"
            })}
            error={errors.name}
          />
        </div>
        <div className="form__group form__group--half">
          <PhoneNumberField
            sValue={setValue}
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <EmailField
            name="email"
            label="Your email"
            error={errors.email}
            childRef={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
          />
        </div>
        <div className="form__group form__group--half">
          <Select
            styles={{
              control: (base: any) => ({
                ...base,
                height: '50px',
                minHeight: '50px',
                borderRadius: 0,
              }),
            }}
            name="subType"
            label="Sub Type"
            error={errors.budget}
            options={subTypes && subTypes.length > 0 ? subTypes.map(({ name: label, id: value }: any) => ({ label, value })) : []}
            onChange={({ value }: any) => setValue("subType", value)}
            childRef={register({
              name: "subType",
              required: "Required",
              pattern: {
                value: /^\d+$/i,
                message: "invalid number"
              }
            })}
          />
        </div>
      </div>

      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <label className="form__label">Start Date</label>
           <DatePicker
             name={"startDate"}
             error={errors.startDate}
             className={"startDate"}
             onChange={(val: any) => setValue("startDate", val)}
             childRef={register({
               name: "startDate",
               required: "Field Start Date is required.",
             })}
           />
        </div>

        <div className="form__group form__group--half">
          <label className="form__label">End Date</label>
          <DatePicker
            name={"endDate"}
            error={errors.endDate}
            className={"endDate"}
            onChange={(val: any) => setValue("endDate", val)}
            childRef={register({
              name: "endDate",
              required: "Field End Date is required.",
            })}
          />
        </div>
      </div>

      <div className="form__group">
        <TextAreaField
          name="notes"
          label="Notes"
          cols={30}
          rows={6}
          error={errors.notes}
          onChange={(val: any) => setValue("notes", val.target.value)}
          childRef={register({ name: "notes" })}
        />
      </div>

      <button type="submit" className="button">
        Order
      </button>
    </form>
  );
};

export default ConciergeRentingForm;
