import React, { FC, useState } from 'react';
import { useForm } from "react-hook-form";
import TextField from "context/Core/Form/Text";
import EmailField from "context/Core/Form/Email";
import { Redirect } from 'react-router-dom';
import TextAreaField from "context/Core/Form/TextArea";
import PhoneNumberField from "context/Core/Form/PhoneNumber";
import { useDispatch, useSelector } from 'react-redux';
import { conciergeRequest } from '../Store/actions';
import { ActionType } from '../Store/types';
import { routeNames } from '../Routing';
import DatePicker from '../../Core/Form/DatePicker';
import NumericField from '../../Core/Form/Numeric';

const ConciergeTripPlanningForm: FC<any> = ({ tripPlanningTree }) => {
  const [checked, addChecked]: any = useState({});
  const { redirect } = useSelector((state: any) => state.concierge);
  const { handleSubmit, register, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: object): any => {
    return dispatch(conciergeRequest(ActionType.REQUEST_TRIP_PLANNING, {
      answers: checked,
      ...data,
    }));
  };

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  const renderChildren: any = (item: any) => {
    if (!item || !item.hasOwnProperty('id')) {
      return (<></>);
    }

    return (
      <>
        <div key={item.id}>
          <h2>{item.name}</h2>
          <div className={"form__group-wrapper form__group-wrapper--checkbox"} style={{ marginBottom: 0 }}>
            {
              item.children ? item.children.map((children: any) => {
                const isChecked = Object.keys(checked).includes(`${item.id}`) && checked[item.id].id === children.id;
                return (
                  <div className="form__group form__group--half" key={`${children.id}-${Math.random() * 5}`}>
                    <input type="checkbox"
                           className="form__control"
                           checked={isChecked}
                           onChange={() => undefined}
                    />
                    <label className="form__label form__label--checkbox" onClick={
                      () => addChecked((prev: any) => ({ ...prev, [item.id]: { ...children, parent: item }}))
                    }>
                      <span />
                      { children.name}
                    </label>
                  </div>
                )
              }) : null
            }
          </div>
        </div>
      </>
    )
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      {
        tripPlanningTree.map((item: any) => {
          return renderChildren(item, true);
        })
      }

      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <TextField
            name="name"
            label="Your name"
            childRef={register({
              required: "Required"
            })}
            error={errors.name}
          />
        </div>
        <div className="form__group form__group--half">
          <PhoneNumberField
            sValue={setValue}
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className={"form__group-wrapper"}>
        <div className={"form__group form__group--half"}>
          <EmailField
            name="email"
            label="Your email"
            error={errors.email}
            childRef={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
          />
        </div>
        <div className="form__group form__group--half">
          <NumericField
            name="numberOfPeople"
            label="Number of People"
            error={errors.numberOfPeople}
            childRef={register({
              required: "Required",
              pattern: {
                value: /^\d+$/i,
                message: "invalid number"
              }
            })}
          />
        </div>
      </div>

      <div className="form__group-wrapper">
        <div className="form__group form__group--half">
          <label className="form__label">Start Date</label>
          <DatePicker
            name={"startDate"}
            error={errors.startDate}
            className={"startDate"}
            onChange={(val: any) => setValue("startDate", val)}
            childRef={register({
              name: "startDate",
              required: "Field Start Date is required.",
            })}
          />
        </div>

        <div className="form__group form__group--half">
          <label className="form__label">End Date</label>
          <DatePicker
            name={"endDate"}
            error={errors.endDate}
            className={"endDate"}
            onChange={(val: any) => setValue("endDate", val)}
            childRef={register({
              name: "endDate",
              required: "Field End Date is required.",
            })}
          />
        </div>
      </div>

      <div className="form__group withTop">
        <TextAreaField
          name="notes"
          label="Notes"
          cols={30}
          rows={6}
          error={errors.notes}
          onChange={(val: any) => setValue("notes", val.target.value)}
          childRef={register({ name: "notes" })}
        />
      </div>

      <button type="submit" className="button">
        Order
      </button>
    </form>
  );
};

export default ConciergeTripPlanningForm;
