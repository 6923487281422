import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBraintreeClientToken, saleBraintree } from '../Store/actions';
const DropIn = require('braintree-web-drop-in-react').default;

const Braintree: FC<any> = ({ payWithCCCallback }) => {
  const { brainTreeClientToken } = useSelector((state: any) => state.cart);
  const { order } = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchBraintreeClientToken());
  }, [stableDispatch]);

  if (!brainTreeClientToken) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      {
        <DropIn
          options={{
            authorization: brainTreeClientToken,
            paymentOptionPriority: ['card', 'paypal'],
            paypal: {
              flow: 'checkout',
              amount: order.orderAmount,
              currency: 'EUR',
              displayName: 'Myko Minions',
            },
          }}
          onInstance={(instance: any) => {
            payWithCCCallback(() => {
              instance.requestPaymentMethod(function (err: any, { nonce, details }: any) {
                if (err) {
                  console.log(err);
                }

                stableDispatch(saleBraintree({ nonce, details, order }));
              });
            });
          }}
        />
      }
    </div>
  );
};

export default Braintree;
