import React, { FC, useCallback, useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routeNames } from '../Routing';
import { fetchPersonalServiceTypes } from '../Store/actions';
import ConciergePersonalServiceForm from '../Component/ConciergePersonalServiceForm';

const ConciergePersonalServices: FC = () => {
  const [selected, setSelected]: any = useState({});
  const { redirect, personalServiceTypes } = useSelector((state: any) => state.concierge);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchPersonalServiceTypes());
  }, [stableDispatch]);

  useEffect(() => {
    if (personalServiceTypes.length > 0) {
      setSelected(personalServiceTypes[0]);
    }
  }, [personalServiceTypes]);

  if (redirect === true) {
    return <Redirect to={routeNames.CONCIERGE} />;
  }

  return (
    <>
      <Helmet>
        <title>Concierge Personal Services</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              You are the one who take care about yourself even on the vacation. Perfect, we take care also about your hair, nails, make up and body.
              Furthermore, if you are tired, best masseurs in Mykonos are waiting for you to lay down and relax. And guess what, they are coming to your
              place or boat!
              If you are down to that, please choose the service that you need, fill up the form below, we will check the availability and text you
              back.
            </p>
          </div>
        </div>
      </div>

      <div className="form">
        <div className="container container--small">
          <div className="form__group-wrapper form__group-wrapper--checkbox">
            {
              personalServiceTypes.map((type: any) => {
                return (
                  <div className="form__group form__group form__group--fifth" key={type.id}>
                    <input
                      name="type"
                      type="checkbox"
                      className="form__control"
                      checked={selected && selected.id === type.id}
                      onChange={() => undefined}
                    />
                    <label className="form__label form__label--checkbox" onClick={() => setSelected(type)}>
                      <span/>
                      { type.name }
                    </label>
                  </div>
                )
              })
            }
          </div>

          <ConciergePersonalServiceForm selected={selected} />

        </div>
      </div>
    </>
  )
};

export default ConciergePersonalServices;
