import React, { FC } from "react";
import { FieldProps } from "./types";
import FormSpanError from "./SpanError";

type Props = FieldProps & {
  type?: string;
  min?: number;
  max?: number;
};

const Numeric: FC<Props> = ({
  type = "number",
  name,
  childRef,
  label,
  error,
  onChange,
  className,
  placeholder,
  min,
  max
}) => (
  <>
    {label && <label className="form__label">{label}</label>}
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      ref={childRef}
      min={min}
      max={max}
      className={`form__control ${
        error ? "form__control--error" : ""
      } ${className || ""}`}
      onChange={onChange}
    />
    <FormSpanError error={error} />
  </>
);

export default Numeric;
