import React, { Component } from 'react';

class MapAutocomplete extends Component<any> {
  autocomplete: any = {};

  constructor(props: any) {
    super(props);
    this.autocomplete = {};

    this.addAutocomplete = this.addAutocomplete.bind(this);
    this.notifyPlaceSelected = this.notifyPlaceSelected.bind(this);
  }

  componentDidMount(): void {
    if (!this.props.google) {
      return;
    }

    this.addAutocomplete();
  }

  componentDidUpdate(): void {
    if (!this.props.google) {
      return;
    }

    this.addAutocomplete();
  }

  notifyPlaceSelected() {
    this.props.onAutocomplete(this.autocomplete.getPlace() || '');
  }

  addAutocomplete() {
    const autocomplete = new this.props.google.maps.places.Autocomplete(
      document.getElementById('autocomplete')
    );

    autocomplete.setComponentRestrictions({ country: 'gr' });
    autocomplete.addListener('place_changed', this.notifyPlaceSelected);

    this.autocomplete = autocomplete;
  }

  render() {
    if (!this.props.google) {
      return (<></>);
    }

    return (
      <input
        id={"autocomplete"}
        style={{
          marginTop: '5px',
          width: '100%',
          marginBottom: '5px',
        }}
        className="form__control"
        placeholder="Search for a place..."
        type="text"
      />
    )
  }
}

export default MapAutocomplete;
