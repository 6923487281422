/* eslint-disable */
import React, { FC, useCallback, useEffect, Fragment } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../Core/Store/actions';
import MarketTotal from '../Component/MarketTotal';
import {
  addMarketShopProduct,
  changeMarketShopProductLabel,
  changeMarketShopProductQuantity,
  removeMarketShopProduct,
  setSetting,
} from '../Store/actions';

const OrderingMarketShop: FC = () => {
  const { market: { products }}: any = useSelector((state: any) => state.order);
  const settings = useSelector((state: any) => state.core.settings);

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchSettings())
  }, [stableDispatch]);

  useEffect(() => {
    stableDispatch(setSetting(settings));
  }, [settings.hasOwnProperty('zones')]);

  if (!settings.hasOwnProperty('zones')) {
    return (<></>);
  }

  const { marketShop } = settings;

  return (
    <>
      <Helmet>
        <title>Ordering Market Shop</title>
      </Helmet>

      <div>
        <div className="tagline tagline--small">
          <div className="container">
            <p>
              Hate shopping? Foreign products in markets that confuse you, the crowd in busy stores, long waiting lines, busy streets, and roads that
              you don't know ?
              Or maybe you forgot your keys somewhere on the island and you don't have time to pick them up? You need adapters or similar stuff and you
              don't know where to find it?
              Or any else custom situation? You don't need to waste your Mykonos time for this. Your Minions will make the job done for you.
              Please just choose below do you want market shopping or something else
            </p>
          </div>
        </div>

        <div className={"container container--small"}>
          <p className="marketingText" style={{ textAlign: 'center' }}>
            Write down to us the shopping list with items and quantity of items and we will bring it to your house.<br />
            First, please note that Myko Minions keeps the right to charge additional amount of 5€ for every fifth item.
          </p>

          <table className="table">
            <tbody>
            <tr>
              <th>Number of different items</th>
              <th>Extra price</th>
            </tr>
            {
              marketShop.map((item: any) => {
                return (
                  <tr key={`${item.to}-${Math.random() * 2}-${item.from}-rand-${Math.random() * 15}`}>
                    <td>{ item.from }-{ item.to }</td>
                    <td>{ item.price }€</td>
                  </tr>
                );
              })
            }
            </tbody>
          </table>

          <p style={{ textAlign: 'center' }}>
            Please add the items and their quantity and fill up the form below. Note that you have to enter 1 item per line
          </p>

          <div className="form__group-wrapper form__group-wrapper--center">
            <div className="form__group form__group--half">
              <input
                type="text"
                className="form__control"
                placeholder={products[0].label}
                value={products[0].label}
                onChange={(e: any) => stableDispatch(changeMarketShopProductLabel(0, e.target.value))}
              />
            </div>
            <div className="form__group form__group--square">
              <input
                type="number"
                className="form__control"
                min={"1"}
                placeholder={products[0].quantity}
                value={products[0].quantity}
                onChange={(e: any) => stableDispatch(changeMarketShopProductQuantity(0, e.target.value))}
              />
            </div>
            <a
              href="/"
              className="menu__item-arrow menu__item-arrow--up"
              onClick={(e: any) => {
                e.preventDefault();
                stableDispatch(addMarketShopProduct({
                  label: `Item ${products.length + 1}`,
                  quantity: 1
                }));
              }}
            > </a>
          </div>
          {
            products.map((_product: any, key: any) => {
              if (key === 0) {
                return (<Fragment key={key} />);
              }

              return (
                <div
                  className="form__group-wrapper form__group-wrapper--center"
                  key={key}>
                  <div className="form__group form__group--half">
                    <input
                      type="text"
                      className="form__control"
                      placeholder={products[key].label}
                      value={products[key].label}
                      onChange={(e: any) => stableDispatch(changeMarketShopProductLabel(key, e.target.value))}
                    />
                  </div>
                  <div className="form__group form__group--square">
                    <input
                      type="number"
                      className="form__control"
                      min={"1"}
                      placeholder={"1"}
                      value={products[key].quantity}
                      onChange={(e: any) => stableDispatch(changeMarketShopProductQuantity(key, e.target.value))}
                    />
                  </div>
                  <a
                    href="/"
                    style={{marginLeft: 25, marginRight: 0 }}
                    className={"menu__item-arrow menu__item-arrow--down"}
                    onClick={(e: any) => {
                      e.preventDefault();
                      stableDispatch(removeMarketShopProduct(key));
                    }}> </a>
                </div>
              )
            })
          }
        </div>
      </div>

      <MarketTotal />
    </>
  );
};

export default OrderingMarketShop;
