import { ActionType } from './types';

export function fetchSettings() {
  return {
    type: ActionType.FETCH_SETTINGS,
  };
}

export function fetchSettingsFullFilled(settings: {} = {}) {
  return {
    type: ActionType.FETCH_SETTINGS_FULFILLED,
    settings,
  };
}

export function showModal(title: string = '', description: string = '') {
  return {
    type: ActionType.SHOW_MODAL,
    title,
    description,
  }
}

export function hideModal() {
  return {
    type: ActionType.HIDE_MODAL,
  }
}
